<template>
  <div>
    <ModalAddContact
      v-if="showAddContact"
      :showModal="showAddContact"
      :isLocked="isLocked"
      :isEmployee="isEmployee"
      :onClickCancel="hideModal"
    />

    <ModalEditContact
      v-if="showEditContact"
      :showModal="showEditContact"
      :isLocked="isLocked"
      :isEmployee="isEmployee"
      :contact="selectedContact"
      :onClickCancel="hideModal"
    />

    <ModalDeleteContact
      v-if="showDeleteContact"
      :showModal="showDeleteContact"
      :isLocked="isLocked"
      :isEmployee="isEmployee"
      :contact="selectedContact"
      :onClickCancel="hideModal"
    />

    <ui-section-header>
      <template v-slot:title>{{
        $t('Components.Reservation.Contacts.Header_Title')
      }}</template>
    </ui-section-header>

    <div
      v-if="!isLocked"
      class="actionBar has-background-white-ter has-padding has-margin-bottom"
    >
      <div class="has-text-right">
        <a
          @click="setShowAddContact"
          class="has-icon"
        >
          <span class="icon is-small">
            <font-awesome-icon :icon="['fas', 'plus']" />
          </span>
          <span>{{
              $t('Components.Reservation.Contacts.Link_AddContact')
            }}</span>
        </a>
      </div>
    </div>

    <table class="table is-fullwidth is-striped">
      <thead>
        <tr>
          <th>
            {{ $t('Components.Reservation.Contacts.Label_Type') }}
          </th>
          <th>
            {{ $t('Components.Reservation.Contacts.Label_Name') }}
          </th>
          <th>
            {{ $t('Components.Reservation.Contacts.Label_Email') }}
          </th>
          <th>
            {{ $t('Components.Reservation.Contacts.Label_Phone') }}
          </th>
          <th>
            {{
              $t(
                'Components.Reservation.Contacts.Label_ReceiveEmail'
              )
            }}
          </th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(contact, index) in reservation.Contacts"
          :key="index"
        >
          <td>{{ getContactTypeName(contact.Type) }}</td>
          <td>{{ contact.Name }}</td>
          <td>{{ contact.Email }}</td>
          <td>{{ contact.Phone }}</td>
          <td>
            <span class="icon">
              <font-awesome-icon
                v-if="contact.ReceiveEmail"
                :icon="['fas', 'check']"
                class="has-text-success"
              />
              <font-awesome-icon
                v-if="!contact.ReceiveEmail"
                :icon="['fas', 'times']"
                class="has-text-danger"
              />
            </span>
          </td>
          <td width="75">
            <div
              v-if="!isLocked"
              class="field is-grouped has-text-right"
            >
              <p class="control">
                <a @click="setShowEditContact(contact)">
                  <span class="icon">
                    <font-awesome-icon :icon="['fas', 'edit']" />
                  </span>
                </a>
              </p>
              <p
                class="control"
                v-if="reservation.Contacts.length > 1"
              >
                <a @click="setShowDeleteContact(contact)">
                  <span class="icon">
                    <font-awesome-icon
                      :icon="['fas', 'trash-alt']"
                      style="color:red"
                    />
                  </span>
                </a>
              </p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import ModalAddContact from '@/components/Reservation/ModalAddContact'
import ModalEditContact from '@/components/Reservation/ModalEditContact'
import ModalDeleteContact from '@/components/Reservation/ModalDeleteContact'

export default {
  components: {
    ModalAddContact,
    ModalEditContact,
    ModalDeleteContact,
  },

  props: {
    isEmployee: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      contactTypes: [],
      showAddContact: false,
      showEditContact: false,
      showDeleteContact: false,
      selectedContact: null,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),
    ...mapGetters('reservationStore', ['isLocked'])
  },

  created() {
    this.setupContactTypes()
  },

  methods: {
    setupContactTypes() {
      this.contactTypes.push({
        TypeId: 0,
        Type: 'Booker',
        Name: this.$t('Components.Reservation.ModalAddContact.Type_Booker'),
      })
      this.contactTypes.push({
        TypeId: 1,
        Type: 'DuringEvent',
        Name: this.$t(
          'Components.Reservation.ModalAddContact.Type_DuringEvent'
        ),
      })
      this.contactTypes.push({
        TypeId: 2,
        Type: 'Trainer',
        Name: this.$t('Components.Reservation.ModalAddContact.Type_Trainer'),
      })
      this.contactTypes.push({
        TypeId: 3,
        Type: 'Speaker',
        Name: this.$t('Components.Reservation.ModalAddContact.Type_Speaker'),
      })
      this.contactTypes.push({
        TypeId: 5,
        Type: 'Host',
        Name: this.$t('Components.Reservation.ModalAddContact.Type_Host'),
      })
      this.contactTypes.push({
        TypeId: 4,
        Type: 'Invoice',
        Name: this.$t('Components.Reservation.ModalAddContact.Type_Invoice'),
      })
    },

    getContactTypeName(type) {
      let contactType = this.contactTypes.find((ct) => ct.Type === type)
      if (contactType) {
        return contactType.Name
      }
      return ''
    },

    setShowAddContact() {
      this.showAddContact = true
    },

    setShowEditContact(contact) {
      this.showEditContact = true

      this.selectedContact = contact
    },

    setShowDeleteContact(contact) {
      this.showDeleteContact = true

      this.selectedContact = contact
    },

    hideModal() {
      this.showAddContact = false
      this.showEditContact = false
      this.showDeleteContact = false

      this.selectedContact = null
    },
  },
}
</script>

<style></style>
