<template>
  <div v-if="reservation">
    <DrawerSpaceDetail
      :drawerTitle="drawerTitle"
      v-if="showSideDrawer"
      :showDrawer="showSideDrawer"
      :spaceId="selectedSpace.SpaceId"
      :meetingtypeId="reservation.MeetingtypeId"
      :onClickClose="hideDrawer"
    />

    <ModalAddSpace
      v-if="showAddSpace"
      :showModal="showAddSpace"
      :channelId="reservation.ChannelId"
      :locationId="reservation.LocationId"
      :startDate="new Date(reservation.StartDate)"
      :startMinutes="resStart"
      :endDate="new Date(reservation.StartDate)"
      :endMinutes="resEnd"
      :seats="Number(reservation.TotalSeats)"
      :voucherId="Number(reservation.VoucherId)"
      :onClickCancel="hideModal"
    />

    <ModalEditSpace
      v-if="showEditSpace"
      :showModal="showEditSpace"
      :onClickCancel="hideModal"
      :channelId="reservation.ChannelId"
      :isEmployee="isEmployee"
      :locationId="reservation.LocationId"
      :meetingtypeId="reservation.MeetingtypeId"
      :space="selectedSpace"
      :index="selectedSpace.Index"
    />

    <ModalDeleteSpace
      v-if="showDeleteSpace"
      :showModal="showDeleteSpace"
      :space="selectedSpace"
      :onClickCancel="hideModal"
    />

    <ui-section-header
      :showAdd="!isLocked && reservation.MeetingtypeId === 1"
      :addFunction="setShowAddSpace"
      :addLabel="
        $t(
          'Components.Reservation.Meetingspaces.Icon_AddMeetingspace'
        )
      "
    >
      <template v-slot:title>{{
        $t('Components.Reservation.Meetingspaces.Subtitle_Spaces')
      }}</template>
    </ui-section-header>

    <Message v-if="reservation.Spaces.length === 0">{{$t('Components.Reservation.Meetingspaces.Text_NoSpacesAdded')}}</Message>

    <div v-if="reservation.Spaces.length > 0">
      <table class="table is-fullwidth is-striped is-hoverable is-narrow">
        <thead>
          <tr>
            <th
              width="75"
              class="has-text-left"
              v-text="$t('Components.Reservation.Meetingspaces.Table_Seats')"
            ></th>
            <th v-text="$t('Components.Reservation.Meetingspaces.Table_Name')"></th>
            <th
              v-if="reservation.MeetingtypeId === 1"
              v-text="$t('Components.Reservation.Meetingspaces.Table_Setting')"
            ></th>
            <th
              width="125"
              v-text="$t('Components.Reservation.Meetingspaces.Table_Time')"
            ></th>
            <th
              width="100"
              class="has-text-right"
              v-text="$t('Components.Reservation.Meetingspaces.Table_Price')"
            ></th>
            <th
              class="has-text-right"
              v-text="$t('Components.Reservation.Meetingspaces.Table_Tax')"
            ></th>
            <th
              width="100"
              class="has-text-right"
              v-text="$t('Components.Reservation.Meetingspaces.Table_Total')"
            ></th>
            <th
              width="75"
              class="is-hidden-print"
            >&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(meetingspace, index) in reservation.Spaces"
            :key="index"
          >
            <td class="has-text-left">{{ meetingspace.Seats }}x</td>
            <td>
              <!-- <span>{{ meetingspace.SpaceName }}</span> -->
              <a
                @click="setShowSpaceSummary(meetingspace)"
                v-html="meetingspace.SpaceName"
              >
                <!-- <span class="icon is-hidden-print">
                  <font-awesome-icon :icon="['fas', 'info-circle']" />
                </span> -->
              </a>
            </td>
            <td v-if="reservation.MeetingtypeId === 1">
              {{ meetingspace.SettingId | getSettingName }}
            </td>
            <td>
              {{ meetingspace.StartMinutes | minutesToTime }} -
              {{ meetingspace.EndMinutes | minutesToTime }}
            </td>
            <td class="has-text-right">
              <span v-if="!reservationHasPackageApplied || !meetingspace.InVoucher">{{
                  meetingspace.PricePerSeat
                    | toCurrency('nl-NL', reservation.CurrencyIso)
                }}</span>
            </td>
            <td class="has-text-right">
              <span v-if="!reservationHasPackageApplied || !meetingspace.InVoucher">{{ meetingspace.TaxPercentage }}%</span>
            </td>
            <td class="has-text-right">
              <span v-if="!reservationHasPackageApplied || !meetingspace.InVoucher">{{
                  meetingspace.PriceTotal
                    | toCurrency('nl-NL', reservation.CurrencyIso)
                }}</span>
            </td>
            <td
              width="75"
              class="is-hidden-print"
            >
              <div class="field is-grouped has-text-right">
                <p class="control">
                  <a
                    v-if="!isLocked"
                    @click="setShowEditSpace(meetingspace, index)"
                    class="is-small"
                  >
                    <span class="icon">
                      <font-awesome-icon :icon="['fas', 'edit']" />
                    </span>
                  </a>
                </p>

                <p class="control">
                  <a
                    v-if="!isLocked && reservation.Spaces.length > 1"
                    @click="setShowDeleteSpace(meetingspace, index)"
                    class="is-small"
                  >
                    <span class="icon has-text-danger">
                      <font-awesome-icon :icon="['fas', 'trash-alt']" />
                    </span>
                  </a>
                </p>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td :colspan="reservation.MeetingtypeId === 1 ? 6 : 5">
              {{
                $t(
                  'Components.Reservation.Meetingspaces.Table_Subtotal'
                )
              }}
            </td>
            <td class="has-text-right">
              <span>{{
                total | toCurrency('nl-NL', reservation.CurrencyIso)
              }}</span>
            </td>
            <td>&nbsp;</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import ModalAddSpace from '@/components/Reservation/ModalAddSpace'
import ModalEditSpace from '@/components/Reservation/ModalEditSpace'
import ModalDeleteSpace from '@/components/Reservation/ModalDeleteSpace'
import DrawerSpaceDetail from '@/components/Reservation/DrawerSpaceDetail'

export default {
  components: {
    ModalAddSpace,
    ModalEditSpace,
    ModalDeleteSpace,
    DrawerSpaceDetail,
  },

  props: {
    isEmployee: {
      default: false,
      type: Boolean,
    },

    isLocked: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      drawerTitle: '',
      selectedSpace: null,
      showAddSpace: false,
      showEditSpace: false,
      showDeleteSpace: false,
      showSideDrawer: false,
      showSpaceSummary: false,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),

    reservationHasPackageApplied() {
      let result = false

      if (this.reservation && this.reservation.Voucher) {
        result = this.reservation.Voucher.Type === 'Package'
      }

      return result
    },

    resStart() {
      let minStartMinutes = 0
      if (this.reservation && this.reservation.Spaces.length > 0) {
        var lowest = Number.POSITIVE_INFINITY
        var tmp
        for (var i = this.reservation.Spaces.length - 1; i >= 0; i--) {
          tmp = this.reservation.Spaces[i].StartMinutes
          if (tmp < lowest) {
            lowest = tmp
          }
        }
        minStartMinutes = lowest
      }
      return minStartMinutes
    },

    resEnd() {
      let maxEndMinutes = 0
      if (this.reservation && this.reservation.Spaces.length > 0) {
        var highest = Number.NEGATIVE_INFINITY
        var tmp
        for (var i = this.reservation.Spaces.length - 1; i >= 0; i--) {
          tmp = this.reservation.Spaces[i].EndMinutes
          if (tmp > highest) {
            highest = tmp
          }
        }
        maxEndMinutes = highest
      }
      return maxEndMinutes
    },

    total() {
      let total = 0.0

      if (this.reservation.Spaces.length > 0) {
        for (let i = 0; i <= this.reservation.Spaces.length; i++) {
          let meetingspace = this.reservation.Spaces[i]
          if (meetingspace && meetingspace.PriceTotal > 0) {
            if (!this.reservationHasPackageApplied || !meetingspace.InVoucher) {
              total = total + meetingspace.PriceTotal
            }
          }
        }
      }

      return total
    },
  },

  created() {
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    minutesToTime(minutes) {
      let hour = Number(Math.floor(minutes / 60))
      let minute = Number(minutes - hour * 60)

      return (
        (hour < 10 ? '0' : '') + hour + ':' + (minute < 10 ? '0' : '') + minute
      )
    },

    setShowSpaceSummary(space) {
      this.drawerTitle = 'Space details'
      this.showSideDrawer = true
      this.showSpaceSummary = true

      this.selectedSpace = JSON.parse(JSON.stringify(space))
    },

    setShowAddSpace() {
      this.showAddSpace = true
    },

    setShowEditSpace(space, index) {
      this.selectedSpace = JSON.parse(JSON.stringify(space))
      this.selectedSpace.Index = index

      this.showEditSpace = true
    },

    setShowDeleteSpace(space, index) {
      this.selectedSpace = JSON.parse(JSON.stringify(space))
      this.selectedSpace.Index = index

      this.showDeleteSpace = true
    },

    hideModal() {
      this.showAddSpace = false
      this.showEditSpace = false
      this.showDeleteSpace = false

      this.selectedSpace = null
    },

    hideDrawer() {
      this.drawerTitle = ''
      this.selectedSpace = null
      this.showSpaceSummary = false
      this.showSideDrawer = false
    },
  },
}
</script>

<style lang="scss" scoped>
.edit-space {
  backface-visibility: hidden;
}

.zoom-fade-enter,
.zoom-fade-leave-active {
  opacity: 0;
  //transform: scale(0);
}

.zoom-fade-enter-active,
.zoom-fade-leave-active {
  transition: opacity 0.5s ease;
  //transform: scale(1) 1.5s ease;
}
</style>
