<template>
  <ui-component-modal
    :modalTitle="$t('Components.Reservation.ModalAddContact.Header_Title')"
    :disableSaveButton="isNameEmpty"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="addContact"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <table class="table is-fullwidth is-striped">
        <tbody>
          <tr class="has-text-vertical-middle">
            <td>
              {{ $t('Components.Reservation.ModalAddContact.Label_Type') }}
            </td>
            <td>
              <div class="select">
                <select v-model="newContact.Type">
                  <option
                    v-for="(contactType, index) in contactTypes"
                    :key="index"
                    :value="contactType.Type"
                    :selected="contactType.Type === newContact.Type"
                  >{{ contactType.Name }}</option>
                </select>
              </div>
            </td>
          </tr>
          <tr class="has-text-vertical-middle">
            <td>
              {{ $t('Components.Reservation.ModalAddContact.Label_Name') }}
            </td>
            <td>
              <input
                type="text"
                class="input"
                v-model="newContact.Name"
              />
            </td>
          </tr>
          <tr class="has-text-vertical-middle">
            <td>
              {{ $t('Components.Reservation.ModalAddContact.Label_Email') }}
            </td>
            <td>
              <input
                type="text"
                class="input"
                v-model="newContact.Email"
              />
            </td>
          </tr>
          <tr class="has-text-vertical-middle">
            <td>
              {{ $t('Components.Reservation.ModalAddContact.Label_Phone') }}
            </td>
            <td>
              <input
                type="text"
                class="input"
                v-model="newContact.Phone"
              />
            </td>
          </tr>
          <tr class="has-text-vertical-middle">
            <td>
              {{
                $t('Components.Reservation.ModalAddContact.Label_ReceiveEmail')
              }}
            </td>
            <td>
              <div class="tabs is-toggle is-small">
                <ul>
                  <li :class="{ 'is-active': newContact.ReceiveEmail }">
                    <a @click="newContact.ReceiveEmail = true">
                      <span>{{
                        $t('Components.Reservation.ModalAddContact.Label_Yes')
                      }}</span>
                    </a>
                  </li>
                  <li :class="{ 'is-active': !newContact.ReceiveEmail }">
                    <a @click="newContact.ReceiveEmail = false">
                      <span>{{
                        $t('Components.Reservation.ModalAddContact.Label_No')
                      }}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventBus/event-bus'
import reservationProvider from '@/providers/reservation'

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      contactTypes: [],
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      newContact: {
        Type: 'Booker',
        Name: '',
        Email: '',
        Phone: '',
        ReceiveEmail: true,
      },
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),

    isNameEmpty() {
      return this.newContact.Name.length === 0
    },
  },

  created() {
    this.contactTypes.push({
      TypeId: 0,
      Type: 'Booker',
      Name: this.$t('Components.Reservation.ModalAddContact.Type_Booker'),
    })
    this.contactTypes.push({
      TypeId: 1,
      Type: 'DuringEvent',
      Name: this.$t('Components.Reservation.ModalAddContact.Type_DuringEvent'),
    })
    this.contactTypes.push({
      TypeId: 2,
      Type: 'Trainer',
      Name: this.$t('Components.Reservation.ModalAddContact.Type_Trainer'),
    })
    this.contactTypes.push({
      TypeId: 3,
      Type: 'Speaker',
      Name: this.$t('Components.Reservation.ModalAddContact.Type_Speaker'),
    })
    this.contactTypes.push({
      TypeId: 5,
      Type: 'Host',
      Name: this.$t('Components.Reservation.ModalAddContact.Type_Host'),
    })
    this.contactTypes.push({
      TypeId: 4,
      Type: 'Invoice',
      Name: this.$t('Components.Reservation.ModalAddContact.Type_Invoice'),
    })
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    addContact() {
      let self = this
      self.isSaving = true

      let reservation = JSON.parse(JSON.stringify(self.reservation))

      reservationProvider.methods
        .addReservationContact(self.reservation.Id, self.newContact)
        .then((response) => {
          if (response.status === 200) {
            self.isSavingSuccess = true

            reservation.Contacts.push(response.data)
            self.setReservation(reservation)

            setTimeout(() => {
              this.onClickCancel()
            }, 1500)

            EventBus.$emit('showToast', {
              type: 'info',
              message:
                self.newContact.Name + this.$t('Components.Reservation.ModalAddContact.Message_ContactAdded'),
            })
          }
        })
        .catch(() => {
          self.isSavingError = true
        })
        .finally(() => {
          self.isSaving = false
        })
    },
  },
}
</script>
