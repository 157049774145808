<template>
  <ui-component-modal
    :modalTitle="$t('Components.Reservation.ModalAddOption.Title_AddOption')"
    :modalSize="modalSize"
    :onClickCancel="onClickCancel"
    :onClickSave="saveReservation"
    :showModal="showModal"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :savingSuccessMessage="'Data saved'"
    :savingErrorMessage="'Something went wrong'"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :disableSaveButton="selectedOptions.length === 0"
    :buttonLabelConfirm="textSaveButton"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <transition
        name="fade"
        mode="out-in"
      >
        <ui-loader
          key="loader"
          v-if="isLoading"
        />
        <message
          key="messageNoOptions"
          class="is-info"
          v-if="!isLoading && !locationOptions.length"
        >
          {{ $t('Components.Reservation.ModalAddOption.Message_NoOptionsAvailable') }}</message>
        <div
          key="availableOptions"
          v-if="!isLoading && locationOptions.length"
        >
          <div class="tabs is-boxed">
            <ul>
              <template v-for="(category, index) in tabs">
                <li
                  :key="index"
                  @click="changeCategory(category)"
                  :class="{ 'is-active': selectedCategory === category.categoryId }"
                >
                  <a>{{ category.label }}</a>
                </li>
              </template>
            </ul>
          </div>
          <transition
            name="fade"
            mode="out-in"
          >
            <div :key="'t_'+selectedCategory">
              <div v-if="displayOptions.length">
                <AvailableOption
                  v-for="option in displayOptions"
                  :key="'addOption-' + option.OptionId"
                  :option="option"
                  class="has-margin-bottom"
                />
              </div>
              <message
                key="messageNoOptions"
                class="is-info"
                v-if="!displayOptions.length"
              >
                {{ $t('Components.Reservation.ModalAddOption.Message_AllOptionsSelected', { category: selectedCategoryName }) }}</message>
            </div>
          </transition>
        </div>
      </transition>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import reservationProvider from '../../providers/reservation'
import optionProvider from '../../providers/option'
import AvailableOption from '@/components/Reservation/AvailableOptions/AvailableOption'
import Message from '../UI/Message.vue'
import { EventBus } from '@/eventBus/event-bus'

export default {
  components: {
    // Message,
    AvailableOption,
    Message
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      changeAllInSet: false,
      isSavingError: false,
      isSavingSuccess: false,
      isSaving: false,
      locationOptions: [],
      mReservation: null,
      searchTerm: '',
      selectedCategory: 1,
      selectedSpace: 0,
      isLoading: true
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),

    modalSize() {
      return !this.isLoading && !this.locationOptions.length ? '' : 'large'
    },

    selectedCategoryName() {
      const categoryTab = this.tabs.find(t => t.categoryId === this.selectedCategory)
      return typeof categoryTab !== 'undefined' ? categoryTab.label : ''
    },

    /**
     *  The option tabs
     */
    tabs() {
      let tabs = []

      // If we have highlighted options, those go first.
      if (this.highlightedOptions.length > 0) {
        tabs.push({
          label: this.$t('Components.Reservation.AvailableOptions.Tab_Highlighted'),
          categoryId: 0,
          options: this.highlightedOptions,
        })
      }

      // Next go through the available categories
      this.availableOptionCategories.forEach((category) => {
        if (this.availableOptionsByCategory[category.CategoryName] && this.availableOptionsByCategory[category.CategoryName].length) {
          tabs.push({
            label: this.$t(`Components.Reservation.AvailableOptions.Tab_CategoryId_${category.CategoryId}`),
            categoryId: category.CategoryId,
            options: this.availableOptionsByCategory[category.CategoryName],
          })
        }
      })

      return tabs
    },

    /**
     * Sort the available options by category
     */
    availableOptionsByCategory() {
      return this.locationOptions.reduce((result, option) => {
        if (!result.hasOwnProperty(option.CategoryName)) {
          result[option.CategoryName] = []
        }
        result[option.CategoryName].push(option)

        return result
      }, {})
    },


    /**
   * Go through the available options, and compile a list of unique categories
   */
    availableOptionCategories() {
      const categories = this.locationOptions
        .reduce((result, option) => {
          // Ignore categories that have already been listed
          if (
            result.find((category) => category.CategoryId === option.CategoryId)
          ) {
            return result
          }

          result.push({
            CategoryId: option.CategoryId,
            CategoryName: option.CategoryName,
          })

          return result
        }, [])
        .sort((a, b) => a.CategoryId - b.CategoryId)

      return categories
    },


    /**
     * Get the three top ranking available options, which are to be highlighted
     */
    highlightedOptions() {
      return this.locationOptions
        ? this.locationOptions
          .filter((option) => option.Rank !== 0)
          .sort((a, b) => b.Rank - a.Rank)
          .slice(0, 6)
        : []
    },

    filteredItems() {
      let result = []
      result = this.locationOptions.filter(
        (item) => item.CategoryId === this.selectedCategory
      )
      return result
    },

    displayOptions() {
      let result = []
      if (this.filteredItems.length > 0) {
        for (let i = 0; i < this.filteredItems.length; i++) {
          let reservationOptionIndex = this.reservation.Options.findIndex(
            (o) =>
              o.OptionId === this.filteredItems[i].OptionId
          )

          if (reservationOptionIndex === -1) {
            result.push(this.filteredItems[i])
          }
        }
      }
      return result
    },

    selectedOptions() {
      let output = []
      output = this.locationOptions.filter(o => o.IsSelected === true)
      return output
    },

    // Create save button text based on the number of selected options
    textSaveButton() {
      return this.$tc('Components.Reservation.ModalAddOption.Button_AddOptions', this.selectedOptions.length)
    }
  },

  watch: {
  },

  created() {
    this.mReservation = JSON.parse(JSON.stringify(this.reservation))
    this.getReservationOptions()
  },

  beforeDestroy() {
    this.setReservationRestoreOptions([])
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation', 'setReservationRestoreOptions']),

    changeCategory(tab) {
      this.selectedCategory = tab.categoryId
    },

    getReservationOptions() {
      this.isLoading = true

      optionProvider.methods
        .getReservationOptions(this.reservation.Id, 0, this.searchTerm)
        .then((response) => {
          if (response.status === 200) {
            // Filter out the require 
            this.locationOptions = response.data

            this.setReservationRestoreOptions(this.$objectHelper.cleanSource(this.locationOptions))
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    saveReservation() {
      let self = this

      if (self.isSaving) { return }

      self.isSaving = true
      let newOptions = []
      for (let i = 0; i < this.selectedOptions.length; i++) {
        let newOption = this.selectedOptions[i]

        newOptions.push({
          ReservationId: Number(self.mReservation.Id),
          OptionId: newOption.OptionId,
          CategoryId: newOption.CategoryId,
          Quantity: newOption.Amount,
          CurrencyId: newOption.CurrencyId,
          PricePerItem: newOption.PricePerItem,
          PriceTotal: this.calculateTotalPrice(newOption),
          Crc: newOption.Crc,
          TaxId: newOption.TaxId,
          TaxPercentage: newOption.TaxPercentage,
          Id: 0,
          ChangeAllInSet: false,
          IsPP: newOption.IsPP,
          IsPerHour: newOption.IsPerHour,
          SelectedTime: newOption.SelectedTime,
          SpaceId: newOption.SpaceId,
        })
      }

      reservationProvider.methods.addReservationOptions(
        self.mReservation.Id,
        newOptions
      )
        .then((response) => {
          if (response.status === 200) {
            // Update reservation store
            self.setReservation(response.data)

            // Show success saved message
            EventBus.$emit('showToast', {
              type: 'info',
              message: this.$t('General.Message_ReservationSaved'),
            })

            // Close model
            this.onClickCancel()
          }
        })
        .catch((error) => {
          self.isSavingError = true
        })
        .finally(() => {
          self.isSaving = false
        })
    },

    calculateTotalPrice(option) {
      let total = option.Amount * option.PricePerItem

      if (option.MaxTotal > 0 && total > option.MaxTotal) {
        total = option.MaxTotal
      }

      return total
    },
  },
}
</script>
