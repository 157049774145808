<template>
  <div>
    <div class="searchForm">
      <div class="columns is-mobile is-mulitline">
        <div
          v-if="meetingtypeId !== 2"
          class="column is-2"
        >
          <p class="control has-icons-left">
            <input
              type="text"
              v-model.number="mSeats"
              @keyup.13="getAvailability"
              class="input"
              :class="{ 'is-danger': seatsErrors.length }"
              @input="() => {
              $v.mSeats.$touch()}"
              @blur="$v.mSeats.$touch()"
            />
            <span class="icon is-small is-left">
              <font-awesome-icon :icon="['fas', 'user-check']" />
            </span>
          </p>
          <div
            v-if="seatsErrors.length"
            class="has-text-danger is-size-7"
            v-html="seatsErrors"
          >
          </div>
        </div>

        <div
          v-if="meetingtypeId < 3"
          class="column column-time"
          :class="{'has-error': endTimeErrors.length}"
        >
          <div class="field is-inline-flex has-addons has-margin-right-x2">
            <div class="control has-icons-left">
              <div
                class="select is-fullwidth"
                :class="{'is-danger': endTimeErrors.length}"
              >
                <select v-model="mStartTime">
                  <option
                    v-for="timeSlot in timeSlots"
                    :key="timeSlot"
                    :value="timeSlot"
                    :selected="mStartTime === timeSlot"
                  >{{ minutesToTime(timeSlot) }}</option>
                </select>
              </div>
              <div class="icon is-small is-left">
                <font-awesome-icon :icon="['fas', 'clock']" />
              </div>
            </div>
            <div class="control has-padding-left-half has-padding-right-half"> — </div>
            <div class="control has-icons-left">
              <div
                class="select is-fullwidth"
                :class="{'is-danger': endTimeErrors.length}"
              >
                <select
                  v-model="mEndTime"
                  :error="endTimeErrors"
                  @input="$v.mEndTime.$touch()"
                  @blur="$v.mEndTime.$touch()"
                >
                  <option
                    v-for="timeSlot in timeSlots"
                    :key="timeSlot"
                    :value="timeSlot"
                    :selected="mEndTime === timeSlot"
                  >{{ minutesToTime(timeSlot) }}</option>
                </select>
              </div>
              <div class="icon is-small is-left">
                <font-awesome-icon :icon="['fas', 'clock']" />
              </div>
            </div>
          </div>
          <div
            v-if="endTimeErrors.length"
            class="errorMessage has-text-danger is-size-7"
            v-html="endTimeErrors"
            style="margin"
          >
          </div>

          <button
            @click="getAvailability"
            class="button"
            :class="{
          'is-loading': isSearching,
          'is-primary': !blockSearchButton,
          'is-disabled': blockSearchButton,
        }"
            :disabled="blockSearchButton"
          >
            {{ $t('Components.Availability.CheckAvailability.Button_Search') }}
          </button>
        </div>

        <!-- <div class="column">
          <div class="control has-icons-left">
            <div
              class="select is-fullwidth"
              :class="{ 'is-loading': isLoadingSpaces }"
            >
              <select v-model="mSpaceId">
                <option
                  :value="0"
                  :select="mSpaceId === 0"
                >{{
              $t(
                'Components.Availability.CheckAvailability.Select_OptionAllSpaces'
              )
            }}</option>
                <option
                  v-for="space in filteredSpaces"
                  :key="space.Id"
                  :value="space.Id"
                  :select="mSpaceId === space.Id"
                >{{ space.Name }}</option>
              </select>
            </div>
            <div class="icon is-small is-left">
              <font-awesome-icon :icon="['fas', 'chair']" />
            </div>
          </div>
        </div>

        <div class="column">
          <div class="control has-icons-left">
            <div
              v-if="meetingtypeId === 1"
              class="select is-fullwidth"
            >
              <select v-model="mSettingId">
                <option
                  :value="0"
                  :selected="mSettingId === 0"
                >{{
              $t(
                'Components.Availability.CheckAvailability.Select_OptionAllConfigurations'
              )
            }}</option>
                <option
                  v-for="setting in filteredSettings"
                  :key="setting.SettingId"
                  :value="setting.SettingId"
                  :selected="setting.SettingId === mSettingId"
                >{{ setting.SettingId | getSettingName }} ({{setting.MinSeats}} - {{setting.MaxSeats}})</option>
              </select>
            </div>
            <div
              v-if="meetingtypeId === 1"
              class="icon is-small is-left"
            >
              <font-awesome-icon :icon="['fas', 'sliders-h']" />
            </div>
          </div>
        </div> -->
      </div>

      <!-- <div class="has-text-right">
        <button
          @click="getAvailability"
          class="button"
          :class="{
          'is-loading': isSearching,
          'is-success': !blockSearchButton,
          'is-disabled': blockSearchButton,
        }"
          :disabled="blockSearchButton"
        >
          {{ $t('Components.Availability.CheckAvailability.Button_Search') }}
        </button>
      </div> -->
    </div>

    <hr />
    <ui-loader v-if="isSearching" />
    <template v-if="!isSearching && availablity.Locations">
      <AvailableLocation
        v-for="location in availablity.Locations"
        :key="location.Id"
        :availableLocation="location"
        :meetingtypeId="meetingtypeId"
        :selectedSpaceId="mSpaceId"
      />
    </template>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapMutations } from 'vuex'
import availabilityProvider from '../../../providers/availability'
import spaceProvider from '../../../providers/space'
import { minValue, required } from 'vuelidate/lib/validators'
import AvailableLocation from './AvailableLocation'

export default {
  name: 'CheckAvailability',

  components: {
    AvailableLocation
  },

  props: {
    autoSearch: {
      default: false,
      type: Boolean,
    },
    canChangeDate: {
      default: false,
      type: Boolean,
    },
    channelId: {
      default: 0,
      type: Number,
    },
    countryId: {
      default: 0,
      type: Number,
    },
    endDate: {
      default: function() {
        return new Date()
      },
      type: Date,
    },
    endTime: {
      default: 1020,
      type: Number,
    },
    locationId: {
      default: 0,
      type: Number,
    },
    meetingtypeId: {
      default: 1,
      type: Number
    },
    seats: {
      default: 1,
      type: Number,
    },
    settingId: {
      default: 0,
      type: Number,
    },
    showDateInput: {
      default: false,
      type: Boolean,
    },
    startDate: {
      default: function() {
        return new Date()
      },
      type: Date,
    },
    startTime: {
      default: 540,
      type: Number,
    },
    tenderId: {
      default: 0,
      type: Number,
    },
    voucherId: {
      default: 0,
      type: Number,
    },
    locationOpeningHours: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      availablity: {},
      availabilityChecked: false,
      closeTime: 0,
      dateOne: this.startDate,
      dateTwo: this.endDate,
      fieldId: new Date().getTime(),
      isLoadingSettings: false,
      isLoadingSpaces: false,
      isSearching: false,
      mEndTime: this.endTime,
      mSeats: this.meetingtypeId === 2 ? 1 : this.seats,
      mSettingId: this.settingId,
      mSpaceId: 0,
      mStartTime: this.startTime,
      openTime: 0,
      searchTerm: '',
      settings: [],
      spaces: [],
      timeSlots: [],
    }
  },

  /**
   * Form validation rules
   */
  validations() {
    let output = {
      mEndTime: {
        minValue: value => value > this.mStartTime
      },

      mSeats: {
        required,
        minValue: minValue(1),

      },
    }
    return output
  },

  computed: {
    filteredSpaces() {
      let spaces = this.spaces

      if (this.meetingtypeId > 0) {
        spaces = spaces.filter((s) =>
          s.Meetingtypes.some((m) => m.MeetingtypeId === this.meetingtypeId)
        )
      }

      return spaces
    },

    filteredSettings() {
      let settings = this.settings

      if (this.mSpaceId > 0) {
        let spaceIndex = this.spaces.findIndex((s) => s.Id === this.mSpaceId)
        if (spaceIndex > -1) {
          let space = this.spaces[spaceIndex]
          settings = space.Settings
        }
      }

      if (this.mSeats > 0 && this.mSpaceId > 0) {
        settings = settings.filter(
          (s) => s.MinSeats <= this.mSeats && s.MaxSeats >= this.mSeats
        )
      }

      return settings
    },

    blockSearchButton() {
      let self = this
      return self.seatsErrors.length > 0 || self.endTimeErrors.length > 0
    },

    endTimeErrors() {
      let errors = ''
      if (!this.$v.mEndTime.$dirty) return errors
      if (!this.$v.mEndTime.minValue) {
        errors = this.$t('Components.Availability.CheckAvailability.Message_TimeError')
      }
      return errors
    },

    seatsErrors() {
      let errors = ''
      if (!this.$v.mSeats.$dirty) return errors
      if (!this.$v.mSeats.required) {
        errors = this.$t('Components.Availability.CheckAvailability.Message_Required')
      }
      if (!this.$v.mSeats.minValue) {
        errors = this.$t('Components.Availability.CheckAvailability.Message_SeatsError')
      }
      return errors
    },
  },

  watch: {
    mSpaceId: {
      immediate: true,
      handler: function(val) {
        if (val === 0) {
          this.mSettingId = 0
        }
      }
    },
  },

  created() {
    this.fillTimeSlots()
    // this.getLocationSpaces()
  },

  mounted() {
    if (this.autoSearch) {
      this.getAvailability()
    }
  },

  beforeDestroy() {
    this.availablity = []
  },

  methods: {
    ...mapMutations('spaceStore', ['setSpacesPageState']),

    formatDates(date = '') {
      let formattedDates = ''

      if (date) {
        formattedDates = this.$options.filters.dateFormat(
          date,
          this.$i18n.locale,
          'longDateFormat'
        )
      }
      return formattedDates
    },

    fillTimeSlots() {
      let min = this.locationOpeningHours ? this.locationOpeningHours.MinMinutes : 0
      let max = this.locationOpeningHours ? this.locationOpeningHours.MaxMinutes : 1440
      let minutes = this.meetingtypeId === 1 ? 30 : 15

      while (min <= max) {
        this.timeSlots.push(min)
        min = min + minutes
      }
    },

    minutesToTime(minutes) {
      let hour = Number(Math.floor(minutes / 60))
      let minute = Number(minutes - hour * 60)

      return (
        (hour < 10 ? '0' : '') + hour + ':' + (minute < 10 ? '0' : '') + minute
      )
    },

    getLocationSpaces() {
      let self = this
      self.isLoadingSpaces = true

      spaceProvider.methods
        .getLocationSpaces(self.locationId, '', this.meetingtypeId, self.mSeats, 0, 0)
        .then((response) => {
          if (response.status === 200) {
            this.spaces = response.data.Results
          }
        })
        .finally(() => {
          this.isLoadingSpaces = false
        })
    },

    getActiveSpaceConfigurations() {
      this.isLoadingSettings = true

      spaceProvider.methods
        .getActiveSpaceConfigurations(this.mLocationId)
        .then((response) => {
          if (response.status === 200) {
            for (let i = 0; i < response.data.length; i++) {
              let settingId = response.data[i]
              this.settings.push({
                SettingId: settingId,
                MinSeats: 0,
                MaxSeats: 0,
              })
            }
          }
        })
        .finally(() => {
          this.isLoadingSettings = false
        })
    },

    getAvailability() {
      let self = this
      self.availablity = {}
      this.$v.$touch()
      if (this.$v.$invalid) {
        // 'ERROR'
        self.isSearching = false
      } else {
        self.isSearching = true

        let startDate = self.$options.filters.dateObjectIsoDateString(
          self.dateOne
        )
        let endDate = self.$options.filters.dateObjectIsoDateString(self.dateTwo)

        availabilityProvider.methods
          .getAvailability(
            startDate,
            self.meetingtypeId < 3 ? startDate : endDate,
            self.channelId,
            self.countryId,
            self.locationId,
            self.searchTerm,
            self.meetingtypeId,
            self.meetingtypeId === 2 ? 1 : self.mSeats,
            self.mStartTime,
            self.mEndTime,
            self.meetingtypeId === 2 ? 0 : self.mSettingId,
            self.mSpaceId,
            self.voucherId,
            self.tenderId,
          )
          .then((response) => {
            self.availablity = response.data

            if (self.availablity && self.availablity.Locations.length > 0) {
              let searchId = self.availablity.Locations[0].SearchId
              self.$emit('searchInitiated', searchId)
            }
          })
          .finally(() => {
            self.isSearching = false
            self.availabilityChecked = true
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.column-time {
  &.has-error {
    .field.has-addons {
      margin-bottom: 0;
    }
  }
}
</style>