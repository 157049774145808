import axios from 'axios'
import store from '../store/store'

export default {
  methods: {
    async getInvoiceById(invoiceId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2 }/api/v1/invoice/${invoiceId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          },
        }
      )
      return response
    },

    async getInvoicePdf(invoiceId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2 }/api/v1/invoice/${invoiceId}/pdf`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          },
        }
      )
      return response
    }
  }
}