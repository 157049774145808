<template>
  <div>
    <ModalEditReservationDetail
      v-if="showEditData"
      :showModal="showEditData"
      :onClickCancel="hideModal"
    />

    <ui-section-header
      :showEdit="!isLocked"
      :editFunction="setShowEditData"
      :editLabel="$t('Components.Reservation.BasicData.Link_Edit')"
    >
      <template v-slot:title>{{
        $t('Components.Reservation.BasicData.Section_Title')
      }}</template>
    </ui-section-header>

    <table class="table is-fullwidth">
      <tbody>
        <tr>
          <td>
            {{ $t('Components.Reservation.BasicData.Label_Name') }}
          </td>
          <td>
            <span>{{ reservation.Name }}</span>
          </td>
        </tr>
        <tr>
          <td>
            {{ $t('Components.Reservation.BasicData.Label_Profile') }}
          </td>
          <td>
            <span v-if="reservation.ProfileId > 0">{{
                reservation.ProfileName
              }}</span>
          </td>
        </tr>
        <tr>
          <td>
            {{ $t('Components.Reservation.BasicData.Label_Company') }}
          </td>
          <td>
            <span v-if="reservation.CompanyId > 0">{{
                reservation.CompanyName
              }}</span>
          </td>
        </tr>
        <tr>
          <td>
            {{ $t('Components.Reservation.BasicData.Label_Status') }}
          </td>
          <td>
            <div>
              <span v-text="statusLabel"></span>
            </div>
          </td>
        </tr>
        <tr v-if="reservation.StatusId === 3">
          <td>
            {{ $t('Components.Reservation.BasicData.Label_CancelReason') }}
          </td>
          <td>
            <div v-if="reservation.Cancel">
              <div v-if="reservation.Cancel.ReasonType === 'Other'">
                {{reservation.Cancel.Reason}}
              </div>
              <div v-else>
                {{ $t(`Components.Reservation.BasicData.CancelText_${reservation.Cancel.ReasonType}`) }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            {{ $t('Components.Reservation.BasicData.Label_Language') }}
          </td>
          <td>
            <div>
              {{ $t('LanguageLabels.Language' + reservation.LanguageId) }}
            </div>
          </td>
        </tr>
        <tr v-if="!isLocked">
          <td>
            {{ $t('Components.Reservation.BasicData.Label_Voucher') }}
          </td>
          <td>
            <CheckVoucher
              :reservation="reservation"
              :isLocked="isLocked"
              @reservationVoucherApplied="reservationVoucherApplied"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

import CheckVoucher from '@/components/Vouchers/CheckVoucher'
import ModalEditReservationDetail from '@/components/Reservation/ModalEditReservationDetail'

export default {
  components: {
    CheckVoucher,
    ModalEditReservationDetail,
  },

  props: {
    isEmployee: {
      default: false,
      type: Boolean,
    },

    isLocked: {
      default: true,
      type: Boolean,
    },
  },

  data() {
    return {
      showEditData: false,
      showSideDrawer: false,
      showReviveReservation: false
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),

    statusLabel() {
      let name = ''

      switch (this.reservation.StatusId) {
        case -1:
          name = 'Denied'
          break
        case 0:
          name = 'Pending approval'
          break
        case 1:
          name = 'Optional'
          break
        case 2:
          name = 'Final'
          break
        case 3:
          name = 'Cancelled'
          break
      }

      return name
    }
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    createProposal() { },

    reservationVoucherApplied(reservation) {
      this.setReservation(reservation)
    },

    setShowEditData() {
      this.showEditData = true
    },

    hideModal() {
      this.showEditData = false
      this.showReviveReservation = false
    },

    setShowReviveReservation() {
      this.showReviveReservation = true
    },

    hideDrawer() {
      this.itemId = 0
      this.showSideDrawer = false
      this.showReviveReservation = false
    },
  },
}
</script>
