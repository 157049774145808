var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"LocationOption"},[_c('figure',{staticClass:"LocationOption__image has-background-grey-light",style:({
      backgroundImage: `url('${_vm.$options.filters.getLocationImage(
        _vm.option.OptionImage,
        _vm.option.LocationId,
        '640'
      )}')`,
    })}),_c('div',{staticClass:"LocationOption__detail_price"},[_c('div',{staticClass:"LocationOption__details has-padding"},[_c('div',{class:`title-option is-size-5 has-text-weight-bold`,domProps:{"innerHTML":_vm._s(_vm.name)}}),(_vm.upsellItems.length)?_c('UpsellTemplate',{staticClass:"has-margin-bottom",attrs:{"upsellItems":_vm.upsellItems}}):_vm._e()],1),_c('div',{staticClass:"LocationOption__price_control has-background-info-light has-padding has-text-centered"},[_c('div',{staticClass:"has-margin-bottom"},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('Components.Reservation.AvailableOptions.Text_PerItem') + ':')}}),_c('span',{staticClass:"has-text-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.option.PricePerItem,'nl', _vm.option.CurrencyIso))+" ")]),_c('span',{staticClass:"has-margin-left price-total"},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('Components.Reservation.AvailableOptions.Text_Total') + ':')}}),_c('span',{staticClass:"has-text-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.totalPrice,'nl', _vm.option.CurrencyIso))+" ")])])]),_c('AvailableOptionAmountControls',{staticClass:"LocationOption__pricing__control has-margin-bottom",attrs:{"option":_vm.option}}),(_vm.isSelected)?[_c('div',{staticClass:"has-margin-bottom price-total-bottom"},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('Components.Reservation.AvailableOptions.Text_Total') + ':')}}),_c('span',{staticClass:"has-text-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.totalPrice,'nl', _vm.option.CurrencyIso))+" ")])]),_c('div',[_c('a',{staticClass:"has-icon has-text-danger",on:{"click":_vm.handleDeleteOption}},[_c('span',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'trash-alt']}})],1),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('Form.Control.Delete'))}})])])]:_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }