<template>
  <ui-component-modal
    :modalTitle="$t('Components.Reservation.ModalDeleteContact.Header_Title')"
    confirmButtonCssClass="is-danger"
    :buttonLabelConfirm="$t('Form.Control.Delete')"
    :isSaving="isDeleting"
    :isSavingSuccess="isDeletingSuccess"
    :isSavingError="isDeletingError"
    :hideFooterButtons="isDeletingError || isDeletingSuccess"
    :disableSaveButton="!isDeleteConfirmed"
    :onClickCancel="onClickCancel"
    :onClickSave="deleteContact"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div>
        <div class="has-margin-bottom is-size-5 has-text-centered">
          {{
        $t('Components.Reservation.ModalDeleteContact.Text_AreYouSure1')
      }} <b>{{ contact.Name }}</b> {{
        $t('Components.Reservation.ModalDeleteContact.Text_AreYouSure2')
      }}
        </div>
        <div class="has-text-centered">
          <BaseCheckbox
            v-model="isDeleteConfirmed"
            class="has-text-left"
            :label="$t('Components.Reservation.ModalDeleteContact.Text_YesSure')"
          />
        </div>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventBus/event-bus'
import reservationProvider from '../../providers/reservation'

const BaseCheckbox = () =>
  import(
    /* webpackChunkName: "settings-options" */ '@/components/UI/Form/BaseCheckbox'
  )

export default {
  components: {
    BaseCheckbox,
  },

  props: {
    contact: null,

    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isDeleteConfirmed: false,
      isDeleting: false,
      isDeletingSuccess: false,
      isDeletingError: false,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),
  },

  created() { },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    deleteContact() {
      let self = this
      self.isDeleting = true

      reservationProvider.methods
        .deleteReservationContact(self.reservation.Id, self.contact.Id)
        .then((response) => {
          if (response.status === 204) {
            self.isDeletingSuccess = true

            let contactIndex = self.reservation.Contacts.findIndex(
              (c) => c.Id === self.contact.Id
            )
            if (contactIndex > -1) {
              let reservation = JSON.parse(JSON.stringify(self.reservation))
              reservation.Contacts.splice(contactIndex, 1)
              self.setReservation(reservation)
            }

            setTimeout(() => {
              self.onClickCancel()
            }, 1500)

            EventBus.$emit('showToast', {
              type: 'info',
              message: this.$t('Components.Reservation.ModalDeleteContact.Text_ContactRemoved', { name: self.contact.Name }),
            })
          }
        })
        .catch(() => {
          self.isDeletingError = true
        })
        .finally(() => {
          self.isDeleting = false
        })
    },
  },
}
</script>
