<template>
  <ui-component-modal
    :modalTitle="
      $t('Components.Reservation.ModalEditOption.Header_ModalHeader') +
        ' ' +
        mOption.Name
    "
    modalSize="medium"
    :showModal="showModal"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickSave="saveReservation"
    :onClickCancel="onClickCancel"
    :disableSaveButton="disableSaveButton"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th width="125">
              {{ $t('Components.Reservation.ModalEditOption.Label_Quantity') }}
            </th>
            <th>
              {{ $t('Components.Reservation.ModalEditOption.Label_Name') }}
            </th>
            <th v-if="mOption.TimeSelectable === 2">
              {{ $t('Components.Reservation.ModalEditOption.Label_Time') }}
            </th>
            <th
              class="has-text-right"
              v-text="$t('Components.Reservation.ModalEditOption.Label_Price')"
            ></th>
            <th
              width="100"
              class="has-text-right"
              v-text="$t('Components.Reservation.ModalEditOption.Label_Total')"
            >
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="has-text-vertical-middle">
            <td>
              <div
                v-if="mOption.RequiredItem !== 2"
                class="field has-addons"
              >
                <p class="control">
                  <InputNumeric
                    v-model.number="mOption.Amount"
                    :allowNegativeValue="false"
                    :isNumeric="true"
                    :class="{ 'is-danger': !mOption.Amount }"
                  />
                </p>
                <p class="control">
                  <a class="button is-static">X</a>
                </p>
              </div>
              <div v-else>{{ mOption.Amount }} x</div>
            </td>
            <td>
              {{mOption.Name}}
            </td>
            <td v-if="mOption.TimeSelectable === 2">
              <div class="select">
                <select v-model="mOption.SelectedTime">
                  <option
                    :value="-1"
                    :selected="option.SelectedTime === -1"
                  >{{
                    $t(
                      'Components.Reservation.ModalEditOption.Label_NonApplicable'
                    )
                  }}</option>
                  <option
                    v-for="timeSlot in timeSlots"
                    :key="timeSlot"
                    :value="timeSlot"
                    :selected="mOption.SelectedTime === timeSlot"
                  >{{ timeSlot | minutesToTime }}</option>
                </select>
              </div>
            </td>
            <td class="has-text-right">
              <span v-html="mOption.CurrencySymbol"></span>
              <span v-text="mOption.PricePerItem.toFixed(2)"></span>
            </td>
            <td class="has-text-right">
              <span v-html="mOption.CurrencySymbol"></span>
              <span> {{ optionTotalPrice.toFixed(2) }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventBus/event-bus'
import reservationProvider from '../../providers/reservation'

export default {
  components: {
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },

    isEmployee: {
      default: false,
      type: Boolean,
    },

    isLocked: {
      default: false,
      type: Boolean,
    },

    option: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      changeAllInSet: false,
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      mOption: JSON.parse(JSON.stringify(this.option)),
      spaces: [],
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),

    resStart() {
      let self = this
      let minStartMinutes = 0

      if (self.reservation && self.reservation.Spaces.length > 0) {
        let lowest = Number.POSITIVE_INFINITY
        let tmp
        for (let i = self.reservation.Spaces.length - 1; i >= 0; i--) {
          tmp = self.reservation.Spaces[i].StartMinutes
          if (tmp < lowest) {
            lowest = tmp
          }
        }
        minStartMinutes = lowest
      }
      return minStartMinutes
    },

    resEnd() {
      let self = this
      let maxEndMinutes = 0

      if (self.reservation && self.reservation.Spaces.length > 0) {
        let highest = Number.NEGATIVE_INFINITY
        let tmp
        for (let i = self.reservation.Spaces.length - 1; i >= 0; i--) {
          tmp = self.reservation.Spaces[i].EndMinutes
          if (tmp > highest) {
            highest = tmp
          }
        }
        maxEndMinutes = highest
      }
      return maxEndMinutes
    },

    timeSlots() {
      let self = this
      let result = []
      let minutes = 30

      let i = self.resStart
      while (i <= self.resEnd) {
        result.push(i)
        i = i + minutes
      }

      return result
    },

    disableSaveButton() {
      return this.showNoQuantityWarning
    },

    showNoQuantityWarning() {
      let self = this
      return self.mOption.Amount === 0
    },

    showNoPriceWarning() {
      let self = this
      return String(self.mOption.PricePerItem).length === 0
    },

    optionTotalPrice() {
      return this.calculateTotalPrice(this.mOption)
      // let self = this
      // let total = 0.0

      // if (self.mOption) {
      //   total =
      //     Math.round(
      //       Number(self.mOption.Amount) *
      //       Number(self.mOption.PricePerItem) *
      //       100
      //     ) / 100
      // }

      // return total
    },
  },

  created() {
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    saveReservation() {
      let self = this
      if (!self.isSaving) {
        self.isSaving = true

        self.mOption.PriceTotal = self.optionTotalPrice

        let optionIndex = self.reservation.Options.findIndex(
          (o) => o.Id === self.option.Id
        )
        if (optionIndex > -1) {
          self.mOption.PriceTotal = self.optionTotalPrice

          let criteria = {
            ReservationId: self.reservation.Id,
            OptionId: self.mOption.OptionId,
            CategoryId: self.mOption.CategoryId,
            Quantity: self.mOption.Amount,
            CurrencyId: self.mOption.CurrencyId,
            PricePerItem: self.mOption.PricePerItem,
            PriceTotal: this.optionTotalPrice,
            Crc: self.mOption.Crc,
            TaxId: self.mOption.TaxId,
            TaxPercentage: self.mOption.TaxPercentage,
            Id: self.mOption.Id,
            ChangeAllInSet: false,
            IsPP: self.mOption.IsPP,
            IsPerHour: self.mOption.IsPerHour,
            SelectedTime: self.mOption.SelectedTime,
            SpaceId: self.mOption.SpaceId,
          }

          reservationProvider.methods
            .updateReservationOption(self.reservation.Id, criteria)
            .then((response) => {
              if (response.status === 200) {
                // Update reservation store
                self.setReservation(response.data)

                // Send message to display toast on screen
                EventBus.$emit('showToast', {
                  type: 'info',
                  message: this.$t('General.Message_ReservationSaved'),
                })

                // Close modal
                self.onClickCancel()
              }
            })
            .catch((error) => {
              self.isSavingError = true
              self.responseMessage = error.response.data.Value
            })
            .finally(() => {
              self.isSaving = false
            })
        } else {
          self.isSavingError = true
          self.isSaving = false
        }
      }
    },

    // Recalculate option price
    calculateTotalPrice(option) {
      let total = option.Amount * option.PricePerItem

      if (option.MaxTotal > 0 && total > option.MaxTotal) {
        total = option.MaxTotal
      }

      return total
    },
  },
}
</script>
