<template>
  <ui-component-modal
    :modalTitle="
      $t('Components.Reservation.ModalEditReservationDetail.Header_ModalHeader')
    "
    modalSize="medium"
    cssClassModalBody="is-hidden-overflow-x"
    :showModal="showModal"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :disableSaveButton="$v.$error"
    :hideFooterButtons="isSavingError || isSavingSuccess || !showEditData"
    :onClickCancel="onClickCancel"
    :onClickSave="saveReservation"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <div
        v-if="mReservation"
        class="slider-wrapper"
      >
        <transition-group
          tag="div"
          class="div-slider"
          :name="back ? 'slideback' : 'slide'"
        >
          <div
            v-if="showEditData"
            key="detailScreen"
            class="slideContent"
          >
            <table class="table-data table-data table is-fullwidth is-striped">
              <tbody>
                <tr>
                  <td>
                    <span v-text="$t('Components.Reservation.ModalEditReservationDetail.Label_Name')"></span>
                    <span class="has-text-danger">*</span>
                  </td>
                  <td>
                    <span>
                      <BaseBulmaField
                        v-model="mReservation.Name"
                        :error="nameError"
                        :required="true"
                        @input="$v.mReservation.Name.$touch()"
                        @blur="$v.mReservation.Name.$touch()"
                      />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td v-text="$t('Components.Reservation.ModalEditReservationDetail.Label_Language')"></td>
                  <td>
                    <div class="tabs is-toggle is-small has-text-right">
                      <ul>
                        <li
                          v-for="(language, index) in locationLanguages"
                          :key="index"
                          :class="{
                              'is-active': 
                                mReservation.LanguageId === language.LanguageId,
                            }"
                        >
                          <a @click="
                                mReservation.LanguageId = language.LanguageId
                              ">
                            <span>{{
                                $t('LanguageLabels.Language' + language.LanguageId)
                              }}</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </transition-group>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { EventBus } from '@/eventBus/event-bus'
import reservationProvider from '../../providers/reservation'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
    onClickSave: {
      type: Function,
      required: false,
    },
  },

  data() {
    return {
      back: false,
      changeAllInSet: false,
      mReservation: null,
      showEditData: true,
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation', 'locationLanguages']),

    nameError() {
      const errors = []
      if (!this.$v.mReservation.Name.$dirty) return errors
      else if (!this.$v.mReservation.Name.required || !this.$v.mReservation.Name) {
        errors.push(this.$t('Form.InputErrors.RequiredField', { fieldLabel: this.$t('Components.Reservation.ModalEditReservationDetail.Label_Name') }))
      }
      return errors
    },


    selectedLanguage() {
      if (
        this.locationLanguages &&
        this.locationLanguages.length > 0 &&
        this.mReservation
      ) {
        let language = this.locationLanguages.find(
          (l) => l.LanguageId === this.mReservation.LanguageId
        )
        return language
      }
      return null
    },
  },

  /**
   * Form validation rules
   */
  validations: {
    mReservation: {
      Name: {
        required,
      },
    }
  },

  created() {
    this.mReservation = JSON.parse(JSON.stringify(this.reservation))
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    saveReservation() {
      let self = this
      this.$v.$touch()
      if (this.$v.$invalid) {
        // 'ERROR'
      } else {
        self.isSaving = true

        reservationProvider.methods
          .saveReservationBasic(
            this.mReservation.Id,
            this.mReservation.Name,
            this.mReservation.ProfileId,
            this.mReservation.CompanyId,
            this.mReservation.StatusId,
            this.selectedLanguage.Language,
            false
          )
          .then((response) => {
            if (response.status === 200) {
              // Update reservation store
              self.setReservation(response.data)

              // Show success saved message
              EventBus.$emit('showToast', {
                type: 'info',
                message: this.$t('General.Message_ReservationSaved'),
              })

              // Close model
              self.onClickCancel()
            }
          })
          .catch((error) => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.slider-wrapper {
  .table-data {
    td {
      vertical-align: middle;
      &:first-child {
        white-space: nowrap;
      }
      &:nth-child(2) {
        width: 100%;
      }

      .mCart.ProfileName,
      .control-noCompany {
        width: 100%;
        .button {
          width: 100%;
        }
      }
    }
  }
  .column-search-profile-top,
  .column-search-company-top {
    > div {
      height: 100%;
    }
  }
}
</style>
