<template>
  <ui-component-modal
    :modalTitle="$t(`Components.Reservation.ModalAddExternalLink.Main_Title`)"
    :isSaving="isSaving"
    :isSavingError="isSavingError"
    :disableSaveButton="urlEmpty || !linkTypeSelected || isUrlValid"
    :hideFooterButtons="isSavingError"
    :onClickCancel="onClickCancel"
    :onClickSave="addExternalLink"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <Message class="is-info">
        {{$t('Components.Reservation.ModalAddExternalLink.Message_ExternalLinkInfo')}}
      </Message>
      <table
        v-if="newExternalLink"
        class="table is-fullwidth is-striped"
      >
        <tbody>
          <tr>
            <td v-text="$t(`Components.Reservation.ModalAddExternalLink.Label_AddNewLinkType`)">
            </td>
            <td>
              <div class="select">
                <select v-model="newExternalLink.Type">
                  <option
                    :value="'None'"
                    :selected="newExternalLink.Type === 'None'"
                    v-text="$t(`Components.Reservation.ModalAddExternalLink.SelectOption_Choose`)"
                  ></option>
                  <option
                    :value="'VideoCall'"
                    :selected="newExternalLink.Type === 'VideoCall'"
                    v-text="$t(`Components.Reservation.ModalAddExternalLink.SelectOption_VideoCall`)"
                  ></option>
                  <option
                    :value="'Document'"
                    :selected="newExternalLink.Type === 'Document'"
                    v-text="$t(`Components.Reservation.ModalAddExternalLink.SelectOption_Document`)"
                  ></option>
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td v-text="$t(`Components.Reservation.ModalAddExternalLink.Label_AddNewLinkUrl`)"></td>
            <td>
              <input
                type="text"
                class="input"
                v-model="newExternalLink.Link"
                @keyup.enter="addExternalLink"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventBus/event-bus'
import reservationProvider from '../../providers/reservation'
import Message from '@/components/UI/Message'

export default {
  components: {
    Message,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingError: false,
      newExternalLink: {
        Link: 'https://',
        Type: 'None',
      },
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),

    urlEmpty() {
      let result = true
      if (this.newExternalLink && this.newExternalLink.Link.length > 0) {
        result = false
      }

      return result
    },

    linkTypeSelected() {
      let result = true
      if (this.newExternalLink.Type === 'None') {
        result = false
      }
      return result
    },

    isUrlValid() {
      let result = true
      let matcher = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/
      let parsedLink = JSON.parse(JSON.stringify(this.newExternalLink.Link))

      if (
        matcher.test(parsedLink) &&
        !parsedLink.includes('..') &&
        !parsedLink.includes(',')
      ) {
        result = false
      }

      return result
    },
  },

  created() { },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    addExternalLink() {
      let self = this
      self.isSaving = true

      let reservation = JSON.parse(JSON.stringify(self.reservation))

      reservationProvider.methods
        .addExternalLink(self.reservation.Id, self.newExternalLink)
        .then((response) => {
          if (response.status === 200) {
            reservation.ExternalLinks.push(response.data)
            self.setReservation(reservation)

            EventBus.$emit('showToast', {
              type: 'info',
              message:
                self.newExternalLink.Link +
                this.$t(
                  `Components.Reservation.ModalAddExternalLink.Message_LinkIsAdded`
                ),
            })

            this.onClickCancel()
          }
        })
        .catch((error) => {
          self.isSavingError = true
        })
        .finally(() => {
          self.isSaving = false
        })
    },
  },
}
</script>
