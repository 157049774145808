<template>
  <UiComponentDrawer
    v-if="showDrawer"
    :showDrawer="showDrawer"
    :closeFunc="onClickClose"
    position="right"
  >
    <template v-slot:content>
      <transition
        name="fade"
        mode="out-in"
      >
        <ui-loader
          v-if="isLoading"
          key="loading"
        />
        <div
          key="ready"
          v-if="!isLoading && mOption"
        >
          <div class="wrapper-images">
            <figure class="image is-3by2 has-background-grey-lighter">
              <img
                src="@/assets/img/spacer1_1.gif"
                :style="{
              backgroundImage: `url('${$options.filters.getLocationImage(
                mOption.Image,
                locationId,
                '640'
              )}')`,
            }"
              >
            </figure>
          </div>

          <div
            class="title is-3"
            v-text="optionName"
          ></div>
          <UpsellTemplate
            v-if="upsellItems.length"
            :upsellItems="upsellItems"
            class="has-margin-bottom-x2"
          />

          <div v-html="description"></div>
        </div>
      </transition>
    </template>
  </UiComponentDrawer>
</template>

<script>
import UiComponentDrawer from '@/components/UI/Drawer'
import optionProvider from '../../providers/option'
import UpsellTemplate from '../UI/UpsellTemplate'

export default {
  name: 'drawer-location-option-detail',

  props: {
    // drawer props
    drawerTitle: {
      type: String,
      default: ''
    },
    hideTitle: {
      type: Boolean,
      default: false
    },
    onClickClose: {
      type: Function,
      default: function() { },
      required: false
    },
    position: {
      type: String,
      default: 'right'
    },
    showDrawer: {
      type: Boolean,
      default: false
    },

    // props for content
    optionId: {
      type: Number,
      required: true
    },
    locationId: {
      type: Number,
      required: true
    }
  },

  components: {
    UiComponentDrawer,
    UpsellTemplate
  },

  data() {
    return {
      isLoading: true,
      mOption: null,
      currentLanguageId: this.$i18n.locale === 'nl' ? 52 : 65,
    }
  },

  computed: {
    optionName() {
      return this.mOption && this.mOption.Texts
        ? this.mOption.Texts.find(
          (d) => d.LanguageId === this.currentLanguageId
        ).Name
        : ''
    },

    description() {
      return this.mOption && this.mOption.Texts
        ? this.mOption.Texts.find(
          (d) => d.LanguageId === this.currentLanguageId
        ).Description
        : ''
    },

    upsellItems() {
      if (!this.mOption) { return [] }

      let output = []
      this.mOption.Features.forEach(f => {
        if (f.LanguageId === this.currentLanguageId) {
          output.push(f.Body)
        }
      })

      return output
    },
  },

  mounted() {
    document.body.appendChild(this.$el)
    this.getOptionData()
  },

  beforeDestroy() {
    let t = setTimeout(() => {
      if (this.$el.parentNode) {
        this.$el.parentNode.removeChild(this.$el)
      }
      clearTimeout(t)
    }, 100)
  },

  methods: {
    getOptionData() {
      let self = this

      optionProvider.methods
        .getOptionById(this.optionId, this.locationId)
        .then((response) => {
          if (response.status === 200) {
            self.mOption = response.data
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  }
}
</script>

<style lang="scss" scoped>
.wrapper-images {
  margin-top: -$gap;
  margin-right: -$gap;
  margin-bottom: $gap;
  margin-left: -$gap;
}
</style>