<template>
  <div>
    <ModalTerms
      v-if="modalType === 'modalTerms'"
      :showModal="modalType === 'modalTerms'"
      :onClickCancel="hideModal"
    />

    <nav class="navbar-custom-menu">
      <div class="navbar-item">
        <router-link
          :to="{ name: 'Reservations'}"
          class="navbar-link is-arrowless has-icon"
        >
          <span class="icon is-small">
            <i class="fas fa-chevron-left"></i>
          </span>
          <span v-text="$t('Views.Reservations.ReservationDetail.Tab_Reservations')"></span>
        </router-link>
      </div>
    </nav>
    <hr />
    <ui-loader
      key="loading"
      v-if="isLoading"
    />
    <div
      key="loadReady"
      v-if="!isLoading"
    >
      <Message
        v-if="isOutdatedBrowser"
        class="is-warning"
      >
        <template>
          <div
            class="has-text-weight-bold is-size-4"
            v-text="$t('Views.Reservations.ReservationDetail.Title_BrowserOutdated')"
          ></div>
          <i18n
            path="Views.Reservations.ReservationDetail.Message_BrowserOutdated"
            tag="div"
            class="has-margin-bottom"
          >
            <template v-slot:br><br /></template>
            <template v-slot:phone>
              <template v-if="locationManager && locationManager.Phone">
                <br /><strong v-text="$t('Views.Reservations.ReservationDetail.Label_Phone')"></strong> <span v-if="locationManager">{{locationManager.Phone}}</span>
              </template>
            </template>

            <template v-slot:email>
              <template v-if="locationManager && locationManager.Email">
                <br /><strong v-text="$t('Views.Reservations.ReservationDetail.Label_Email')"></strong> <span
                  class="email"
                  v-if="locationManager"
                  v-text="locationManager.Email"
                ></span>
              </template>
            </template>
            <template v-slot:br1><br /><br /></template>
            <template v-slot:br2><br /></template>
            <template v-slot:name>{{locationManager.Name}}</template>
          </i18n>
          <strong
            v-text="$t('Views.Reservations.ReservationDetail.Title_MostUsedBrowsers')"
            class="mr-2"
          ></strong>
          <a
            href="https://www.google.nl/intl/nl/chrome/"
            target="_blank"
          >Chrome</a> - <a
            href="https://www.microsoft.com/edge"
            target="_blank"
          >Edge</a> - <a
            href="https://www.mozilla.org/firefox/new"
            target="_blank"
          >Firefox</a> - <a
            href="https://www.opera.com/download#opera-browser"
            target="_blank"
          >Opera</a> - <a
            href="https://support.apple.com/downloads/safari"
            target="_blank"
          >Safari</a>
        </template>
      </Message>

      <div v-if="messageStatus !== 200">
        <Message class="is-info">
          <template>
            <i18n
              :path="messageResource"
              tag="div"
              class="is-size-5"
            >
              <template v-slot:name>{{ profile.FirstName }}</template>
              <template v-slot:br><br /></template>
            </i18n>
          </template>
        </Message>
        <a
          @click="gotoPreviousPage()"
          class="button"
          v-text="$t('Form.Control.Back')"
        ></a>
      </div>

      <div
        v-if="reservation"
        class="columns is-multiline"
      >
        <div class="column is-full">
          <div
            class="title is-3"
            v-html="pageTitle"
          ></div>
          <div
            class="subtitle is-4 no-margin-bottom"
            v-html="pageSubTitle"
          ></div>
          <span class="is-size-6 has-text-weight-light is-italic has-text-grey">{{ $t('General.Created-on') }}
            {{
            reservation.CreatedOn
              | dateFormat($i18n.locale, 'longDateFormat')
          }}
            {{ ($t('General.On') + ' ' + $t('General.Channel')).toLowerCase() }}
            {{ reservation.ChannelName }}</span>

          <span
            v-if="reservation.ReservationTerms"
            class="is-size-7 has-text-weight-light is-italic has-text-grey"
          >,
            <a @click="setShowBookingTermModal">Terms: {{ reservation.ReservationTerms.BookingTerm.Version }}</a></span>
        </div>
        <div class="column is-full-tablet is-one-quarter-desktop column-pageMenu">
          <PageMenu
            :menuItems="pageMenu"
            class="has-margin-bottom-x2"
          />
          <Message
            class="is-info"
            v-if="locationManager"
          >
            <i18n
              path="Views.Reservations.ReservationDetail.Message_BookingContactLocation"
              tag="div"
            >
              <template v-slot:phone>
                <template v-if="locationManager.Phone">
                  <br /><strong v-text="$t('Views.Reservations.ReservationDetail.Label_Phone')"></strong><br />
                  <span v-if="locationManager">{{locationManager.Phone}}</span>
                </template>
              </template>

              <template v-slot:email>
                <template v-if="locationManager.Email">
                  <br /><strong v-text="$t('Views.Reservations.ReservationDetail.Label_Email')"></strong><br />
                  <span
                    v-if="locationManager"
                    class="email"
                    v-text="locationManager.Email"
                  ></span>
                </template>
              </template>
              <template v-slot:br1><br /><br /></template>
              <template v-slot:br2><br /></template>
              <template v-slot:name>{{locationManager.Name}}</template>
            </i18n>
            {{reservation.LocationName}}
          </Message>
        </div>
        <div class="column">
          <Message
            v-if="reservation.StatusId === 3"
            class="is-warning"
          >{{ $t('Views.Reservations.ReservationDetail.Message_ReservationCancelled')}}</Message>

          <ReservationDetail
            v-if="visiblePageComponent === viewPageComponents.detail"
            :key="viewPageComponents.detail"
            :reservationId="reservation.Id"
            @actionShowInvoice="setShowInvoice"
          />
          <ReservationComments
            v-if="visiblePageComponent === viewPageComponents.comments"
            :key="viewPageComponents.comments"
            :reservationId="reservation.Id"
          />
          <ReservationContacts
            v-if="visiblePageComponent === viewPageComponents.contacts"
            :key="viewPageComponents.contacts"
            :reservationId="reservation.Id"
          />
          <ReservationProgram
            v-if="visiblePageComponent === viewPageComponents.program"
            :key="viewPageComponents.program"
            :reservationId="reservation.Id"
            :endMinutes="reservation.EndMinutes"
            :startMinutes="reservation.StartMinutes"
          />
          <InvoiceDetail
            v-if="visiblePageComponent === viewPageComponents.invoiceDeposit"
            :key="viewPageComponents.invoiceDeposit"
            :invoiceStatusChangeCounter="invoiceStatusChangeCounter"
            :invoiceId="reservation.HasDepositInvoice"
          />

          <InvoiceDetail
            v-if="visiblePageComponent === viewPageComponents.invoiceDetail"
            :key="viewPageComponents.invoiceDetail"
            :invoiceStatusChangeCounter="invoiceStatusChangeCounter"
            :invoiceId="reservation.HasInvoice"
          />
          <ReservationMail
            v-if="visiblePageComponent === viewPageComponents.mail"
            :key="viewPageComponents.mail"
            :reservationId="reservation.Id"
          />
          <ExternalLinks
            v-if="visiblePageComponent === viewPageComponents.externalLinks"
            :key="viewPageComponents.externalLinks"
            :reservationId="reservation.Id"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations, mapState } from 'vuex'
import reservationProvider from '../../providers/reservation'
import locationProvider from '../../providers/location'
import openinghoursProvider from '../../providers/openinghours'

import PageMenu from '@/components/UI/PageMenu'
import ReservationDetail from '@/components/Reservation/ReservationDetail'
import InvoiceDetail from '@/components/Invoices/InvoiceDetail'
import ReservationComments from '@/components/Reservation/Submenu/ReservationComments'
import ReservationContacts from '@/components/Reservation/Submenu/ReservationContacts'
import ReservationProgram from '@/components/Reservation/Submenu/ReservationProgram'
import ReservationMail from '@/components/Reservation/Submenu/ReservationMail'
import ExternalLinks from '@/components/Reservation/Submenu/ReservationExternalLinks'
import ModalTerms from '@/components/Reservation/ModalTerms'
import Message from '../../components/UI/Message.vue'
import VueConfetti from 'vue-confetti'
import { EventBus } from '../../eventBus/event-bus'
Vue.use(VueConfetti)

export default {
  name: 'ViewReservationDetail',

  components: {
    PageMenu,
    ReservationDetail,
    ReservationComments,
    ReservationContacts,
    ReservationProgram,
    ReservationMail,
    ExternalLinks,
    InvoiceDetail,
    ModalTerms,
    Message
  },

  data() {
    return {
      newReservation: false,
      reservationKey: this.$route.params.reservationKey,
      invoiceStatusChangeCounter: 0,
      messageStatus: 0,
      reservationProfile: {
        ReservationId: 0,
        ProfileId: 0,
        ProfileKey: '',
        ProfileName: '',
        ProfileImage: '',
      },
      reservationProfiles: [],
      isLoading: true,
      viewPageComponents: {
        detail: 'Details',
        comments: 'Comments',
        contacts: 'Contacts',
        program: 'Program',
        invoiceDetail: 'InvoiceDetail',
        invoiceDeposit: 'InvoiceDeposit',
        mail: "Mail",
        externalLinks: "ExternalLinks"
      },
      modalType: '',
      prevRoute: null
    }
  },

  computed: {
    ...mapState(['profile']),
    ...mapState('reservationStore', ['reservation', 'locationManager', 'isOutdatedBrowser']),
    ...mapState('menuStore', ['visiblePageComponent']),
    ...mapGetters({
      isLoggedIn: 'getIsLoggedIn'
    }),

    pageTitle() {
      let temp = ''
      if (this.reservation) {
        temp = 'Reservation: ' + this.reservation.Id
        temp += `; ${this.reservation.LocationName}`
      }
      return temp
    },

    messageResource() {
      if (this.messageStatus === 403) {
        return 'Views.Reservations.ReservationDetail.Message_Status403'
      }
      return ''
    },

    pageSubTitle() {
      let temp = ''
      if (this.reservation) {
        if (this.reservation.MeetingtypeId < 3) {
          temp += `${this.$options.filters.dateFormat(
            this.reservation.StartDate,
            this.$i18n.locale,
            'longDateFormat'
          )}`
          temp += `, ${this.$options.filters.minutesToTime(
            this.reservation.StartMinutes
          )} - ${this.$options.filters.minutesToTime(
            this.reservation.EndMinutes
          )}`
        } else {
          temp += `${this.$options.filters.dateFormat(
            this.reservation.StartDate,
            this.$i18n.locale,
            'longDateFormat'
          )} - ${this.$options.filters.dateFormat(
            this.reservation.EndDate,
            this.$i18n.locale,
            'longDateFormat'
          )}`
        }
      }
      return temp
    },

    pageMenu() {
      let menuItems = [
        {
          Name: this.$t('Views.Reservations.ReservationDetail.Submenu_Reservation'),
          Link: this.viewPageComponents.detail,
        },
        {
          Name: this.$t('Views.Reservations.ReservationDetail.Submenu_Comments'),
          Link: this.viewPageComponents.comments,
          BadgeValue: this.reservation.Comments
            ? this.reservation.Comments.length
            : 0,
        },
        {
          Name: this.$t('Views.Reservations.ReservationDetail.Submenu_Contacts'),
          Link: this.viewPageComponents.contacts
        },
      ]

      if (this.reservation && this.reservation.HasInvoice) {
        menuItems.push({
          Name: this.$t('Views.Reservations.ReservationDetail.Submenu_Invoice'),
          Link: this.viewPageComponents.invoiceDetail,
        })
      }

      if (this.reservation && this.reservation.HasDepositInvoice) {
        menuItems.push({
          Name: this.$t('Views.Reservations.ReservationDetail.Submenu_DepositInvoice'),
          Link: this.viewPageComponents.invoiceDeposit,
        })
      }

      if (this.reservation && this.reservation.MeetingtypeId === 1) {
        menuItems.push({
          Name: this.$t('Views.Reservations.ReservationDetail.Submenu_Program'),
          Link: this.viewPageComponents.program,
        })
      }

      menuItems.push({
        Name: this.$t('Views.Reservations.ReservationDetail.Submenu_ExternalLinks'),
        Link: this.viewPageComponents.externalLinks,
      })

      return menuItems
    },
  },

  beforeRouteEnter(to, from, next) {
    let newReservation = false

    if (from.name === 'proposal-detail') {
      newReservation = true
    }
    next((vm) => {
      vm.newReservation = newReservation
    })
  },

  /**
   * Load new reservation when reservation route changed
   */
  beforeRouteUpdate(to, from, next) {
    if (!to && !to.params.reservationKey) { window.location.reload() }
    this.prevRoute = from

    this.reservationKey = to.params.reservationKey
    this.setReservation(null)
    this.setVisiblePageComponent(this.viewPageComponents.detail)

    this.getReservationByKey()

    next()
  },

  created() {
    this.setVisiblePageComponent(this.viewPageComponents.detail)
    this.getReservationByKey()
  },

  mounted() {
    this.loadFreshdeskWidget()

    if (this.newReservation) {
      this.startConfetti()
    }
  },

  beforeDestroy() {
    this.$confetti.stop()

    if (document.getElementById('freshworks-container')) {
      document.getElementById('freshworks-container').style.display = 'none'
    }

    this.setReservation(null)
    this.setLocationLanguages(null)
    this.setLocationOpeningHours(null)
    this.setLocationManager(null)
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation', 'setLocationLanguages', 'setLocationOpeningHours', 'setLocationManager']),
    ...mapMutations('menuStore', ['setSelectedMenuItemIndex', 'setVisiblePageComponent']),

    loadFreshdeskWidget() {
      if (document.getElementById('freshworks-container')) {
        document.getElementById('freshworks-container').style.display = ''
        return
      }
      window.fwSettings = {
        'widget_id': 11000000300
      }
      let fdWidgetScript = document.createElement('script')
      fdWidgetScript.setAttribute('src', 'https://widget.freshworks.com/widgets/11000000300.js')
      fdWidgetScript.async = true
      fdWidgetScript.onerror = (oError) => {
        throw new URIError('The script ' + oError.target.src + 'didn\'t load correctly.')
      }
      fdWidgetScript.onload = () => {
      }
      document.head.appendChild(fdWidgetScript)
    },

    startConfetti() {
      this.$confetti.start({ dropRate: 300 })

      let t = setTimeout(() => {
        EventBus.$emit('showToast', {
          type: 'info',
          message: 'Reservation created!',
        })
        this.$confetti.stop()
        clearTimeout(t)
      }, 2000)
    },

    gotoPreviousPage() {
      if (!this.prevRoute) {
        this.$router.push({ name: 'Reservations' })
        return
      }

      this.$router.push({ name: this.prevRoute.name, params: this.prevRoute.params }).catch((err) => { })
    },

    getReservationByKey() {
      let self = this

      if (!this.reservation) {
        this.isLoading = true
      }

      reservationProvider.methods
        .getReservationByKey(this.reservationKey)
        .then((response) => {
          self.messageStatus = response.status

          if (response.status === 200) {
            // Store reservation data
            this.setReservation(response.data)

            // Add profile announcement
            self.reservationProfile.ReservationId = Number(response.data.Id)
            self.reservationProfile.ProfileId = Number(self.profile.Id)
            self.reservationProfile.ProfileKey = self.profile.Key
            self.reservationProfile.ProfileName = self.profile.FullName
            self.reservationProfile.ProfileImage = self.profile.Image

            // Get location opening hours
            this.getLocationOpeningHours({
              locationId: response.data.LocationId,
              channelId: response.data.ChannelId,
              date: response.data.StartDate,
              meetingtypeId: response.data.MeetingtypeId
            })

            // Get location languages
            this.getLocationLanguages(response.data.LocationId)

            // Get location active manager
            this.getLocationActiveManager()
          }
        })
        .catch(err => {
          self.messageStatus = err.response.status
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    setShowBookingTermModal() {
      this.modalType = 'modalTerms';
    },

    hideModal() {
      this.modalType = ''
    },

    async getLocationOpeningHours({ locationId, channelId, date, meetingtypeId }) {
      try {
        const response = await openinghoursProvider.methods.getOpeningHours({
          date,
          channelId,
          locationId,
          meetingtypeId
        })
        if (response.status === 200) {
          this.setLocationOpeningHours(response.data)
        }
      }
      catch (err) {
        return null
      }
    },

    /**
     * Get location active manager
     */
    async getLocationActiveManager() {
      try {
        const response = await locationProvider.methods.getActiveManager(this.reservation.LocationId)
        if (response.status !== 200) { return }
        this.setLocationManager(response.data)

      } catch (error) { }
    },

    getLocationLanguages(locationId) {
      locationProvider.methods
        .getLocationLanguageIds(locationId)
        .then(response => {
          this.setLocationLanguages(response.data)
        })
    },

    goToPreviousReservationInSet() {
      let setIndex = this.reservation.ReservationSetIds.findIndex(
        (r) => r.Id === this.reservation.Key
      )
      let previousReservation = this.reservation.ReservationSetIds[setIndex - 1]
      this.goToReservation(previousReservation.Key)
    },

    goToNextReservationInSet() {
      let setIndex = this.reservation.ReservationSetIds.findIndex(
        (r) => r.Id === this.reservation.Key
      )
      let nextReservation = this.reservation.ReservationSetIds[setIndex + 1]
      this.goToReservation(nextReservation.Key)
    },

    goToReservation(reservationKey) {
      this.$router
        .push({
          name: 'reservation-detail',
          params: { reservationKey: reservationKey },
        })
        .catch((err) => { })
    },

    setShowInvoice() {
      this.setSelectedMenuItemIndex({
        menuGroupIndex: 2,
        selectedMenuItemIndex: 3,
      })
      this.setVisiblePageComponent(this.viewPageComponents.invoiceDetail)
    }
  }
}
</script>

<style lang="scss" scoped>
.column-pageMenu {
  min-width: 240px;
  span.email {
    overflow-wrap: break-word;
  }
}
.placeholderTermsLine {
  margin-top: 3px;
}
</style>