<template>
  <ui-component-modal
    :showModal="showModal"
    :modalTitle="$t('Components.Reservation.ModalEditTags.Title_EditTags')"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveReservation"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div>
        <Message class="is-info">
          {{messageTagsExplanation}}</Message>
        <tag-input
          :tags="tags"
          @tagAdded="addTag"
          @tagDeleted="deleteTag"
        />
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { EventBus } from '@/eventBus/event-bus'
import Message from '@/components/UI/Message'
import reservationProvider from '../../providers/reservation'

import TagInput from '@/components/UI/Form/TagInput'

export default {
  components: {
    Message,
    'tag-input': TagInput,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      changeAllInSet: false,
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      tags: [],
      mTags: [],
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),

    messageTagsExplanation() {
      if (this.reservation.MeetingtypeId === 2) {
        return this.$t('Components.Reservation.Tags.Tag_MessageNoTagsAdded_MeetingType_2')
      }
      return this.$t('Components.Reservation.Tags.Tag_MessageNoTagsAdded_MeetingType_1')
    },
  },

  created() {
    this.tags =
      this.reservation.Tags.trim().length > 0
        ? this.reservation.Tags.trim().split(',')
        : []

    this.mTags = JSON.parse(JSON.stringify(this.tags))
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    addTag(tag) {
      this.mTags.push(tag)
    },

    deleteTag(tag) {
      let tagIndex = this.mTags.findIndex((t) => t === tag)
      if (tagIndex > -1) {
        this.mTags.splice(tagIndex, 1)
      }
    },

    saveReservation() {
      let self = this
      if (this.isSaving) { return }
      this.isSaving = true

      let reservation = JSON.parse(JSON.stringify(self.reservation))

      reservationProvider.methods.saveReservationTags(this.reservation.Id, this.mTags.join(','))
        .then((response) => {
          if (response.status === 200) {
            // Update reservation store
            self.setReservation(response.data)

            // Send message to be shown as toast on page
            EventBus.$emit('showToast', {
              type: 'info',
              message: this.$t("General.Message_ReservationSaved")
            })

            // Close modal
            this.onClickCancel()
          }
        })
        .catch(() => {
          self.isSavingError = true
        })
        .finally(() => {
          self.isSaving = false
        })
    },
  },
}
</script>
