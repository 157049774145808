<template>
  <ui-component-modal
    :modalTitle="$t('Components.Reservation.ModalDeleteOption.Header_ModalHeader')"
    confirmButtonCssClass="is-danger"
    :onClickCancel="onClickCancel"
    :showModal="showModal"
    :buttonLabelConfirm="$t('Form.Control.Delete')"
    :onClickSave="deleteReservationOption"
    :isSaving="isDeleting"
    :isSavingSuccess="isDeletingSuccess"
    :isSavingError="isDeletingError"
    :hideFooterButtons="isDeletingError || isDeletingSuccess"
    :disableSaveButton="!isDeleteConfirmed"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div
        class="has-margin-bottom is-size-5 has-text-centered"
        v-html="
          $t('Components.Reservation.ModalDeleteOption.Text_Delete', {
            name: option.Name,
          })
        "
      ></div>

      <div class="has-text-centered">
        <BaseCheckbox
          v-model="isDeleteConfirmed"
          class="has-text-left"
          :label="$t('Components.Reservation.ModalDeleteOption.Label_YesSure')"
        />
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventBus/event-bus'
import reservationProvider from '../../providers/reservation'

const BaseCheckbox = () =>
  import(
    /* webpackChunkName: "settings-options" */ '@/components/UI/Form/BaseCheckbox'
  )

export default {
  components: {
    BaseCheckbox,
  },

  props: {
    option: null,
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      changeAllInSet: false,
      isDeleteConfirmed: false,
      isDeleting: false,
      isDeletingSuccess: false,
      isDeletingError: false,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    deleteReservationOption() {
      let self = this

      let optionIndex = self.reservation.Options.findIndex(
        (o) => o.Id === self.option.Id
      )
      if (optionIndex > -1) {
        self.reservation.Options.splice(optionIndex, 1)

        if (!self.isDeleting) {
          self.isDeleting = true

          let criteria = {
            Id: self.option.Id,
            ChangeAllInSet: false,
          }

          reservationProvider.methods
            .deleteReservationOption(self.reservation.Id, criteria)
            .then((response) => {
              if (response.status === 200) {
                // Update reservation store
                self.setReservation(response.data)

                self.isDeletingSuccess = true

                // Send message to display toast on screen
                EventBus.$emit('showToast', {
                  type: 'info',
                  message: this.$t("General.Message_ReservationSaved"),
                })

                // Close modal
                this.onClickCancel()
              }
            })
            .catch((error) => {
              self.isDeletingError = true
            })
            .finally(() => {
              self.isDeleting = false
            })
        }
      } else {
        self.isSavingError = true
        self.isSaving = false
      }
    },
  },
}
</script>
