<template>
  <ui-component-modal
    :modalTitle="$t('Components.Reservation.ModalDeleteSpace.Header_ModalHeader')"
    confirmButtonCssClass="is-danger"
    :showModal="showModal"
    :buttonLabelConfirm="$t('Form.Control.Delete')"
    :isSaving="isDeleting"
    :isSavingSuccess="isDeletingSuccess"
    :isSavingError="isDeletingError"
    :hideFooterButtons="isDeletingError || isDeletingSuccess"
    :disableSaveButton="!isDeleteConfirmed"
    :onClickSave="deleteReservationSpace"
    :onClickCancel="onClickCancel"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div class="has-margin-bottom is-size-5 has-text-centered">
        {{ $t('Components.Reservation.ModalDeleteSpace.Message_AreYouSure', { name: space.SpaceName }) }}
      </div>

      <div class="has-text-centered">
        <BaseCheckbox
          v-model="isDeleteConfirmed"
          class="has-text-left"
          :label="$t('Components.Reservation.ModalDeleteSpace.Label_YesSure')"
        />
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventBus/event-bus'
import reservationProvider from '../../providers/reservation'

const BaseCheckbox = () =>
  import(
    /* webpackChunkName: "settings-options" */ '@/components/UI/Form/BaseCheckbox'
  )

export default {
  components: {
    BaseCheckbox,
  },

  props: {
    space: null,

    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isDeleteConfirmed: false,
      isDeleting: false,
      isDeletingSuccess: false,
      isDeletingError: false,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    deleteReservationSpace() {
      let self = this

      this.reservation.Spaces.splice(this.space.Index, 1)

      if (!this.isDeleting) {
        this.isDeleting = true

        let criteria = {
          Id: this.space.Id,
        }

        reservationProvider.methods
          .deleteReservationSpace(this.reservation.Id, criteria)
          .then((response) => {
            if (response.status === 200) {
              // Update reservation store
              self.setReservation(response.data)

              // Show success saved message
              EventBus.$emit('showToast', {
                type: 'info',
                message: this.$t("General.Message_ReservationSaved"),
              })

              // Close model
              self.onClickCancel()
            }
          })
          .catch((error) => {
            self.isDeletingError = true
            self.responseMessage = error.response.data.Value
          })
          .finally(() => {
            self.isDeleting = false
          })
      }

      // let self = this
      // let reservation = JSON.parse(JSON.stringify(self.reservation))
      // reservation.Spaces.splice(self.space.Index, 1)

      // let total = 0
      // for (let i = 0; i < reservation.Spaces.length; i++) {
      //   total = total + Number(reservation.Spaces[i].Seats)
      // }

      // reservation.TotalSeats = total
      // for (let i = 0; i < reservation.Options.length; i++) {
      //   if (reservation.Options[i].IsPP) {
      //     reservation.Options[i].Amount = reservation.TotalSeats
      //   }
      // }

      // if (!self.isDeleting) {
      //   self.isDeleting = true

      //   reservationProvider.methods
      //     .saveReservation(reservation, false)
      //     .then((response) => {
      //       if (response.status === 200) {
      //         self.setReservation(response.data)

      //         self.isDeletingSuccess = true

      //         setTimeout(() => {
      //           this.onClickCancel()
      //         }, 1500)

      //         EventBus.$emit('showToast', {
      //           type: 'info',
      //           message: this.$t("General.Message_ReservationSaved"),
      //         })
      //       }
      //     })
      //     .catch(() => {
      //       self.isDeletingError = true
      //     })
      //     .finally(() => {
      //       self.isDeleting = false
      //     })
      // }
    },
  },
}
</script>
