<template>
  <ui-component-modal
    modalTitle="Delete program item"
    confirmButtonCssClass="is-danger"
    :buttonLabelConfirm="$t('Form.Control.Delete')"
    :isSaving="isDeleting"
    :isSavingSuccess="isDeletingSuccess"
    :isSavingError="isDeletingError"
    :savingSuccessMessage="$t('Components.Reservation.Program.Message_SavingSuccess', {
      name: programItem.Description,
    })"
    :hideFooterButtons="isDeletingError || isDeletingSuccess"
    :disableSaveButton="!isDeleteConfirmed"
    :onClickCancel="onClickCancel"
    :onClickSave="save"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div>
        <div class="has-margin-bottom is-size-5 has-text-centered">
          {{$t('Components.Reservation.Program.Text_DeleteProgramItem')}}
        </div>
        <div class="has-text-centered">
          <BaseCheckbox
            v-model="isDeleteConfirmed"
            class="has-text-left"
            :label="$t('Components.Reservation.Program.Text_YesSure')"
          />
        </div>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapState } from 'vuex'
import { EventBus } from '@/eventBus/event-bus'

import BaseCheckbox from '@/components/UI/Form/BaseCheckbox'

export default {
  components: {
    BaseCheckbox,
  },

  props: {
    programItem: null,

    isEmployee: {
      default: false,
      type: Boolean,
    },

    isLocked: {
      default: false,
      type: Boolean,
    },

    index: {
      type: Number,
      required: true,
    },

    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isDeleteConfirmed: false,
      isDeleting: false,
      isDeletingSuccess: false,
      isDeletingError: false,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),
  },

  created() { },

  methods: {

    async save() {
      await EventBus.$emit('deleteProgramItem', this.index)
      this.isDeletingSuccess = true

      let t = setTimeout(() => {
        this.onClickCancel()
        clearTimeout(t)
      }, 1500)
    }
  },
}
</script>
