<template>
  <div v-if="reservation">
    <ModalCancelReservation
      v-if="showModalCancelReservation"
      :showModal="showModalCancelReservation"
      :onClickCancel="hideModal"
    />

    <component
      key="ModalCopyReservation"
      v-if="modalCopyReservation && showModalCopyReservation"
      :is="modalCopyReservation"
      v-bind="modalCopyReservationProps"
    >
    </component>

    <component
      key="ModalChangeDate"
      v-if="modalChangeDate && showModalChangeDate"
      :is="modalChangeDate"
      v-bind="modalChangeDateProps"
    >
    </component>

    <ModalReservationSet
      v-if="showReservationSet"
      :showModal="showReservationSet"
      :onClickCancel="hideModal"
    />

    <div class="is-hidden-print actionBar has-background-white-ter has-padding">
      <div class="has-text-right">
        <LinkDownload
          type="reservation"
          :id="reservation.Id"
          class="has-margin-left"
        />
        <LinkPrint
          type="reservation"
          v-if="!isOutdatedBrowser"
          :id="reservation.Id"
          class=" has-margin-left"
        />

        <a
          @click="setShowCancelReservation"
          class="has-icon has-text-link has-margin-left"
          :class="{ 'has-text-grey is-disabled': isSaving || isLocked }"
        >
          <span class="icon">
            <font-awesome-icon :icon="['fas', 'ban']" />
          </span>
          <span>{{$t('Components.Reservation.ReservationDetail.HeaderText_CancelReservation')}}</span>
        </a>

        <a
          @click="setShowChangeDate"
          :disabled="isSaving"
          class="has-icon has-text-link has-margin-left"
          :class="[{ 'has-text-grey is-disabled': isSaving || isLocked || isLoadingModal}]"
        ><span
            class="icon"
            :class="{'is-loading': isLoadingModal === 'modalChangeDate'}"
          >
            <font-awesome-icon :icon="['fas', 'calendar-alt']" />
          </span>
          <span>{{$t('Components.Reservation.ReservationDetail.HeaderText_ChangeDate')}}</span></a>

        <a
          v-if="reservation.MeetingtypeId === 1"
          @click="setShowCopyReservation"
          :disabled="isSaving || isLocked"
          class="has-icon has-text-link has-margin-left"
          :class="{ 'has-text-grey is-disabled': isSaving || isLocked || isLoadingModal}"
        ><span
            class="icon"
            :class="{'is-loading': isLoadingModal === 'modalCopyReservation'}"
          >
            <font-awesome-icon :icon="['fa', 'copy']" />
          </span>
          <span>{{$t('Components.Reservation.ReservationDetail.HeaderText_CopyReservation')}}</span></a>
      </div>
    </div>

    <section class="section is-small">
      <a
        class="tag"
        :class="isPartOfSet ? 'is-link' : 'is-disabled'"
        @click="setShowReservationSet"
      >
        <span class="icon">
          <font-awesome-icon :icon="['fas', 'clone']" />
        </span>
        <span v-text="isPartOfSet ? 
              $t('Components.Reservation.ReservationDetail.Icon_PartOfSet')
              :
              $t('Components.Reservation.ReservationDetail.Icon_NotPartOfSet')"></span>
      </a>

      <a
        @click="setShowInvoiceSummary(reservation.HasInvoice)"
        class="tag has-margin-left"
        :class="reservation.HasInvoice ? 'is-link' : 'is-disabled'"
      >
        <span class="icon">
          <font-awesome-icon :icon="['fas', 'file-invoice']" />
        </span>
        <span v-text="reservation.HasInvoice ? 
              $t('Components.Reservation.ReservationDetail.Icon_InvoiceCreated')
              :
              $t('Components.Reservation.ReservationDetail.Icon_NoInvoice')"></span>
      </a>
    </section>

    <section class="section is-small">
      <div class="columns has-margin-top">
        <div class="column">
          <ReservationBasicData
            :isLocked="isLocked"
            :isEmployee="isEmployee"
          />
        </div>
        <div class="column">
          <ReservationInvoiceAddress
            :isLocked="isLocked"
            :isEmployee="isEmployee"
          />
        </div>
      </div>
    </section>

    <section
      v-if="reservation.Voucher && reservation.Voucher.Type === 'Package'"
      class="section is-small"
    >
      <ReservationPackage />
    </section>

    <section class="section is-small">
      <ReservationMeetingspaces
        v-if="reservation.MeetingtypeId === 1 || reservation.MeetingtypeId === 2"
        :isLocked="isLocked"
      />

      <ReservationDeskspaces
        v-if="reservation.MeetingtypeId === 3"
        :isLocked="isLocked"
      />
    </section>

    <section class="section is-small">
      <ReservationOptions
        :isEmployee="isEmployee"
        :isLocked="isLocked"
      />
    </section>

    <section class="section is-small">
      <ReservationTotals />
    </section>

    <section class="section is-small">
      <ReservationTags
        :isEmployee="isEmployee"
        :isLocked="isLocked"
      />
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { EventBus } from '@/eventBus/event-bus'
import reservationProvider from '@/providers/reservation'

import ModalCancelReservation from '@/components/Reservation/ModalCancelReservation'
// import ModalChangeDate from '@/components/Reservation/ModalChangeDate'
import ReservationDeskspaces from '@/components/Reservation/ReservationDeskspaces'
import ReservationMeetingspaces from '@/components/Reservation/ReservationMeetingspaces'
import ReservationOptions from '@/components/Reservation/ReservationOptions'
import ReservationPackage from '@/components/Reservation/ReservationPackage'
import ReservationTotals from '@/components/Reservation/ReservationTotals'
import ReservationBasicData from '@/components/Reservation/ReservationBasicData'
import ReservationInvoiceAddress from '@/components/Reservation/ReservationInvoiceAddress'
import ReservationTags from '@/components/Reservation/ReservationTags'
// import ModalCopyReservation from './ModalCopyReservation.vue'
import LinkPrint from '../UI/LinkPrint'
import LinkDownload from '../UI/LinkDownload'
import ModalReservationSet from './ModalReservationSet.vue'

export default {
  components: {
    ModalCancelReservation,
    // ModalChangeDate,
    ReservationBasicData,
    ReservationInvoiceAddress,
    ReservationDeskspaces,
    ReservationMeetingspaces,
    ReservationOptions,
    ReservationPackage,
    ReservationTotals,
    ReservationTags,
    // ModalCopyReservation,
    LinkPrint,
    LinkDownload,
    ModalReservationSet,
  },

  data() {
    return {
      drawerTitle: '',
      isEmployee: false,
      isSaving: false,
      itemId: 0,
      modalTitle: '',
      selectedOption: null,
      selectedSpace: null,
      showModalCancelReservation: false,
      showModalChangeDate: false,
      showModalCopyReservation: false,
      showReservationSet: false,
      modalCopyReservation: null,
      modalCopyReservationProps: {},
      modalChangeDate: null,
      modalChangeDateProps: {},
      isLoadingModal: ''
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation', 'isOutdatedBrowser']),
    ...mapGetters('reservationStore', ['isLocked']),

    isPartOfSet() {
      return this.reservation.ReservationSetIds.length
    },

    resStart() {
      let minStartMinutes = 0
      if (this.reservation && this.reservation.Spaces.length > 0) {
        var lowest = Number.POSITIVE_INFINITY
        var tmp
        for (var i = this.reservation.Spaces.length - 1; i >= 0; i--) {
          tmp = this.reservation.Spaces[i].StartMinutes
          if (tmp < lowest) {
            lowest = tmp
          }
        }
        minStartMinutes = lowest
      }
      return minStartMinutes
    },

    resEnd() {
      let maxEndMinutes = 0
      if (this.reservation && this.reservation.Spaces.length > 0) {
        var highest = Number.NEGATIVE_INFINITY
        var tmp
        for (var i = this.reservation.Spaces.length - 1; i >= 0; i--) {
          tmp = this.reservation.Spaces[i].EndMinutes
          if (tmp > highest) {
            highest = tmp
          }
        }
        maxEndMinutes = highest
      }
      return maxEndMinutes
    },

    nrOfHours() {
      let hours = 0
      let self = this

      let minutes = self.resEnd - self.resStart
      hours = self.roundToZero(minutes / 60)

      return hours
    },

    nrOfSeats() {
      let seats = 0
      let self = this

      if (self.reservation && self.reservation !== null) {
        seats = Number(this.reservation.TotalSeats)
      }

      return seats
    },
  },

  created() {
    EventBus.$on('addToSet', (set) => {
      this.reservation.ReservationSetIds.push(set)
      this.updateReservationStore(this.reservation)
    })
  },

  mounted() {
  },

  beforeDestroy() {
    EventBus.$off('addToSet')
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    addSpace(space) {
      this.reservation.Spaces.push({
        Id: 0,
        ReservationId: this.reservation.Id,
        SpaceId: space.SpaceId,
        SpaceName: space.SpaceName,
        SpaceImage: space.SpaceImage,
        SettingId: space.SettingId,
        Seats: space.Seats,
        CurrencyId: space.CurrencyId,
        PricePerSeat: space.PricePerSeat,
        PriceTotal: space.PriceTotal,
        TaxId: space.TaxId,
        TaxPercentage: space.TaxPercentage,
        Crc: space.Crc,
        StartDate: space.StartDate,
        StartMinutes: space.StartMinutes,
        EndDate: space.EndDate,
        EndMinutes: space.EndMinutes,
      })

      let t = setTimeout(() => {
        this.saveReservation()
        this.hideModal()
        clearTimeout(t)
      }, 0)
    },

    saveReservation() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        reservationProvider.methods
          .saveReservation(self.reservation, false)
          .then((response) => {
            if (response.status === 200) {
              // Update store
              self.updateReservationStore(response.data)

              EventBus.$emit('showToast', {
                type: 'info',
                message: this.$t("General.Message_ReservationSaved"),
              })
            }
          })
          .catch((error) => {
            if (error.response.status === 400) {
              EventBus.$emit('showToast', {
                type: 'warning',
                message: error.response.data.Value,
              })
            }
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },

    cancelReservation(reservation) {
      let self = this
      self.reservation = reservation
      // Update store
      self.updateReservationStore(self.reservation)
    },

    setShowAddDeskspace() {
      this.modalTitle = 'Add deskspace'
      this.setVisibleSideBarItem('addDeskspace')
    },

    setShowCancelReservation() {
      if (!this.isLocked) {
        this.showModalCancelReservation = true
      }
    },

    setShowInvoiceSummary() {
      if (!this.reservation.HasInvoice) { return }
      this.$emit('actionShowInvoice', true);
    },

    async setShowChangeDate() {
      if (this.isLocked || this.isLoadingModal) { return }
      this.isLoadingModal = 'modalChangeDate'

      this.modalChangeDateProps = {
        showModal: true,
        onClickCancel: this.hideModal
      }

      if (!this.modalChangeDate) {
        const response = await import('./ModalChangeDate.vue')
        this.modalChangeDate = response.default
      }
      this.showModalChangeDate = true
      this.isLoadingModal = ''
    },

    async setShowCopyReservation() {
      if (this.isLocked || this.isLoadingModal) { return }
      this.isLoadingModal = 'modalCopyReservation'

      this.modalCopyReservationProps = {
        showModal: true,
        onClickCancel: this.hideModal
      }
      if (!this.modalCopyReservation) {
        const response = await import('./ModalCopyReservation.vue')
        this.modalCopyReservation = response.default
      }
      this.showModalCopyReservation = true
      this.isLoadingModal = ''
    },

    setShowReservationSet() {
      if (!this.isPartOfSet) { return }
      this.showReservationSet = true
    },

    hideModal() {
      this.showModalCancelReservation = false
      this.showReservationSet = false

      this.showModalCopyReservation = false
      this.modalCopyReservationProps = {}

      this.showModalChangeDate = false
      this.modalChangeDateProps = {}

      this.itemId = 0
      this.drawerTitle = ''
    },

    roundToZero(num) {
      let self = this
      return +(Math.round(self.roundToTwo(num) + 'e+0') + 'e-0')
    },

    roundToTwo(num) {
      return +(Math.round(num + 'e+2') + 'e-2')
    },

    /**
     * Update reservation state and original reservation
     */
    updateReservationStore(data) {
      this.setReservation(data)
    },
  },
}
</script>
