<template>
  <a
    class="has-icon has-text-link is-unselectable"
    @click="download()"
  >
    <span
      class="icon"
      :class="{'is-loading': isLoading }"
    >
      <font-awesome-icon :icon="['fas', 'file-download']" />
    </span>
    <span v-text="$t('Form.Control.Download')"></span>
  </a>
</template>

<script>
import reservationProvider from '../../providers/reservation'
import invoiceProvider from '../../providers/invoice'

export default {
  props: {
    type: {
      type: String,
      required: true
    },

    id: {
      type: Number,
      required: true
    },

    openInModal: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isLoading: false
    }
  },

  created() {
  },

  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    async download() {
      if (this.isLoading) { return }
      this.isLoading = true
      let response = null

      switch (this.type) {
        case 'reservation':
          response = await reservationProvider.methods.getReservationPdf(this.id).catch()
          break;
        case 'invoice':
          response = await invoiceProvider.methods.getInvoicePdf(this.id).catch()
          break;

        default:
          break;
      }

      const linkSource = `data:application/pdf;base64,${response.data}`
      const pdfBlob = this.dataURItoBlob(linkSource)
      const blobUrl = window.URL.createObjectURL(pdfBlob)
      this.isLoading = false

      /**
       * Internet Explorer stuff!
       */
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(pdfBlob, `${Date.now()}.pdf`)
        return
      }

      const link = document.createElement('a')
      link.href = blobUrl
      link.setAttribute('download', `${Date.now()}.pdf`)
      document.body.appendChild(link)
      link.click()
      window.URL.revokeObjectURL(blobUrl)

      link.remove()
      return response

    },

    dataURItoBlob(dataURI) {
      const byteString = atob(dataURI.split(',')[1])
      const mimeString = dataURI
        .split(',')[0]
        .split(':')[1]
        .split(';')[0]

      // write the bytes of the string to an ArrayBuffer
      const ab = new ArrayBuffer(byteString.length)
      const ia = new Uint8Array(ab)
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }

      // write the ArrayBuffer to a blob, and you're done
      const bb = new Blob([ab], { type: mimeString })
      return bb
    },
  },
}
</script>

<style lang="scss" scoped>
</style>