<template>
  <ui-component-modal
    :modalTitle="$t(`Components.Reservation.ModalAddExternalLink.Main_Title`)"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveEditedLink"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>

      <table class="table is-fullwidth is-striped">
        <tbody>
          <tr>
            <td v-text="$t(`Components.Reservation.ModalAddExternalLink.Label_AddNewLinkType`)">
            <td>
              <div class="select"></div>
              <select v-model="mExternalLink.LinkType">
                <option
                  :value="'VideoCall'"
                  :selected="mExternalLink.LinkType === 'VideoCall'"
                  v-text="$t(`Components.Reservation.ModalAddExternalLink.SelectOption_VideoCall`)"
                ></option>
                <option
                  :value="'Document'"
                  :selected="mExternalLink.LinkType === 'Document'"
                  v-text="$t(`Components.Reservation.ModalAddExternalLink.SelectOption_Document`)"
                ></option>
              </select>
            </td>
          </tr>
          <tr>
            <td v-text="$t(`Components.Reservation.ModalAddExternalLink.Label_AddNewLinkUrl`)"></td>
            <td>
              <input
                type="text"
                v-model="mExternalLink.Link"
                class="input"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>

</template>

<script>
import reservationProvider from '@/providers/reservation'

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },

    externalLink: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      mExternalLink: null,
    }
  },

  computed: {},

  created() {
    if (this.externalLink) {
      this.mExternalLink = JSON.parse(JSON.stringify(this.externalLink))
    }

  },


  methods: {

    saveEditedLink() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        reservationProvider.methods
          .editExternalLink(self.mExternalLink)
          .then((response) => {
            if (response.status === 200) {
              // Update reservation store
              self.isSavingSuccess = true

              setTimeout(() => {
                this.onClickCancel()
              }, 1500)

              this.$emit('externalLinkEdited', response.data)
            }
          })
          .catch(() => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }

    },
  },
}
</script>