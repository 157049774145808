var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper-carousel"},[_c('transition',{attrs:{"name":"fade","mode":"out-in","duration":100,"appear":""}},[_c('figure',{key:'im' + _vm.imageIndex,staticClass:"image is-3by2 has-background-grey-lighter"},[_c('img',{style:({
            backgroundImage: `url('${_vm.$options.filters.getLocationImage(
              _vm.mImages[_vm.imageIndex].Image,
              (_vm.mImages[_vm.imageIndex].LocationId ? _vm.mImages[_vm.imageIndex].LocationId : _vm.locationId),
              '640'
            )}')`,
          }),attrs:{"src":require("@/assets/img/spacer1_1.gif")}})])]),(_vm.mImages.length > 1)?[_c('a',{staticClass:"stepNav prev",on:{"click":function($event){return _vm.stepNav('<')}}},[_c('span',{class:`${_vm.$classPrefix}icon is-large`},[_c('font-awesome-icon',{attrs:{"icon":['far', 'angle-left'],"size":"2x"}})],1)]),_c('a',{staticClass:"stepNav next",on:{"click":function($event){return _vm.stepNav('>')}}},[_c('span',{class:`${_vm.$classPrefix}icon is-large`},[_c('font-awesome-icon',{attrs:{"icon":['far', 'angle-right'],"size":"2x"}})],1)])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }