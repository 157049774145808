<template>
  <div v-if="reservation">
    <ModalDeleteCancelRule
      v-if="showDeleteCancelRule"
      :showModal="showDeleteCancelRule"
      :onClickCancel="hideModal"
    />

    <table class="table is-fullwidth is-striped is-narrow">
      <tbody>
        <tr>
          <td>
            {{
              $t('Components.Reservation.ReservationTotals.Label_TotalExclTax')
            }}
          </td>
          <td class="has-text-right">
            {{
              reservation.TotalExclTax
                | toCurrency('nl-NL', reservation.CurrencyIso)
            }}
          </td>
          <td
            width="85"
            class="is-hidden-print"
          >&nbsp;</td>
        </tr>
        <template v-for="total in reservation.TaxTotals">
          <tr
            :key="total.Percentage"
            v-if="total.Total > 0"
          >
            <td>
              {{
                $t('Components.Reservation.ReservationTotals.Label_TotalVat')
              }}
              {{ total.Percentage }}%
            </td>
            <td class="has-text-right">
              {{ total.Total | toCurrency('nl-NL', reservation.CurrencyIso) }}
            </td>
            <td
              width="85"
              class="is-hidden-print"
            >&nbsp;</td>
          </tr>
        </template>
      </tbody>
      <tfoot>
        <tr>
          <td>
            {{
              $t('Components.Reservation.ReservationTotals.Label_TotalInclTax')
            }}
          </td>
          <td
            width="100"
            class="has-text-right"
          >
            {{
              reservation.TotalInclTax
                | toCurrency('nl-NL', reservation.CurrencyIso)
            }}
          </td>
          <td
            width="85"
            class="is-hidden-print"
          >&nbsp;</td>
        </tr>

        <tr v-if="reservation.CancelRule">
          <td class="has-text-weight-bold">
            {{
              $t(
                'Components.Reservation.ReservationTotals.Label_CancelRuleApplies'
              )
            }}
            {{ reservation.CancelRule.Percentage }}%
            {{ $t('Components.Reservation.ReservationTotals.Label_Of') }}
            {{
              reservation.TotalInclTax
                | toCurrency('nl-NL', reservation.CurrencyIso)
            }}
          </td>
          <td
            width="100"
            class="has-text-left"
          >&nbsp;</td>
          <td
            width="85"
            class="is-hidden-print"
          >
          </td>
        </tr>
        <tr v-if="reservation.Cancel && reservation.Cancel.Percentage > 0">
          <td class="has-text-weight-bold">
            {{
              $t('Components.Reservation.ReservationTotals.Label_AmountToPay')
            }}
          </td>
          <td
            width="100"
            class="has-text-right"
          >
            {{
              reservation.AmountToPay
                | toCurrency('nl-NL', reservation.CurrencyIso)
            }}
          </td>
          <td width="85">&nbsp;</td>
        </tr>
        <tr v-if="reservation.Cancel && reservation.Cancel.Percentage > 0">
          <td colspan="3">
            <Message
              :message="
                $t(
                  'Components.Reservation.ReservationTotals.Label_CancelRuleApplies',
                  {
                    percentage: reservation.Cancel.Percentage,
                  }
                )
              "
              :type="'is-danger'"
            />
          </td>
        </tr>

        <tr v-if="reservation.TotalPaid > 0">
          <td>
            {{ $t('Components.Reservation.ReservationTotals.Label_TotalPaid') }}
          </td>
          <td
            width="100"
            class="has-text-right"
          >
            {{
              reservation.TotalPaid
                | toCurrency('nl-NL', reservation.CurrencyIso)
            }}
          </td>
          <td
            width="85"
            class="is-hidden-print"
          >&nbsp;</td>
        </tr>

        <tr v-if="reservation.TotalPaid > 0">
          <td class="has-text-weight-bold">
            {{
              $t('Components.Reservation.ReservationTotals.Label_AmountOpen')
            }}
          </td>
          <td class="has-text-right has-text-weight-bold">
            {{
              reservation.TotalOpen
                | toCurrency('nl-NL', reservation.CurrencyIso)
            }}
          </td>
          <td
            width="85"
            class="is-hidden-print"
          >&nbsp;</td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import Message from '../../components/UI/Message.vue'

export default {
  components: {
    Message,
  },

  props: {},

  data() {
    return {
      showDeleteCancelRule: false,
      taxValues: [],
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),
  },

  methods: {
    roundToZero(num) {
      return +(Math.round(this.roundToTwo(num) + 'e+0') + 'e-0')
    },

    roundToTwo(num) {
      return +(Math.round(num + 'e+2') + 'e-2')
    },

    setShowDeleteCancelRule() {
      this.showDeleteCancelRule = true
    },

    hideModal() {
      this.showDeleteCancelRule = false
    },
  },
}
</script>
