<template>
  <div class="box box-menu is-paddingless ">
    <aside
      v-if="!showMobileMenu"
      class="menu-page menu"
    >
      <ul class="menu-list">
        <li
          v-for="(item, index) in menuItems"
          :key="'m_' + index"
        >
          <a
            @click="updateSelectedMenuIndex(index)"
            :class="{ 'is-active' : index === selectedMenuItems[menuGroupIndex] }"
          >
            <span
              v-bind="item.BadgeValue && item.BadgeValue > 0 ? {'data-badge': item.BadgeValue} : {}"
              class="level-left has-badge-rounded has-badge-large has-badge-inline menu-list"
            >{{ item.Name }}</span>
          </a>
        </li>
      </ul>
    </aside>
    <div
      v-else
      class="wrapper-select has-padding"
    >
      <div class="select">
        <select @change="updateSelectedMenuIndex">
          <option
            v-for="(item, index) in menuItems"
            :key="'dm_' + index"
            :value="index"
            :selected="index === selectedMenuItems[menuGroupIndex]"
          >{{item.Name}}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { EventBus } from '../../eventBus/event-bus'

export default {
  name: 'page-menu',

  props: {
    clickActionFunc: {
      type: Function,
      default: function() { return false }
    },
    menuItems: {
      type: Array,
      default: function() { return [] }
    }
  },

  data() {
    return {
      menuGroupIndex: 2,
      selectedMenuItemIndex: 0,
      showMobileMenu: window.innerWidth < 1024,
    }
  },

  computed: {
    ...mapState('menuStore', ['selectedMenuItems']),
  },

  created() {
    EventBus.$on('windowResizeListener', this.setShowMobileMenu)
  },

  beforeDestroy() {
    EventBus.$off('windowResizeListener', this.setShowMobileMenu)
    this.updateSelectedMenuIndex(0)

  },

  methods: {
    ...mapMutations('menuStore', ['setSelectedMenuItemIndex', 'setVisiblePageComponent']),

    updateSelectedMenuIndex(index) {
      // Update selected page menu item
      this.setSelectedMenuItemIndex({
        menuGroupIndex: this.menuGroupIndex,
        selectedMenuItemIndex: Number(index)
      })
      this.setVisiblePageComponent(this.menuItems[index].Link)
      // this.menuItems[index].Action(this.menuItems[index], index)
    },


    setShowMobileMenu(val) {
      this.showMobileMenu = val.width < 1024
    }
  }
}
</script>