import axios from 'axios'
import store from '../store/store'

let getOptionToken = null

export default {
  methods: {
    async getOptionById(optionId, locationId) {
      if (getOptionToken) {
        getOptionToken.cancel()
      }
      getOptionToken = axios.CancelToken.source()

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/option/${optionId}`,
        {
          cancelToken: getOptionToken.token,

          params: {
            locationid: locationId,
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken,
          },
        }
      )
      return response
    },

    async getReservationOptions(
      reservationId,
      categoryId = 0,
      searchTerm = ''
    ) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/reservation/${reservationId}/available/options`,
        {
          params: {
            q: searchTerm,
            cat: categoryId,
          },
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken,
          },
        }
      )
      return response
    },
  }
}