<template>
  <div>
    <ui-section-header>
      <template v-slot:title>{{
        $t('Components.Reservation.Comments.Main_Title')
      }}</template>
    </ui-section-header>

    <ModalAddComment
      v-if="showModalCreateComment"
      :showModal="showModalCreateComment"
      :onClickCancel="hideModal"
    />

    <div class="has-margin-bottom-x2">
      <Message class="is-info">{{$t('Components.Reservation.Comments.Info_text')}}</Message>
    </div>

    <div
      v-if="!isLocked"
      class="actionBar has-background-white-ter has-padding has-margin-bottom has-text-right"
    >
      <a
        class="has-icon"
        @click="setShowModalCreateComment"
      >
        <span class="icon is-small">
          <font-awesome-icon :icon="['fas', 'plus']" />
        </span>
        <span>{{$t('Components.Reservation.Comments.Button_CreateComment')}}</span>
      </a>
    </div>

    <reservation-comment-item
      v-for="comment in filterComments(reservation.Comments, 0)"
      :key="'cp' + comment.Id"
      :commentObj="comment"
      :comments="reservation.Comments"
      :isLocked="isLocked"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'

import Message from '@/components/UI/Message'
import ModalAddComment from '@/components/Reservation/ModalAddComment'
import { EventBus } from '../../../eventBus/event-bus'
import ReservationCommentItem from '../ReservationCommentItem.vue'

export default {
  props: {
    comments: {
      type: Array,
      default: function() {
        return []
      },
    },
  },

  components: {
    Message,
    ModalAddComment,
    ReservationCommentItem,
  },

  data() {
    return {
      mComments: this.comments,
      showModalCreateComment: false,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),
    ...mapGetters('reservationStore', ['isLocked'])
  },

  created() {
    EventBus.$on('deletedComment', (comment) => {
      let commentIndex = this.reservation.Comments.findIndex(
        (c) => c.Id === comment.Id
      )
      if (commentIndex > -1) {
        this.reservation.Comments.splice(commentIndex, 1)
      }
    })
  },

  beforeDestroy() {
    EventBus.$off('deletedComment')
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    filterComments(comments, parentId) {
      return comments.filter((c) => c.ParentId === parentId)
    },

    updateReservationStore() {
      this.setReservation(this.reservation)
    },

    setShowModalCreateComment() {
      this.showModalCreateComment = true
    },

    hideModal() {
      this.showModalCreateComment = false
    },


  },
}
</script>
