import axios from "axios";
import store from "../store/store";

let getLocationSpacesToken = null;

export default {
  methods: {
    async getLocationSpaces(
      locationId,
      searchTerm,
      meetingtypeId = 0,
      seats = 0,
      page = 1,
      itemsPerPage = 50
    ) {
      if (getLocationSpacesToken) {
        getLocationSpacesToken.cancel();
      }
      getLocationSpacesToken = axios.CancelToken.source();

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/space/location/${locationId}`,
        {
          cancelToken: getLocationSpacesToken.token,

          params: {
            q: searchTerm,
            meetingtypeId: meetingtypeId,
            seats: seats,
            page: page,
            itemsPerPage: itemsPerPage
          },

          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      );
      return response;
    },

    async getActiveSpaceConfigurations(locationId = 0) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/space/configurations/active`,
        {
          params: {
            locationId: locationId
          },

          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      );
      return response;
    },

    async getSpaceById(spaceId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/space/${spaceId}`,
        {
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY
          }
        }
      );
      return response;
    }
  }
}