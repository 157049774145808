<template>
  <div>
    <ui-section-header>
      <template v-slot:title>{{
        $t('Components.Reservation.MailLog.Main_Title')
      }}</template>
    </ui-section-header>

    <MailLog :mailLogs="mailLogs" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mailProvider from '@/providers/mail'

const MailLog = () => import('@/components/Mails/MailLog')

export default {
  components: {
    MailLog,
  },

  data() {
    return {
      isLoading: false,
      mailLogs: [],
      showSendMail: false,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),
  },

  created() {
    this.getReservationMailLog()
  },

  methods: {
    getReservationMailLog() {
      let self = this

      if (!self.isLoading) {
        self.isLoading = true

        mailProvider.methods
          .getReservationMailLog(self.reservation.Id)
          .then((response) => {
            if (response.status === 200) {
              self.mailLogs = response.data
            }
          })
          .finally(() => {
            self.isLoading = false
          })
      }
    },

    setShowSendMail() {
      this.showSendMail = true
    },

    hideModal() {
      this.showSendMail = false
    },
  },
}
</script>
