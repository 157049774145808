<template>
  <UiComponentDrawer
    v-if="showDrawer"
    :showDrawer="showDrawer"
    :closeFunc="onClickClose"
    position="right"
  >
    <template v-slot:content>
      <transition
        name="fade"
        mode="out-in"
      >
        <ui-loader
          v-if="isLoading"
          key="loading"
        />
        <div
          key="ready"
          v-if="!isLoading && space"
        >
          <div class="wrapper-images">
            <LocationImageCarousel
              v-if="space.Images"
              :images="space.Images"
              :locationId="space.LocationId"
            />
          </div>

          <div
            class="title is-3"
            v-text="spaceName"
          ></div>
          <UpsellTemplate
            v-if="upsellItems.length"
            :upsellItems="upsellItems"
            class="has-margin-bottom-x2"
          />

          <div
            class="has-margin-bottom-x2"
            v-html="description"
          ></div>

          <div
            v-if="meetingtypeId === 1"
            class="columns wrapper-settings is-mobile is-multiline"
          >
            <div class="column is-full">
              <div
                class="is-size-5 has-text-weight-bold"
                v-text="$t('Components.Reservation.Meetingspaces.Title_Configuration')"
              ></div>
            </div>
            <div
              class="column is-one-third has-text-centered"
              v-for="(setting, index) in space.Settings"
              :key="index"
            >
              <SvgSetting
                aria-hidden="true"
                :settingId="setting.SettingId"
                size="is-large"
              />
              <div class="has-text-weight-bold">{{ settingName(setting) }}</div>
              {{ minMaxSeats(setting) }} {{ $t('Components.Reservation.Meetingspaces.Label_Persons') }}
            </div>
          </div>
        </div>
      </transition>
    </template>
  </UiComponentDrawer>
</template>

<script>
import LocationImageCarousel from '@/components/UI/LocationImageCarousel'
import UiComponentDrawer from '@/components/UI/Drawer'
import spaceProvider from '../../providers/space'
import SvgSetting from '@/components/UI/Settings/SvgSetting'
import UpsellTemplate from '../UI/UpsellTemplate'

export default {
  name: 'drawer-location-space-detail',

  props: {
    // drawer props
    drawerTitle: {
      type: String,
      default: '',
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    onClickClose: {
      type: Function,
      default: function() { },
      required: false,
    },
    position: {
      type: String,
      default: 'right',
    },
    showDrawer: {
      type: Boolean,
      default: false,
    },

    // props for content
    spaceId: {
      type: Number,
      required: true,
    },

    meetingtypeId: {
      type: Number,
      default: 0
    }
  },

  components: {
    LocationImageCarousel,
    UiComponentDrawer,
    SvgSetting,
    UpsellTemplate
  },

  data() {
    return {
      isLoading: true,
      currentLanguageId: this.$i18n.locale === 'nl' ? 52 : 65,
      space: null,
    }
  },

  computed: {
    spaceName() {
      return this.space && this.space.Descriptions
        ? this.space.Descriptions.find(
          (d) => d.LanguageId === this.currentLanguageId
        ).Name
        : ''
    },

    description() {
      return this.space && this.space.Descriptions
        ? this.space.Descriptions.find(
          (d) => d.LanguageId === this.currentLanguageId
        ).Description
        : ''
    },

    upsellItems() {
      if (!this.space) { return [] }

      let output = []
      this.space.Features.forEach(f => {
        if (f.LanguageId === this.currentLanguageId) {
          output.push(f.Body)
        }
      })

      return output
    },
  },

  mounted() {
    document.body.appendChild(this.$el)
    this.getSpaceData()
  },

  beforeDestroy() {
    let t = setTimeout(() => {
      if (this.$el.parentNode) {
        this.$el.parentNode.removeChild(this.$el)
      }
      clearTimeout(t)
    }, 100)
  },

  methods: {
    getSpaceData() {
      let self = this
      spaceProvider.methods.getSpaceById(this.spaceId).then((response) => {
        self.space = response.data
      })
        .finally(() => {
          this.isLoading = false
        })
    },

    /**
     * The regex splits CamelCase strings into separate words
     */
    settingName(setting) {
      return setting.SettingName.replace(/([a-zA-Z])(?=[A-Z])/g, '$1 ')
    },

    /**
     * Format the seating information based on the information that is available
     */
    minMaxSeats(setting) {
      return `${setting.MinSeats} - ${setting.MaxSeats}`
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper-images {
  margin-top: -$gap;
  margin-right: -$gap;
  margin-bottom: $gap;
  margin-left: -$gap;
}
</style>
