<template>
  <transition-expand>
    <article class="media media-comments">
      <div class="media-left">
        <figure class="image is-32x32">
          <img
            src="@/assets/img/spacer1_1.gif"
            class="is-rounded has-shadow has-background-grey-light"
            :style="{
            backgroundImage: `url(${$options.filters.getProfileImage(
              mComment.ProfileImage,
              mComment.ProfileId,
              38
            )})`,
          }"
          />
        </figure>
      </div>
      <div class="media-content">
        <div class="content">
          <div class="has-text-grey postDetailBar">
            {{ mComment.CreatedOn | dateTimeStampToDate | longDateFormat($i18n.locale) }} -
            {{ mComment.CreatedOn | timeFromDateTimeStamp }}
          </div>
          <div class="enable-line-break">
            <strong>{{ mComment.ProfileName }}, </strong>
            <span :class="{ 'is-deleted': mComment.IsDeleted }">{{
            mComment.Text
          }}</span>
          </div>
          <transition-expand>
            <div
              v-if="showCommentInput"
              class="has-margin-top"
            >
              <div>
                <textarea
                  class="textarea has-margin-bottom"
                  v-model="commentText"
                />
                <div class="buttons is-right">
                  <a
                    @click="cancelShowCommentInput"
                    class="button is-ghost"
                  >{{ $t('Components.Comments.CommentItem.Button_Cancel') }}</a>
                  <a
                    @click="sendComment(mComment)"
                    class="button is-primary"
                  >{{ $t('Components.Comments.CommentItem.Button_Send') }}</a>
                </div>
              </div>
            </div>
          </transition-expand>

          <div
            v-if="!isLocked"
            class="has-text-right"
          >
            <a
              @click="deleteComment(mComment)"
              class="has-icon has-text-danger"
              v-if="!mComment.IsDeleted && mComment.CreatedBy === profile.Id"
            >
              <span class="icon">
                <font-awesome-icon :icon="['fas', 'trash-alt']" />
              </span>
              <span>{{ $t('Components.Comments.CommentItem.Button_Delete') }}</span>
            </a>

            <a
              v-if="!hideReplyLink"
              @click="setShowCommentInput()"
              class="has-icon"
            >
              <span class="icon">
                <font-awesome-icon :icon="['fas', 'reply']" />
              </span>
              <span>{{ $t('Components.Comments.CommentItem.Button_Reply') }}</span>
            </a>
          </div>
          <ReservationCommentItem
            v-for="comment in filterComments(mComment.Id)"
            :key="comment.Id"
            :commentObj="comment"
            :comments="mComments"
            :isLocked="isLocked"
          />
        </div>
      </div>
    </article>
  </transition-expand>
</template>

<script>
import { mapGetters } from 'vuex'
import commentProvider from '../../providers/comment'
import { EventBus } from '../../eventBus/event-bus'
import TransitionExpand from '../UI/TransitionExpand.vue'

export default {
  name: 'ReservationCommentItem',
  props: {
    isLocked: {
      type: Boolean,
      default: false
    },
    commentObj: {
      type: Object,
      default: function() { return null }
    },
    comments: {
      type: Array,
      default: function() {
        return []
      },
    },
    depthIndex: {
      type: Number,
      default: 0
    },
    maxDepth: {
      type: Number,
      default: 0
    },
    showReplyLink: {
      type: Boolean,
      default: false
    }
  },

  components: {
    TransitionExpand,
  },

  data() {
    return {
      commentText: '',
      mComment: this.commentObj,
      mComments: this.comments,
      showCommentInput: false,
    }
  },

  computed: {
    ...mapGetters({
      isLoggedIn: 'getIsLoggedIn',
      profile: 'getProfile',
    }),

    hideReplyLink() {
      return !this.isLoggedIn
        || !this.showReplyLink
        || this.showCommentInput
        || this.mComment.CreatedBy === this.profile.Id
    }
  },

  methods: {
    filterComments(parentId) {
      const arr = this.mComments.filter((c) => c.ParentId === parentId)
      return arr
    },

    setShowCommentInput() {
      this.showCommentInput = true
    },

    cancelShowCommentInput() {
      this.showCommentInput = false
    },

    sendComment(value) {
      commentProvider.methods
        .replyToComment(value.Id, this.commentText)
        .then((response) => {
          this.mComments.push(response.data)
          this.commentText = ''
          this.showCommentInput = false
        })
    },

    deleteComment(value) {
      commentProvider.methods
        .deleteComment(value.Id).then(() => {
          value.IsDeleted = true
          EventBus.$emit('deletedComment', value)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.is-deleted {
  text-decoration: line-through;
}

.enable-line-break {
  white-space: pre-wrap;
}
</style>
