<template>
  <ui-component-modal
    v-if="showModal"
    :showModal="showModal"
    :onClickCancel="onClickCancel"
    :hideFooterButtons="true"
    :modalTitle="modalTitle"
    modalSize="large"
  >
    <template v-slot:content>
      <div
        class="content"
        v-html="description"
      ></div>
    </template>
    <template v-slot:footer>
      <div v-html="'Version: ' + version"></div>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      modalTitle: '',
      description: '',
      version: ''
    }
  },
  computed: {
    ...mapState('reservationStore', ['reservation']),
  },
  created() {
    this.initializeTerms()
  },
  methods: {
    initializeTerms() {
      if (!this.reservation.ReservationTerms) { return }
      this.modalTitle += ` (${this.reservation.ReservationTerms.BookingTerm.Version})`
      this.description = this.reservation.ReservationTerms.BookingTerm.Text
      this.version = this.reservation.ReservationTerms.BookingTerm.Version
      if (this.reservation.ReservationTerms.BookingTerm.CancelRules && this.reservation.ReservationTerms.BookingTerm.CancelRules.length) {
        this.description += this.buildCancellationRulesText(this.reservation.ReservationTerms.BookingTerm.CancelRules)
      }
    },

    buildCancellationRulesText(rules) {
      let output = `<hr/><div class="title is-3">${this.$t('Views.Reservations.ReservationDetail.BookingTerms.Header_Cancellation')}</div>`
      let table = `<table class="table is-fullwidth is-bordered">
        <thead>
          <tr>
            <th class="has-text-centered">${this.$t('Views.Reservations.ReservationDetail.BookingTerms.TableHeader_DaysTillBooking')}</th>
            <th class="has-text-centered">${this.$t('Views.Reservations.ReservationDetail.BookingTerms.TableHeader_GroupSize')}</th>
            <th class="has-text-centered">${this.$t('Views.Reservations.ReservationDetail.BookingTerms.TableHeader_Percentage')}</th>
          </tr>
        </thead>
        <tbody>`
      rules = rules.sort((a, b) => {
        if (a.HoursUntilStart > b.HoursUntilStart && a.MaxSeats > b.MaxSeats) return 1
        if (a.HoursUntilStart < b.HoursUntilStart && a.MaxSeats < b.MaxSeats) return -1
      })
      rules.forEach(rule => {
        table += `<tr>
          <td class="has-text-centered">${this.hoursToDay(rule.HoursUntilStart, true)}</td>
          <td class="has-text-centered">${rule.MinSeats} - ${rule.MaxSeats}</td>
          <td class="has-text-centered">${rule.Percentage}%</td>
        </tr>`
      });
      table += '</tbody></table>'
      output += table
      return output
    },

    hoursToDay(hours, onlyDayFormat = false) {
      if (hours <= 48 && !onlyDayFormat) {
        // return in hours
        return this.$tc('Views.Reservations.ReservationDetail.BookingTerms.Label_Hour', hours)
      }
      return this.$tc('Views.Reservations.ReservationDetail.BookingTerms.Label_Days', Math.floor(hours / 24))
    }
  }
}
</script>

<style lang="scss" scoped>
</style>