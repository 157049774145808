<template>
  <ui-component-modal
    :modalTitle="$t('Components.Reservation.ModalReservationSet.Main_Title')"
    :onClickCancel="onClickCancel"
    :showModal="showModal"
    @closeModal="onClickCancel"
    :hideFooterButtons="true"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <table class="table is-fullwidth is-striped is-hoverable">
        <tbody>
          <tr
            v-for="set in reservation.ReservationSetIds"
            :key="set.Id"
            :class="{ 'is-selected': set.Id === reservation.Id }"
          >
            <td>#{{ set.Id }}</td>
            <td>{{ set.Date | dateFormat($i18n.locale) }}</td>
            <!-- <td class="has-text-right">
              <a
                class="has-icon"
              >
                <span class="icon is-small has-text-danger">
                  <font-awesome-icon :icon="['fas', 'times']" />
                </span>
                <span v-text="$t('Components.Reservation.ModalReservationSet.Link_RemoveFromSet')"></span>
              </a>
            </td> -->
            <td class="has-text-right">
              <router-link
                v-if="set.Id != reservation.Id"
                :to="{ name: 'reservation-detail', params: { reservationKey: set.Key }}"
                class="has-icon has-margin-left"
                :class="{
                  'is-loading': isLoading && selectedReservationId === set.Id,
                }"
              >
                <span class="icon is-small">
                  <font-awesome-icon :icon="['fas', 'share']" />
                </span>
                <span v-text="$t('Components.Reservation.ModalReservationSet.Link_Open')"></span>
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      selectedReservationId: 0,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),
  },

  created() { },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    setItemClicked(reservationId) {
      this.setReservation(null)
      this.goToReservation(reservationId)
    },

    goToReservation(reservationId) {
      this.$router
        .push({
          name: `reservation-detail`,
          params: { reservationId: reservationId },
        })
        .catch((err) => { })

      this.onClickCancel()
    },
  },
}
</script>
