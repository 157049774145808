<template>
  <div
    class="card-space card p-0"
    :class="[viewType, {'is-disabled': isBusy && !selectedSettingId}]"
  >
    <figure
      class="image has-background-grey-light"
      :style="{
        backgroundImage: `url('${spaceImage}')`,
      }"
    ></figure>
    <div class="settings_price">
      <div class="p-4">
        <div
          class="spaceName is-size-4 has-text-weight-bold"
          v-html="meetingspace.SpaceName"
        ></div>
        <div class="mb-4">
          {{ meetingspace.StartDate | dateFormat(this.$i18n.locale, 'longDateFormat') }}
        </div>
        <div
          v-if="spaceAvailable"
          class="price"
        >
          <strong class="has-text-weight-bold">{{$t('Components.Reservation.ModalChangeDate.Text_PricePerSeat')}} {{ seatPrice }}<br />
            {{$t('Components.Reservation.ModalChangeDate.Text_PriceTotal')}} {{ totalPrice }}</strong>
        </div>
        <template v-if="!spaceAvailable">
          <Message
            v-for="(Warning, index) in meetingspace.Warnings"
            :key="index"
            class="is-warning has-margin-bottom"
          >{{$t(
                `Components.Availability.AvailableMeetingspace.Warning_CannotBeBooked`
              )}} {{$t(
                  `Components.Availability.AvailableMeetingspace.Warning_${Warning}`
                )}}</Message>
        </template>
      </div>

      <div class="has-background-info-light p-4">
        <div
          v-if="spaceAvailable"
          class="has-margin-bottom has-text-weight-bold"
          v-text="$t('Components.Availability.AvailableMeetingspace.Header_ChooseSetting')"
        >
        </div>

        <div class="wrapper-settings settings columns is-mobile is-multiline">
          <div
            v-for="setting in meetingspace.Settings"
            :class="['tile-setting', tileCssClass(setting)]"
            :key="'sId_' + setting.Id"
          >
            <div
              class="setting is-inline-flex has-text-centered"
              :class="[tileCssClass(setting)]"
              @click="selectSetting(setting)"
            >
              <SvgSetting
                aria-hidden="true"
                :settingId="setting.Id"
                size="is-large"
              />
              <div class="is-size-7">{{ setting.Id | getSettingName }}</div>
              <div class="is-size-7">({{ setting.MinSeats }} - {{ setting.MaxSeats }})</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/eventBus/event-bus'
import SvgSetting from '@/components/UI/Settings/SvgSetting'
import Message from '@/components/UI/Message'
import { mapGetters } from 'vuex'

export default {
  name: 'AvailableMeetingspace',
  components: {
    SvgSetting,
    Message
  },

  props: {
    languageCulture: {
      type: String,
      default: 'nl-NL',
    },

    meetingtypeId: {
      type: Number,
      default: 0,
    },

    meetingspace: null,

    spaceSelected: {
      default: false,
      type: Boolean,
    },

    viewType: {
      type: String,
      default: 'list-row'
    },
  },

  data() {
    return {
      selectedSettingId: 0,
    }
  },

  computed: {
    ...mapGetters('reservationStore', ['isBusy']),

    spaceImage() {
      return this.$options.filters.getLocationImage(
        this.meetingspace.SpaceImage, this.meetingspace.LocationId, '640')
    },

    noCapacity() {
      // Space is already occupied
      return this.meetingspace.Warnings.indexOf('NoCapacity') !== -1
    },

    spaceAvailable() {
      return this.meetingspace.Settings.find(s => s.SpaceAvailable)
    },

    seatPrice() {
      let setting = this.meetingspace.Settings[0]
      return this.$options.filters.toCurrency(setting.Price, this.languageCulture, setting.CurrencyIso)
    },

    totalPrice() {
      let setting = this.meetingspace.Settings[0]
      return this.$options.filters.toCurrency((this.meetingspace.Seats * setting.Price), this.languageCulture, setting.CurrencyIso)
    },
  },

  created() {
  },

  methods: {
    tileCssClass(setting) {
      return {
        'is-disabled': !setting.SpaceAvailable || this.noCapacity || (this.isBusy && this.selectedSettingId !== setting.Id),
        'is-loading': this.isBusy && this.selectedSettingId === setting.Id
      }
    },

    selectSetting(setting) {
      if (this.isBusy || !setting.SpaceAvailable || this.selectedSettingId === setting.Id) { return }

      this.selectedSettingId = setting.Id

      let space = {
        Id: 0,
        LocationId: this.meetingspace.LocationId,
        SpaceId: this.meetingspace.SpaceId,
        SpaceName: this.meetingspace.SpaceName,
        SpaceImage: this.meetingspace.SpaceImage,
        SettingId: setting.Id,
        Seats: this.meetingspace.Seats,
        StartDate: this.meetingspace.StartDate,
        StartMinutes: this.meetingspace.StartMinutes,
        EndDate: this.meetingspace.EndDate,
        EndMinutes: this.meetingspace.EndMinutes,
        CurrencyId: setting.CurrencyId,
        CurrencyIso: setting.CurrencyIso,
        CurrencySymbol: setting.CurrencySymbol,
        PricePerSeat: setting.Price,
        Crc: setting.Crc,
        PriceTotal: this.roundToTwo(setting.Price * this.meetingspace.Seats),
        TaxId: setting.TaxId,
        TaxPercentage: setting.TaxPercentage,
        Hash: setting.Hash,
      }

      EventBus.$emit('spaceSelected', space)
      this.$emit('spaceSelected')
    },

    roundToTwo(num) {
      return +(Math.round(num + 'e+2') + 'e-2')
    },
  },
}
</script>

<style lang="scss" scoped>
.card-space {
  display: flex;
  &.is-disabled {
    opacity: 0.5;
  }
  .image {
    width: 100%;
    min-height: 200px;
    max-width: 300px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex-grow: 0;
  }

  &.list-card {
    flex-direction: column;
    width: 100%;
    .image {
      max-width: unset;
    }
  }

  .settings_price {
    flex-grow: 1;
    flex-shrink: 1;

    .wrapper-settings {
      overflow: hidden;
      .tile-setting {
        padding: 15px;
        .setting {
          font-size: $size-7;
          cursor: not-allowed;
          flex-direction: column;
          align-items: center;
          width: 100px;
          height: 90px;
          padding: 5px;
          border: 1px solid $grey;
          border-radius: $radius;
          color: $grey;
          background-color: $grey-light;
          &:not(.is-disabled) {
            cursor: pointer;
            border-color: $blue;
            color: $white;
            background-color: $blue;
            line-height: normal;
            &:hover {
              background-color: $white;
              color: $blue;
            }
          }
          &.is-loading {
            color: transparent !important;
            pointer-events: none;
            position: relative;
            &:after {
              animation: spinAround 500ms infinite linear;
              border: 2px solid $grey-lighter;
              border-radius: $radius-rounded;
              border-right-color: transparent;
              border-top-color: transparent;
              content: "";
              display: block;
              height: 2em;
              position: absolute;
              width: 2em;
              left: calc(50% - (#{2em} / 2));
              top: calc(50% - (#{2em} / 2));
            }
          }
        }
      }
    }
  }
}
</style>