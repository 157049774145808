<template>
  <article class="LocationOption">
    <figure
      class="LocationOption__image has-background-grey-light"
      :style="{
        backgroundImage: `url('${$options.filters.getLocationImage(
          option.OptionImage,
          option.LocationId,
          '640'
        )}')`,
      }"
    ></figure>
    <div class="LocationOption__detail_price">
      <div class="LocationOption__details has-padding">
        <div
          :class="`title-option is-size-5 has-text-weight-bold`"
          v-html="name"
        ></div>
        <UpsellTemplate
          class="has-margin-bottom"
          v-if="upsellItems.length"
          :upsellItems="upsellItems"
        />
      </div>
      <div class="LocationOption__price_control has-background-info-light has-padding has-text-centered">
        <div class="has-margin-bottom">
          <span v-text="$t('Components.Reservation.AvailableOptions.Text_PerItem') + ':'"></span>
          <span class="has-text-weight-bold">
            {{ option.PricePerItem | toCurrency('nl', option.CurrencyIso) }}
          </span>
          <span class="has-margin-left price-total">
            <span v-text="$t('Components.Reservation.AvailableOptions.Text_Total') + ':'"></span>
            <span class="has-text-weight-bold">
              {{ totalPrice | toCurrency('nl', option.CurrencyIso) }}
            </span>
          </span>
        </div>
        <AvailableOptionAmountControls
          class="LocationOption__pricing__control has-margin-bottom"
          :option="option"
        />
        <template v-if="isSelected">
          <div class="has-margin-bottom price-total-bottom">
            <span v-text="$t('Components.Reservation.AvailableOptions.Text_Total') + ':'"></span>
            <span class="has-text-weight-bold">
              {{ totalPrice | toCurrency('nl', option.CurrencyIso) }}
            </span>
          </div>
          <div>
            <a
              @click="handleDeleteOption"
              class="has-icon has-text-danger"
            >
              <span class="icon">
                <font-awesome-icon :icon="['fas', 'trash-alt']" />
              </span>
              <span v-html="$t('Form.Control.Delete')"></span>
            </a>
          </div>
        </template>
      </div>
    </div>
  </article>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import AvailableOptionAmountControls from './AvailableOptionAmountControls.vue'
import UpsellTemplate from '../../UI/UpsellTemplate'

export default {
  name: 'AvailableOption',

  components: {
    UpsellTemplate,
    AvailableOptionAmountControls,
  },

  props: {
    option: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showExtraInformation: false,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation', 'reservationRestoreOptions']),

    name() {
      return this.option.OptionName
    },
    description() {
      return this.option.OptionDescription
    },
    hasDescription() {
      return this.option.OptionDescription !== ''
    },

    totalPrice() {
      if (!this.isSelected && !this.isRequired) {
        return '0.00'
      }
      return this.calculateOptionTotalPrice()
    },

    isRequired() {
      return this.option.RequiredItem !== 0
    },

    isSelected() {
      return this.option.IsSelected
    },

    upsellItems() {
      let output = this.option.Features.map((el) => el.Body)
      return output
    },
  },

  methods: {
    ...mapMutations('reservationStore', ['setNewOptions']),

    calculateOptionTotalPrice() {
      let priceTotal = this.option.Amount * this.option.PricePerItem
      if (this.option.MaxTotal > 0 && priceTotal > this.option.MaxTotal) {
        priceTotal = this.option.MaxTotal
      }

      return priceTotal
    },

    handleDeleteOption() {
      let restoreOption = this.reservationRestoreOptions.find(o => o.OptionId === this.option.OptionId)
      this.option.IsSelected = false
      this.option.Amount = restoreOption.Amount
    }
  },
}
</script>

<style lang="scss" scoped>
.LocationOption {
  display: flex;
  padding: 0;
  box-shadow: $box-shadow;
  &__image {
    min-height: 160px;
    width: 100%;
    min-width: 160px;
    max-width: 220px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex-grow: 0;
  }

  &__detail_price {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    width: 100%;
  }

  &__details {
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 300px;

    .has-icon {
      flex-grow: 0;
      flex-shrink: 0;
    }
  }

  &__price_control {
    max-width: 150px;
    width: 100%;
    .price-total {
      display: none;
    }
  }

  @media screen and (max-width: 1100px) and (min-width: 900px),
    screen and (max-width: 419px) {
    &__detail_price {
      flex-direction: column;
      width: 100%;
    }

    &__details {
      width: 100%;
      min-width: 0;
    }

    &__image {
      max-width: 240px;
    }

    &__price_control {
      max-width: unset;
      text-align: center;
      .price-total {
        display: unset;
      }
      .price-total-bottom {
        display: none;
      }
    }
    &__pricing__control {
      display: inline-block;
      max-width: 150px;
    }
  }
  @media screen and (max-width: 699px) and (min-width: 420px) {
    flex-direction: column;
    &__image {
      max-width: unset;
      min-width: unset;
      padding-top: 66%;
    }
    &__details {
      min-width: unset;
    }
  }
  @media screen and (max-width: 419px) {
    flex-direction: column;
    &__image {
      max-width: unset;
      min-width: unset;
      padding-top: 66%;
    }
    &__details {
      min-width: unset;
    }
  }
}
</style>
