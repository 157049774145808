<template>
  <ui-component-modal
    :modalTitle="
      $t('Components.Reservation.ModalCancelReservation.Header_ModalHeader')
    "
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :buttonLabelConfirm="
      $t('Components.Reservation.ModalCancelReservation.Label_Yes')
    "
    :buttonLabelCancel="
      $t('Components.Reservation.ModalCancelReservation.Label_No')
    "
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="cancelReservation"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div>
        <div>
          <Message
            v-if="cancelRule"
            class="is-warning"
          >
            <strong v-text="$t('Components.Reservation.ModalCancelReservation.Text_AreYouSureWantToCancel')"></strong><br />
            <div v-text="$t('Components.Reservation.ModalCancelReservation.Label_TermsApply', {
              percentage: cancelRule.Percentage,
              price: $options.filters.toCurrency(reservation.TotalInclTax, 'nl-NL', reservation.CurrencyIso)
            })"></div>
            {{ cancelFeeAmount | toCurrency('nl-NL', reservation.CurrencyIso) }} {{ $t('Components.Reservation.ModalCancelReservation.Label_Charged') }}
          </Message>
          <table class="table is-fullwidth">
            <tbody>
              <tr>
                <td class="has-text-vertical-middle">
                  {{ $t('Components.Reservation.ModalCancelReservation.Label_CancelReason') }}
                </td>
                <td>
                  <div class="select">
                    <select v-model="mReasonId">
                      <option
                        v-for="predefinedReason in predefinedReasons"
                        :key="predefinedReason.Id"
                        :value="predefinedReason.Id"
                      >{{ predefinedReason.Name }}</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr v-if="mReasonId === 6">
                <td>
                  {{ $t('Components.Reservation.ModalCancelReservation.Label_OtherReason') }}
                </td>
                <td>
                  <textarea
                    class="textarea has-fixed-size"
                    v-model="mReason"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import reservationProvider from '@/providers/reservation'
import Message from '@/components/UI/Message'

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  components: {
    Message
  },

  data() {
    return {
      cancelruleId: 0,
      applyCancelFee: false,
      cancelFeePercentage: 0,
      cancelFeeAmount: 0,
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      mReasonId: 0,
      mReason: '',
      mReservation: null,
      predefinedReasons: [],
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),

    terms() {
      let terms = null

      if (
        this.reservation &&
        this.reservation.ReservationTerms &&
        this.reservation.ReservationTerms.BookingTerm
      ) {
        terms = this.reservation.ReservationTerms.BookingTerm
      }

      return terms
    },

    cancelRule() {
      let cancelRule = null
      let totalSeats = this.reservation.TotalSeats
      let hoursUntilStart = this.reservation.HoursUntilStart
      if (hoursUntilStart < 0) {
        hoursUntilStart = 0
      }

      if (
        this.terms && this.terms.CancelRules.length > 0
      ) {
        let cancelRules = this.terms.CancelRules.filter(cr => cr.MeetingtypeId == this.reservation.MeetingtypeId)

        if (cancelRules.filter(cr => cr.MinSeats > -1 || cr.MaxSeats > -1)) {
          cancelRules = cancelRules.filter(cr => totalSeats <= cr.MaxSeats)
        }

        cancelRules = cancelRules.filter(
          (cr) => cr.HoursUntilStart >= hoursUntilStart
        )

        let maxValue = Math.min.apply(
          Math,
          cancelRules.map(function(cr) {
            return cr.HoursUntilStart
          })
        )
        cancelRule = cancelRules.find(
          function(cr) {
            return cr.HoursUntilStart == maxValue
          }
        )
      }
      return cancelRule
    },
  },

  created() {
    this.mReservation = JSON.parse(JSON.stringify(this.reservation))

    this.predefinedReasons.push({
      Id: 0,
      Name: this.$i18n.t(
        'Components.Reservation.ModalCancelReservation.Reason_NoReasonSpecified'
      ),
    })
    this.predefinedReasons.push({
      Id: 1,
      Name: this.$i18n.t(
        'Components.Reservation.ModalCancelReservation.Reason_NoParticipants'
      ),
    })
    this.predefinedReasons.push({
      Id: 2,
      Name: this.$i18n.t(
        'Components.Reservation.ModalCancelReservation.Reason_FoundCheaperLocation'
      ),
    })
    this.predefinedReasons.push({
      Id: 3,
      Name: this.$i18n.t(
        'Components.Reservation.ModalCancelReservation.Reason_OtherRegion'
      ),
    })
    this.predefinedReasons.push({
      Id: 4,
      Name: this.$i18n.t(
        'Components.Reservation.ModalCancelReservation.Reason_MeetingIsCancelled'
      ),
    })
    this.predefinedReasons.push({
      Id: 5,
      Name: this.$i18n.t(
        'Components.Reservation.ModalCancelReservation.Reason_MeetingIsRescheduled'
      ),
    })
    this.predefinedReasons.push({
      Id: 6,
      Name: this.$i18n.t(
        'Components.Reservation.ModalCancelReservation.Reason_Other'
      ),
    })

    if (this.cancelRule) {
      this.applyCancelFee = true
      this.cancelruleId = this.cancelRule.Id
      this.cancelFeePercentage = this.cancelRule.Percentage

      this.cancelFeeAmount = this.reservation.TotalInclTax / 100 * this.cancelFeePercentage
      this.cancelFeeAmount = this.$options.filters.roundToTwo(this.cancelFeeAmount)
    }
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    cancelReservation() {
      let self = this
      // Do saving then close modal
      if (!self.isSaving) {
        self.isSaving = true

        reservationProvider.methods
          .cancelReservation(
            self.mReservation.Id,
            self.mReasonId,
            self.mReason,
            self.cancelruleId,
            self.applyCancelFee,
            self.cancelFeePercentage
          )
          .then((response) => {
            if (response.status === 200) {
              self.isSavingSuccess = true

              // Update reservation store
              self.setReservation(response.data)

              // Set timer to close popup in in 1,5 seconds
              setTimeout(() => {
                this.onClickCancel()
              }, 1500)
            }
          })
          .catch(() => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>
