<template>
  <div v-if="availableLocation">
    <p class="subtitle">{{ availableLocation.Name }}</p>

    <transition-group
      v-if="spaces"
      name="staggered-fade"
      v-bind:css="false"
      v-on:before-enter="beforeEnter"
      v-on:enter="enter"
      v-on:leave="leave"
      appear
      tag="div"
    >
      <div
        v-if="meetingtypeId === 1"
        :key="'ms'"
      >
        <AvailableMeetingspace
          v-for="(meetingspace, index) in sortedSpaces"
          :key="'ms' + index"
          :languageCulture="'nl-NL'"
          :meetingspace="meetingspace"
          :meetingtypeId="meetingtypeId"
          :spaceSelected="spaceSelected"
          :viewType="viewType"
          @spaceSelected="spaceSelected = true"
          :data-index="index"
          :class="{ 'has-margin-top': index > 0 }"
        />
      </div>

      <div
        v-if="meetingtypeId === 2"
        :key="'ws'"
      >
        <AvailableWorkspace
          v-for="(workspace, index) in sortedSpaces"
          :key="'ws' + workspace.Id"
          :languageCulture="'nl-NL'"
          :space="workspace"
          :meetingtypeId="meetingtypeId"
          @spaceSelected="spaceSelected = true"
          :class="{ 'has-margin-top': index > 0 }"
        />
      </div>

      <div
        v-if="meetingtypeId === 3"
        :key="'ds'"
      >
        <AvailableDeskspace
          v-for="(deskspace, index) in sortedSpaces"
          :key="'ds' + deskspace.Id"
          :deskspace="deskspace"
          :meetingtypeId="meetingtypeId"
          @spaceSelected="spaceSelected = true"
          :class="{ 'has-margin-top': index > 0 }"
        />
      </div>
    </transition-group>
    <Message
      v-if="spaces.length === 0"
      class="is-info"
    >
      <div
        v-html="$t('Components.Reservation.ModalChangeDate.Message_NoAvailableSpaces')"
        class="has-margin-bottom"
      ></div>
      <div v-if="locationManager && locationManager.Phone">
        <strong v-text="$t('Views.Reservations.ReservationDetail.Label_Phone')"></strong> {{locationManager.Phone}}
      </div>
      <div v-if="locationManager && locationManager.Label_Email">
        <strong v-text="$t('Views.Reservations.ReservationDetail.Label_Email')"></strong> {{locationManager.Email}}
      </div>
    </Message>
  </div>
</template>

<script>
import staggeredAnimation from '@/mixins/staggeredAnimation'

import AvailableDeskspace from './AvailableDeskspace'
import AvailableMeetingspace from './AvailableMeetingspace'
import AvailableWorkspace from './AvailableWorkspace'
import Message from '../../UI/Message.vue'
import { mapState } from 'vuex'

export default {
  name: 'AvailableLocation',

  components: {
    AvailableDeskspace,
    AvailableMeetingspace,
    AvailableWorkspace,
    Message,
  },

  props: {
    availableLocation: {
      default: null,
      type: Object,
    },
    meetingtypeId: {
      default: 0,
      type: Number,
    },
    meetingSpaces: {
      default: () => [],
      type: Array,
    },

    selectedSpaceId: {
      default: null,
      type: Number,
    },

    selectedSpace: {
      default: null,
      type: Object
    },

    viewType: {
      type: String,
      default: 'list-row'
    }
  },

  mixins: [staggeredAnimation],

  data() {
    return {
      spaceSelected: false,
    }
  },

  computed: {
    ...mapState('reservationStore', ['locationManager']),
    spaces() {
      let spaces = []

      if (this.availableLocation && this.availableLocation.Spaces) {
        let locationId = this.availableLocation.LocationId

        for (let j = 0; j < this.availableLocation.Spaces.length; j++) {
          let space = this.availableLocation.Spaces[j]

          let spaceAvailable = spaces.findIndex((s) => s.SpaceId === space.SpaceId) === -1
            && space.SpaceAvailable === true

          if (spaceAvailable) {
            spaces.push({
              Id: space.Id,
              SpaceId: space.SpaceId,
              LocationId: locationId,
              SpaceName:
                space.SpaceName.length === 0
                  ? space.InternalName
                  : space.SpaceName,
              SpaceImage: space.SpaceImage,
              Seats: space.Seats,
              StartDate: space.StartDate,
              StartMinutes: space.StartMinutes,
              EndDate: space.EndDate,
              EndMinutes: space.EndMinutes,
              Warnings: space.Warnings,
              Settings: this.getSpaceSettings(space.SpaceId),
            })
          }
        }
      }

      return spaces
    },

    sortedSpaces() {
      let spaces = this.spaces

      spaces.sort((a, b) => {
        if (a.SpaceId === this.selectedSpaceId) return -1
        return a.Warnings.length - b.Warnings.length
      })

      return spaces
    },
  },

  created() { },

  beforeDestroy() { },

  methods: {
    getSpaceSettings(spaceId) {
      let self = this
      let settings = []

      let meetingspaceSettings = self.availableLocation.Spaces.filter(
        (s) => s.SpaceId === spaceId
      )
      for (let i = 0; i < meetingspaceSettings.length; i++) {
        let setting = meetingspaceSettings[i]
        if (settings.findIndex((s) => s.Id === setting.SettingId) === -1) {
          settings.push({
            Id: setting.SettingId,
            CurrencyId: setting.CurrencyId,
            CurrencySymbol: setting.CurrencySymbol,
            CurrencyIso: setting.CurrencyIso,
            Price: setting.Price,
            Crc: setting.Crc,
            Hash: setting.Hash,
            TaxId: setting.TaxId,
            TaxPercentage: setting.TaxPercentage,
            MinSeats: setting.MinSeats,
            MaxSeats: setting.MaxSeats,
            SpaceAvailable: setting.SpaceAvailable,
          })
        }
      }

      return settings
    },
  },
}
</script>
