<template>
  <div class="SpaceOptionAmountControls">
    <button
      v-if="showChooseOptionButton"
      class="is-success is-fullwidth"
      :class="[`button`, { 'is-loading': isSavingOptionData }]"
      @click="handleChosenOption"
      v-text="$t('Form.Control.Choose')"
    ></button>

    <i18n
      v-if="isDisabled && !showChooseOptionButton"
      path="Components.Reservation.AvailableOptions.Text_OptionAddedPP"
      tag="span"
    >
      <strong place="amount">{{ option.Amount }}</strong>
      <template v-slot:persons>
        {{$tc('Components.Reservation.AvailableOptions.Text_Person', option.Amount)}}
      </template>
    </i18n>

    <div
      v-if="!showChooseOptionButton && !isDisabled"
      :class="`field has-addons`"
    >
      <div :class="`control`">
        <button
          :class="`button is-primary`"
          :disabled="isMinimalValue"
          @click="handleReduceAmount"
        >
          <span :class="`icon`">
            <font-awesome-icon :icon="['far', 'minus']" />
          </span>
        </button>
      </div>
      <div :class="`control`">
        <InputNumeric
          v-model.number="option.Amount"
          :isNumeric="true"
          :allowNegativeValue="false"
          @blur="checkAmountValue"
        />
      </div>
      <div :class="`control`">
        <button
          :class="`button is-primary`"
          :disabled="isMaxValue"
          @click="handleIncreaseAmount"
        >
          <span :class="`icon`">
            <font-awesome-icon :icon="['far', 'plus']" />
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isSavingOptionData: false,
    }
  },
  computed: {
    ...mapState('reservationStore', ['reservation']),

    showChooseOptionButton() {
      return !this.option.IsSelected
    },

    /**
     * Control Status
     */
    isDisabled() {
      // This kind of required item does not allow the user to change the amount
      return this.option.RequiredItem === 2
    },
    isRequired() {
      return this.option.RequiredItem !== 0
    },

    isMinimalValue() {
      return this.option.Amount === 1
    },
    isMaxValue() {
      return (
        (this.option.MaxAmount !== 0 &&
          this.option.Amount === this.option.MaxAmount) ||
        (this.option.MaxPP !== 0 &&
          this.option.Amount === this.option.MaxPP * this.Seats)
      )
    },
  },
  methods: {
    handleIncreaseAmount() {
      this.option.Amount = this.option.Amount + 1
      this.checkAmountValue()
    },
    handleReduceAmount() {
      this.option.Amount = this.option.Amount - 1
      this.checkAmountValue()
    },
    isMaxValueHandler() {
      return this.isMaxValue
    },

    handleChosenOption() {
      this.option.IsSelected = true
    },

    checkAmountValue() {
      let amount = Number(Number(this.option.Amount) <= 0 || this.option.Amount === '' ? 1 : this.option.Amount)

      // When option has max amount setting limit amout to max
      if (this.option.MaxAmount && amount > this.option.MaxAmount) {
        this.option.Amount = this.option.MaxAmount
        return
      }

      this.option.Amount = amount
    }
  }
}
</script>

<style lang="scss">
.SpaceOptionAmountControls {
  display: flex;
  justify-content: flex-end;

  .field {
    flex-grow: 1;
    flex-shrink: 1;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
  .input {
    -moz-appearance: textfield;
    min-width: 54px;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  > button {
    text-align: center;
  }
}
</style>
