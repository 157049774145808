<template>
  <div>
    <ModalEditTags
      v-if="showEditTags"
      :showModal="showEditTags"
      :onClickCancel="hideModal"
    />

    <ui-section-header
      :showEdit="!isLocked"
      :editFunction="setShowEditTags"
      :editLabel="$t('Components.Reservation.Tags.Icon_EditTags')"
    >
      <template v-slot:title>{{ $t('Components.Reservation.Tags.Subtitle_Tags')}}</template>
    </ui-section-header>

    <Message class="is-info">
      {{ messageTagsExplanation }}</Message>

    <tags
      v-if="reservation.Tags.length > 0"
      :tags="reservation.Tags.split(',')"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import Message from '@/components/UI/Message'
import ModalEditTags from '@/components/Reservation/ModalEditTags'
import Tags from '@/components/UI/Tags'

export default {
  props: {
    isEmployee: {
      default: false,
      type: Boolean,
    },

    isLocked: {
      default: true,
      type: Boolean,
    },
  },

  components: {
    Message,
    tags: Tags,
    ModalEditTags,
  },

  data() {
    return {
      showEditTags: false,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),

    messageTagsExplanation() {
      if (this.reservation.MeetingtypeId === 2) {
        return this.$t('Components.Reservation.Tags.Tag_MessageNoTagsAdded_MeetingType_2')
      }
      return this.$t('Components.Reservation.Tags.Tag_MessageNoTagsAdded_MeetingType_1')
    }
  },

  methods: {
    setShowEditTags() {
      this.showEditTags = true
    },

    hideModal() {
      this.showEditTags = false
    },
  },
}
</script>
