<template>
  <div>
    <div v-if="voucherApplied">
      {{ voucherName }}
      <!-- <a @click="setShowVoucherSummary()">
        <span class="icon">
          <font-awesome-icon :icon="['fas', 'question-circle']" />
        </span>
      </a> -->
      <a
        @click="revertVoucher"
        v-if="!isLocked"
        :title="$t('Components.Vouchers.CheckVoucher.Button_Delete')"
      >
        <span
          class="icon has-text-danger"
          :class="{ 'is-loading': isChecking }"
        >
          <font-awesome-icon :icon="['fa', 'trash-alt']" />
        </span>
      </a>
    </div>

    <div v-if="!voucherApplied">
      <div class="field has-addons">
        <div class="control">
          <input
            type="text"
            v-model="voucherCode"
            v-on:keyup.13="checkVoucher"
            class="input is-small"
          />
        </div>
        <div class="control">
          <a
            @click="checkVoucher"
            class="button is-small is-primary"
            :class="{ 'is-loading': isChecking }"
          >{{
              $t('Components.Vouchers.CheckVoucher.Button_CheckVoucher')
            }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import voucherProvider from '../../providers/voucher'
import { EventBus } from '@/eventBus/event-bus'

export default {
  components: {
  },

  props: {
    isLocked: {
      default: false,
      type: Boolean
    },

    reservation: {
      default: null,
      type: Object
    }
  },

  data() {
    return {
      isChecking: false,
      mReservation: this.reservation,
      showSideDrawer: false,
      voucher: null,
      voucherId: 0,
      voucherName: '',
      voucherCode: '',
    }
  },

  computed: {
    voucherApplied() {
      let self = this
      let applied = false

      if (
        (self.mReservation && self.mReservation.VoucherId > 0)
      ) {
        self.voucherId = self.mReservation.VoucherId
        self.voucherName = self.mReservation.VoucherName
        applied = true
      }

      return applied
    }
  },

  methods: {
    checkVoucher() {
      if (this.voucherCode.length > 0) {
        if (this.reservation && this.reservation.Id > 0) {
          this.applyReservationVoucher()
        }
      } else {

        EventBus.$emit('showToast', {
          type: 'warning',
          message: this.$t('Components.Vouchers.CheckVoucher.Toast_NoCodeEntered')
        })
      }
    },

    applyReservationVoucher() {
      let self = this
      self.isChecking = true

      voucherProvider.methods
        .applyReservationVoucher(self.voucherCode, self.mReservation.Id)
        .then(response => {
          let result = response.data

          if (result.StatusCode < 1) {

            EventBus.$emit('showToast', {
              type: 'warning',
              message: this.$t('Components.Vouchers.CheckVoucher.Toast_NoVoucherFound')
            })
          }

          if (result.StatusCode === 1) {
            self.mReservation = result.Reservation
            self.$emit('reservationVoucherApplied', result.Reservation)

            EventBus.$emit('showToast', {
              type: 'warning',
              message: this.$t(`Components.Vouchers.CheckVoucher.Message_StatusCode_${result.StatusCode}`, { name: self.mReservation.VoucherName })
            })
          }
        })
        .finally(() => {
          self.isChecking = false
        })
    },

    revertVoucher() {
      if (this.isChecking) { return }

      if (this.reservation && this.reservation.VoucherId > 0) {
        this.revertReservationVoucher()
      }
    },

    revertReservationVoucher() {
      let self = this
      self.isChecking = true

      voucherProvider.methods
        .revertReservationVoucher(self.mReservation.Id, self.voucherId)
        .then(response => {
          if (response.status === 200) {
            self.mReservation = response.data
            self.$emit('reservationVoucherApplied', response.data)

            EventBus.$emit('showToast', {
              type: 'info',
              message: this.$t('Components.Vouchers.CheckVoucher.Toast_VoucherRemoved')
            })
          }
        })
        .finally(() => {
          self.isChecking = false
        })
    },
  }
}
</script>
