var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showDrawer)?_c('UiComponentDrawer',{attrs:{"showDrawer":_vm.showDrawer,"closeFunc":_vm.onClickClose,"position":"right"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.isLoading)?_c('ui-loader',{key:"loading"}):_vm._e(),(!_vm.isLoading && _vm.mOption)?_c('div',{key:"ready"},[_c('div',{staticClass:"wrapper-images"},[_c('figure',{staticClass:"image is-3by2 has-background-grey-lighter"},[_c('img',{style:({
            backgroundImage: `url('${_vm.$options.filters.getLocationImage(
              _vm.mOption.Image,
              _vm.locationId,
              '640'
            )}')`,
          }),attrs:{"src":require("@/assets/img/spacer1_1.gif")}})])]),_c('div',{staticClass:"title is-3",domProps:{"textContent":_vm._s(_vm.optionName)}}),(_vm.upsellItems.length)?_c('UpsellTemplate',{staticClass:"has-margin-bottom-x2",attrs:{"upsellItems":_vm.upsellItems}}):_vm._e(),_c('div',{domProps:{"innerHTML":_vm._s(_vm.description)}})],1):_vm._e()],1)]},proxy:true}],null,false,12123318)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }