<template>
  <div class="upsells">
    <div
      class="upsells__upsell"
      v-for="(text, index) in upsellItems"
      :key="`u${index}`"
    >
      <span
        v-if="!hideIcon"
        class="icon"
      >
        <font-awesome-icon
          :icon="['fa', 'check']"
          class="has-text-success"
        />
      </span>
      <div v-html="text"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'upsell-template',

  props: {
    hideIcon: {
      type: Boolean,
      default: false
    },
    upsellItems: {
      type: Array,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.upsells {
  &__upsell {
    display: flex;
    margin-top: 5px;
    &:first-child {
      margin-top: 0;
    }
    .icon {
      flex-grow: 0;
      flex-shrink: 0;
      margin-right: 5px;
    }
  }
}
</style>