<template>
  <div
    class="card-space card p-0"
    :class="[viewType]"
  >
    <figure
      class="image has-background-grey-light"
      :style="{
        backgroundImage: `url('${spaceImage}')`,
      }"
    ></figure>
    <div class="settings_price">
      <div class="details p-4">
        <div
          :class="`spaceName is-size-4 has-text-weight-bold has-margin-bottom`"
          v-html="space.SpaceName"
        ></div>
        <div
          v-if="spaceAvailableSetting"
          class="price"
        >
          <strong
            class="has-text-weight-bold is-size-5"
            v-html="price"
          ></strong>
        </div>
        <template v-if="!spaceAvailableSetting">
          <Message
            v-for="(Warning, index) in space.Warnings"
            :key="index"
            class="is-warning has-margin-bottom"
          >{{$t(`Components.Availability.AvailableMeetingspace.Warning_CannotBeBooked`)}} {{$t(`Components.Availability.AvailableMeetingspace.Warning_${Warning}`)}}</Message>/>
        </template>
      </div>
      <div
        v-if="spaceAvailableSetting"
        class="has-background-info-light p-4"
      >
        <button
          @click="selectSetting()"
          class="button is-primary"
          v-text="$t('Components.Availability.AvailableMeetingspace.Link_Select')"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/eventBus/event-bus'

export default {
  props: {
    languageCulture: {
      type: String,
      default: 'nl-NL',
    },

    meetingtypeId: {
      type: Number,
      default: 0,
    },

    space: null,

    viewType: {
      type: String,
      default: 'list-row'
    },
  },

  components: {
  },

  data() {
    return {
      selectedSettingId: -1
    }
  },

  computed: {
    spaceImage() {
      return this.$options.filters.getLocationImage(
        this.space.SpaceImage, this.space.LocationId, '640')
    },

    spaceAvailableSetting() {
      return this.space.Settings.find(s => s.SpaceAvailable)
    },

    price() {
      let setting = this.space.Settings[0]
      return this.$options.filters.toCurrency(setting.Price, this.languageCulture, setting.CurrencyIso)
    },
  },

  methods: {
    selectSetting() {
      if (!this.spaceAvailableSetting.SpaceAvailable || this.selectedSettingId === this.spaceAvailableSetting.Id) { return }

      this.selectedSettingId = this.spaceAvailableSetting.Id

      let space = {
        Id: 0,
        LocationId: this.space.LocationId,
        SpaceId: this.space.SpaceId,
        SpaceName: this.space.SpaceName,
        SpaceImage: this.space.SpaceImage,
        SettingId: this.spaceAvailableSetting.Id,
        Seats: this.space.Seats,
        StartDate: this.space.StartDate,
        StartMinutes: this.space.StartMinutes,
        EndDate: this.space.EndDate,
        EndMinutes: this.space.EndMinutes,
        CurrencyId: this.spaceAvailableSetting.CurrencyId,
        PricePerSeat: this.spaceAvailableSetting.PricePerSeat,
        PriceTotal: this.spaceAvailableSetting.PricePerSeat * this.space.Seats,
        TaxId: this.spaceAvailableSetting.TaxId,
        TaxPercentage: this.spaceAvailableSetting.TaxPercentage,
        Hash: this.spaceAvailableSetting.Hash,
      }
      EventBus.$emit('spaceSelected', space)
      this.$emit('spaceSelected')
    },
  },
}
</script>

<style lang="scss" scoped>
.card-space {
  display: flex;
  .image {
    width: 100%;
    min-height: 200px;
    max-width: 300px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex-grow: 0;
  }

  &.list-card {
    flex-direction: column;
    width: 100%;
    .image {
      max-width: unset;
    }
  }

  .settings_price {
    flex-grow: 1;
    flex-shrink: 1;

    display: flex;
    flex-direction: column;
    .details {
      flex-grow: 1;
      flex-shrink: 1;
    }
  }
}
</style>