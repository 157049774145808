<template>
  <ui-component-modal
    :modalTitle="
      $t('Components.Reservation.ModalEditSpace.Header_ModalHeader') +
        ' ' +
        mSpace.SpaceName
    "
    :modalSize="modalSize"
    :showModal="showModal"
    :onClickCancel="onClickCancel"
    :onClickSave="saveSpace"
    :isSaving="isSaving || isValidating > 0"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :disableSaveButton="showNotAvailable || blockSaveButton"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <article
        v-if="locationOpeningHours"
        class="message is-info"
      >
        <div class="message-body">
          {{ $t('Components.Reservation.ModalEditSpace.Label_OpeningHours') }}
          {{ locationOpeningHours.MinMinutes | minutesToTime }} -
          {{ locationOpeningHours.MaxMinutes | minutesToTime }}
        </div>
      </article>

      <div v-if="setupDone && meetingtypeId < 3">

        <div>
          <table class="table is-fullwidth has-margin-bottom">
            <thead>
              <tr>
                <th width="125">
                  {{ $t('Components.Reservation.ModalEditSpace.Label_Pax') }}
                </th>
                <th
                  v-if="reservation.MeetingtypeId === 1"
                  width="300"
                >
                  {{
                    $t(
                      'Components.Reservation.ModalEditSpace.Label_Configuration'
                    )
                  }}
                </th>
                <th width="150">
                  {{
                    $t('Components.Reservation.ModalEditSpace.Label_StartTime')
                  }}
                </th>
                <th width="150">
                  {{
                    $t('Components.Reservation.ModalEditSpace.Label_EndTime')
                  }}
                </th>
                <th
                  width="100"
                  class="has-text-centered"
                >
                  {{ $t('Components.Reservation.ModalEditSpace.Label_Total') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div
                    v-if="reservation.MeetingtypeId === 1"
                    class="field has-addons"
                  >
                    <p class="control">
                      <InputNumeric
                        @input="delay(validateAvailability, 100)"
                        v-model.number="mSpace.Seats"
                        :isNumeric="true"
                        :class="{ 'is-danger': showNoSeatsWarning  }"
                        :maxLength="4"
                      />
                    </p>
                    <p class="control">
                      <a class="button is-static">X</a>
                    </p>
                  </div>
                  <div v-if="reservation.MeetingtypeId === 2">
                    {{ mSpace.Seats }} X
                  </div>
                </td>
                <td v-if="reservation.MeetingtypeId === 1">
                  <div
                    class="select"
                    :class="{'is-danger': showNoSettingSelectedWarning}"
                  >
                    <select
                      @change="validateAvailability"
                      v-model="mSpace.SettingId"
                    >
                      <option
                        :value="0"
                        :selected="space.SettingId === 0"
                      >Select a configuration</option>
                      <option
                        v-for="(setting, index) in settings"
                        :key="index"
                        :value="setting.SettingId"
                        :selected="setting.SettingId === space.SettingId"
                      >{{ setting.SettingId | getSettingName }} ({{setting.MinSeats}} - {{setting.MaxSeats}})</option>
                    </select>
                  </div>
                </td>
                <td>
                  <div
                    class="select"
                    :class="{ 'is-danger': showStartTimeAferEndTimeWarning }"
                  >
                    <select
                      @change="validateAvailability"
                      v-model="mSpace.StartMinutes"
                    >
                      <option
                        v-for="(timeSlot, index) in timeSlots"
                        :key="index"
                        :value="timeSlot"
                        :selected="mSpace.StartMinutes === timeSlot"
                      >{{ timeSlot | minutesToTime }}</option>
                    </select>
                  </div>
                </td>
                <td>
                  <div
                    class="select"
                    :class="{ 'is-danger': showStartTimeAferEndTimeWarning }"
                  >
                    <select
                      @change="validateAvailability"
                      v-model="mSpace.EndMinutes"
                    >
                      <option
                        v-for="(timeSlot, index) in timeSlots"
                        :key="index"
                        :value="timeSlot"
                        :selected="mSpace.EndMinutes === timeSlot"
                      >{{ timeSlot | minutesToTime }}</option>
                    </select>
                  </div>
                </td>
                <td class="has-text-centered has-text-weight-bold">
                  <transition name="fade">
                    <span
                      v-if="isRecalculating"
                      key="loading"
                      class="is-loading"
                    ></span>
                    <span
                      v-if="!isRecalculating"
                      key="ready"
                    >{{
                    meetingspaceTotalPrice
                      | toCurrency('nl', mSpace.CurrencyISO)
                  }}</span>
                  </transition>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <article
          v-if="showNotAvailable && isValidating === 0"
          class="message is-warning"
        >
          <div class="message-body">
            <ul>
              <li v-if="showWarning(0)">
                {{
                  $t(
                    'Components.Reservation.ModalEditSpace.Warning_LocationIsClosedOnDate'
                  )
                }}
              </li>
              <li v-if="showWarning(1)">
                {{
                  $t(
                    'Components.Reservation.ModalEditSpace.Warning_LocationIsClosed'
                  )
                }}
                <div>
                  <a
                    @click="resetSpaceValue"
                    class="is-size-7"
                  >
                    <span class="icon is-small">
                      <font-awesome-icon :icon="['fas', 'undo']" />
                    </span>
                    {{
                      $t('Components.Reservation.ModalEditSpace.Button_Undo')
                    }}
                  </a>
                </div>
              </li>
              <li v-if="showWarning(2)">
                <span>{{
                  $t(
                    'Components.Reservation.ModalEditSpace.Warning_SpaceNotAvailable'
                  )
                }}</span>
              </li>
              <li v-if="showWarning(3)">
                <span>{{
                  $t(
                    'Components.Reservation.ModalEditSpace.Warning_MinimumHours'
                  )
                }}</span>
                <div>
                  <a
                    @click="resetSpaceValue"
                    class="is-size-7"
                  >
                    <span class="icon is-small">
                      <font-awesome-icon :icon="['fas', 'undo']" />
                    </span>
                    {{
                      $t('Components.Reservation.ModalEditSpace.Button_Undo')
                    }}
                  </a>
                </div>
              </li>
              <li v-if="showWarning(4)">
                <span>{{
                  $t(
                    'Components.Reservation.ModalEditSpace.Warning_NumberOfSeats'
                  )
                }}</span>
                <div>
                  <a
                    @click="resetSpaceValue"
                    class="is-size-7"
                  >
                    <span class="icon is-small">
                      <font-awesome-icon :icon="['fas', 'undo']" />
                    </span>
                    {{
                      $t('Components.Reservation.ModalEditSpace.Button_Undo')
                    }}
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </article>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { EventBus } from '@/eventBus/event-bus'
import availabilityProvider from '../../providers/availability'
import openinghoursProvider from '../../providers/openinghours'
import reservationProvider from '../../providers/reservation'
import spaceProvider from '../../providers/space'


export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
    onClickSave: {
      type: Function,
      required: false,
    },
    channelId: {
      default: 0,
      type: Number,
    },
    isEmployee: {
      default: false,
      type: Boolean,
    },
    locationId: {
      default: 0,
      type: Number,
    },
    meetingtypeId: {
      default: 0,
      type: Number,
    },
    index: {
      default: 0,
      type: Number,
    },
    space: null,
  },

  data() {
    return {
      timer: null,
      check: null,
      checkString: '1#1#1#1',
      isAvailable: true,
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      isValidating: 0,
      mSpace: null,
      settings: [],
      setupDone: false,
      showLocationOpenWarning: false,
      showLocationOpeningHoursWarning: false,
      showMinimumHoursWarning: false,
      showSeatsSettingWarning: false,
      showUnitAvailableWarning: false,
      isRecalculating: false,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation', 'locationOpeningHours']),

    modalSize() {
      return this.meetingtypeId === 1 ? 'large' : ''
    },

    timeSlots() {
      let timeSlots = []

      let min = this.locationOpeningHours ? this.locationOpeningHours.MinMinutes : 0
      let max = this.locationOpeningHours ? this.locationOpeningHours.MaxMinutes : 1440
      let minutes = this.meetingtypeId === 1 ? 30 : 15

      while (min <= max) {
        timeSlots.push(min)
        min = min + minutes
      }
      return timeSlots
    },

    meetingspaceTotalPrice() {
      let total = 0.0
      if (this.mSpace) {
        if (this.mSpace.CalculationType === 'PerDayPartOnly') {
          total = Number(this.mSpace.PricePerSeat)
        } else {
          total =
            Math.round(
              Number(this.mSpace.Seats) * Number(this.mSpace.PricePerSeat) * 100
            ) / 100
        }
      }

      return total
    },

    showNotAvailable() {
      let show = false

      show = !this.isAvailable

      return show
    },

    blockSaveButton() {
      return (
        this.showNoSeatsWarning ||
        this.showStartTimeAferEndTimeWarning ||
        this.showEndTimeBeforeStartTimeWarning ||
        this.showNoSettingSelectedWarning
      )
    },

    showNoSeatsWarning() {
      return String(this.mSpace.Seats).length === 0
    },

    showNoSettingSelectedWarning() {
      return this.meetingtypeId === 1 && this.mSpace.SettingId === 0
    },

    showStartTimeAferEndTimeWarning() {
      return this.mSpace.StartMinutes >= this.mSpace.EndMinutes
    },

    showEndTimeBeforeStartTimeWarning() {
      return this.mSpace.EndMinutes <= this.mSpace.StartMinutes
    },
  },

  async created() {
    this.mSpace = JSON.parse(JSON.stringify(this.space))
    await this.getLocationOpeninghours()
    await this.getSpaceData()
    this.setupDone = true
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    async getLocationOpeninghours() {
      if (this.locationOpeningHours) { return true }

      await openinghoursProvider.methods
        .getOpeningHours({
          date: this.space.StartDate,
          channeldId: 0,
          locationId: this.locationId,
          meetingtypeId: this.meetingtypeId
        })
        .then((response) => {
          if (response.status === 200) {
            this.setLocationOpeningHours(response.data)
          }
        })
    },

    async getSpaceData() {
      await spaceProvider.methods
        .getSpaceById(this.space.SpaceId)
        .then((response) => {
          if (response.status === 200) {
            this.settings = response.data.Settings
          }
        })
    },

    delay(callback, ms) {
      let self = this
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.isRecalculating = true
      this.timer = setTimeout(() => {
        callback.apply(self)
        clearTimeout(self.timer)
      }, ms || 0)
    },

    validateAvailability() {
      if (Number(this.mSpace.Seats) > 0) {
        this.mSpace.LocationId = this.locationId
        this.mSpace.ChannelId = this.channelId
        this.mSpace.MeetingtypeId = this.meetingtypeId
        this.mSpace.VoucherId = this.reservation.VoucherId

        this.isValidating++

        availabilityProvider.methods
          .checkSpaceTimeAvailability(this.mSpace)
          .then((response) => {
            this.check = response.data
            let checksArray = this.check.CheckString.split('#')

            this.showLocationOpenWarning = Number(checksArray[0]) === 0
            this.showLocationOpeningHoursWarning = Number(checksArray[1]) === 0
            this.showUnitAvailableWarning = Number(checksArray[2]) === 0
            this.showMinimumHoursWarning = Number(checksArray[3]) === 0
            this.showSeatsSettingWarning = Number(checksArray[4]) === 0

            if (this.check.IsAvailable) {
              this.mSpace.PricePerSeat = this.check.PricePerSeat
              this.mSpace.PriceTotal = this.check.PriceTotal
              this.mSpace.Crc = this.check.Crc
            }

            this.isAvailable = this.check.IsAvailable
            this.checkString = this.check.CheckString
          })
          .finally(() => {
            --this.isValidating
            this.isRecalculating = false
          })
      }
    },

    showWarning(index) {
      let checks = this.checkString.split('#')

      return Number(checks[index]) === 0
    },

    cancelEditReservationSpace() {
      this.$emit('cancelEditSpace')
    },

    resetSpaceValue() {
      let self = this
      self.checkString = '1#1#1#1'
      self.isAvailable = true
      self.mSpace = JSON.parse(JSON.stringify(self.space))
    },

    saveSpace() {
      let self = this

      if (this.isSaving) { return }
      this.isSaving = true

      this.mSpace.PriceTotal = this.meetingspaceTotalPrice

      reservationProvider.methods
        .updateReservationSpace(this.reservation.Id, this.mSpace)
        .then((response) => {
          if (response.status === 200) {
            // Update reservation store
            self.setReservation(response.data)

            // Send message to display toast on screen
            EventBus.$emit('showToast', {
              type: 'info',
              message: this.$t('General.Message_ReservationSaved'),
            })

            // Close modal
            self.onClickCancel()
          }
        })
        .catch((error) => {
          self.isSavingError = true
          self.responseMessage = error.response.data.Value
        })
        .finally(() => {
          self.isSaving = false
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.table {
  td {
    vertical-align: middle !important;
  }
}
</style>