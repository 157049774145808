<template>
  <ui-component-modal
    :modalTitle="$t('Components.Reservation.ModalAddComment.Main_Title')"
    :isSaving='isSaving'
    :isSavingSuccess='isSavingSuccess'
    :isSavingError='isSavingError'
    :hideFooterButtons='isSavingError || isSavingSuccess'
    :onClickCancel='onClickCancel'
    :onClickSave='saveNewComment'
    :showModal='showModal'
    @closeModal='onClickCancel'
    :disableSaveButton="noText"
  >
    <template slot='modalTitle'></template>
    <template v-slot:content>
      <div v-if="newComment.ItemId">
        <textarea
          v-model="newComment.Text"
          class="textarea"
        ></textarea>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapState } from 'vuex'
import commentProvider from '../../providers/comment'

export default {
  components: {
  },

  props: {
    showModal: {
      type: Boolean,
      default: false
    },

    onClickCancel: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      newComment: {},
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),

    noText() {
      let result = true

      if (this.newComment.Text !== '') {
        result = false
      }
      return result
    }
  },

  created() {
    this.newComment = this.setNewComment()
  },

  methods: {
    saveNewComment() {
      let self = this
      if (!self.isSaving) {
        self.isSaving = true
      }
      commentProvider.methods
        .addReservationComment(this.newComment.ItemId, this.newComment.Text)
        .then(response => {
          if (response.status === 200) {
            self.isSavingSuccess = true

            let t = setTimeout(() => {
              self.reservation.Comments.push(response.data)
              self.onClickCancel()
              clearTimeout(t)
            }, 1500)
          }
        })
        .catch(() => {
          self.isSavingError = true
        })
        .finally(() => {
          self.isSaving = false
        })
    },

    setNewComment() {
      let comment = {
        ItemId: this.reservation.Id,
        Text: "",
      }
      return comment
    }
  }
}
</script>