<template>
  <ui-component-modal
    :modalTitle="
      $t('Components.Spaces.ModalAddSpace.Title_AddReservationSpace')
    "
    modalSize="large"
    :hideSaveButton="true"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :onClickCancel="onClickCancel"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <CheckAvailability
        :channelId="channelId"
        :locationId="locationId"
        :meetingtypeId="meetingtypeId"
        :showDateInput="false"
        :startDate="startDate"
        :endDate="endDate"
        :startMinutes="startMinutes"
        :endMinutes="endMinutes"
        :voucherId="voucherId"
        :locationOpeningHours="locationOpeningHours"
      />
    </template>
  </ui-component-modal>
</template>

<script>
import { EventBus } from '@/eventBus/event-bus'
import { mapState, mapMutations, mapGetters } from 'vuex'
import reservationProvider from '../../providers/reservation'
import CheckAvailability from './Availability/CheckAvailability'

export default {
  components: {
    CheckAvailability,
  },

  props: {
    autoSearch: {
      default: false,
      type: Boolean,
    },
    canChangeDate: {
      default: false,
      type: Boolean,
    },
    channelId: {
      default: 0,
      type: Number,
    },
    countryId: {
      default: 0,
      type: Number,
    },
    endDate: {
      default: function() {
        return new Date()
      },
      type: Date,
    },
    endMinutes: {
      default: 0,
      type: Number,
    },
    locationId: {
      default: 0,
      type: Number,
    },
    meetingtypeId: {
      default: 1,
      type: Number,
    },
    seats: {
      default: 1,
      type: Number,
    },
    voucherId: {
      default: 0,
      type: Number,
    },
    settingId: {
      default: 0,
      type: Number,
    },
    showDateInput: {
      default: false,
      type: Boolean,
    },
    startDate: {
      default: function() {
        return new Date()
      },
      type: Date,
    },
    startMinutes: {
      default: 0,
      type: Number,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      spaces: []
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation', 'locationOpeningHours']),
  },

  watch: {},

  created() {
    let self = this

    EventBus.$on('spaceSelected', (space) => {
      space.ReservationId = self.reservation.Id
      this.spaces.push(space)
      self.addReservationSpaces()
    })
  },

  beforeDestroy() {
    EventBus.$off('spaceSelected')
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation', 'increaseBusyState', 'decreaseBusyState']),

    addReservationSpaces() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true
        this.increaseBusyState()

        reservationProvider.methods
          .addReservationSpaces(self.reservation.Id, self.spaces)
          .then((response) => {
            if (response.status === 200) {
              self.isSavingSuccess = true

              // Update reservation store
              self.setReservation(response.data)

              // Send message to display toast on screen
              EventBus.$emit('showToast', {
                type: 'info',
                message: this.$t('General.Message_ReservationSaved'),
              })

              // Close modal
              this.onClickCancel()
            }
          })
          .catch((error) => {
            self.isSavingError = true
            self.responseMessage = error.response.data.Value
          })
          .finally(() => {
            self.isSaving = false
            this.decreaseBusyState()
          })


        // reservationProvider.methods
        //   .saveReservation(self.reservation)
        //   .then((response) => {
        //     if (response.status === 200) {
        //       self.isSavingSuccess = true

        //       // Update reservation store
        //       self.setReservation(response.data)

        //       // Set timer to close popup in in 2,5 seconds
        //       let t = setTimeout(() => {
        //         this.onClickCancel()
        //         clearTimeout(t)
        //       }, 2500)
        //     }
        //   })
        //   .catch((error) => {
        //     self.isSavingError = true
        //   })
        //   .finally(() => {
        //     self.isSaving = false
        //     this.decreaseBusyState()
        //   })
      }
    },
  },
}
</script>
