import axios from 'axios'
import store from '../store/store'

export default {
  methods: {
    /**
     *
     * @param {number} channelId
     * @param {number} locationId
     * @param {number} companyId
     * @param {number} profileId
     * @param {string} searchTerm
     * @param {boolean} showExpired
     * @param {boolean} isActive
     * @param {number} page
     * @param {number} itemsPerPage
     */
    getProfileVouchers(channelId = 0, locationId = 0, companyId = 0, profileId = 0, searchTerm = '', showExpired = false, isActive = true, page = 0, itemsPerPage = 0) {
      let criteria = {
        ChannelId: channelId,
        LocationId: locationId,
        CompanyId: companyId,
        ProfileId: profileId,
        SearchTerm: searchTerm,
        ShowExpired: showExpired,
        IsActive: isActive,
        Page: page,
        ItemsPerPage: itemsPerPage
      }

      return axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/voucher/profile?${JSON.stringify(criteria)}`, {
        headers: {
          'Content-type': 'application/json',
          token: process.env.VUE_APP_API_KEY,
          profileToken: store.getters.getProfileToken
        }
      })
    },

    
    /**
     * Get voucher by ID
     * @param {Number} voucherId 
     * @param {Number} locationId 
     * @returns voucher object
     */
    async getVoucherById(voucherId, locationId = null) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/voucher/${voucherId}`,
        {
          params: {
            locationId: locationId
          },
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    /**
     * Apply voucher to reservation
     * @param {String} voucherCode 
     * @param {Number} reservationId 
     * @returns 
     */
    async applyReservationVoucher(voucherCode = '', reservationId) {
      let criteria = {
        Code: voucherCode
      }
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/voucher/reservation/${reservationId}/apply`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    /**
     * Revert voucher from reservation
     * @param {Number} reservationId 
     * @param {Number} voucherId 
     * @returns 
     */
    async revertReservationVoucher(reservationId, voucherId) {
      let criteria = {
        VoucherId: voucherId
      }

      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/voucher/reservation/${reservationId}/revert`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },
  }
}
