<template>
  <div v-if="reservation">

    <DrawerOptionDetail
      v-if="showOptionSummary"
      :showDrawer="showOptionSummary"
      :onClickClose="hideDrawer"
      :optionId="itemId"
      :locationId="reservation.LocationId"
    />

    <ModalAddOption
      v-if="showAddOption"
      :showModal="showAddOption"
      :reservationSpaces="reservation.Spaces"
      :endMinutes="reservation.EndMinutes"
      :startMinutes="reservation.StartMinutes"
      :hideSelectedItems="true"
      :isEmployee="isEmployee"
      :onClickCancel="hideModal"
    />

    <ModalEditOption
      v-if="showEditOption"
      :showModal="showEditOption"
      :isEmployee="isEmployee"
      :isLocked="isLocked"
      :option="selectedOption"
      :onClickCancel="hideModal"
    />

    <ModalDeleteOption
      v-if="showDeleteOption"
      :showModal="showDeleteOption"
      :option="selectedOption"
      :onClickCancel="hideModal"
    />

    <ui-section-header
      :showAdd="!isLocked"
      :addFunction="setShowAddOption"
      :addLabel="$t('Components.Reservation.Options.Icon_AddOption')"
    >
      <template v-slot:title>{{
        $t('Components.Reservation.Options.Subtitle_Options')
      }}</template>
    </ui-section-header>

    <Message
      class="is-info"
      v-if="reservation.Options.length === 0"
    >{{$t('Components.Reservation.Options.Message_NoOptionsAdded')}}</Message>

    <div v-if="reservation.Options.length > 0">
      <div
        v-for="(cat, cIndex) in categories"
        :key="cat"
      >
        <div
          class="has-text-weight-bold has-margin-bottom-half"
          :class="{ 'has-margin-top' : cIndex !== 0}"
          v-html="$t('Components.Reservation.ReservationOptions.Category_' + cat)"
        >
        </div>
        <table class="table is-fullwidth is-striped is-hoverable is-narrow has-margin-top">
          <thead>
            <tr>
              <th>
                {{
                $t('Components.Reservation.Options.Table_Quantity')
              }}
              </th>
              <th>
                {{ $t('Components.Reservation.Options.Table_Name') }}
              </th>
              <th v-if="timeSelectableOptions.length > 0">
                {{ $t('Components.Reservation.Options.Table_Time') }}
              </th>
              <th class="has-text-right">
                {{ $t('Components.Reservation.Options.Table_Price') }}
              </th>
              <th class="has-text-right">
                {{ $t('Components.Reservation.Options.Table_Tax') }}
              </th>
              <th class="has-text-right">
                {{ $t('Components.Reservation.Options.Table_Total') }}
              </th>
              <th class="is-hidden-print">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(option, index) in reservation.Options.filter(
                (o) => o.CategoryId === cat
              )"
              :key="'rc' + index"
            >
              <td>{{ option.Amount }}x</td>
              <td>
                <div>
                  <!-- <span>{{ option.Name }}</span> -->
                  <a
                    @click="setShowOptionSummary(option.OptionId)"
                    v-html="option.Name"
                  >
                    <!-- <span class="icon is-hidden-print">
                      <font-awesome-icon :icon="['fas', 'info-circle']" />
                    </span> -->
                  </a>
                </div>
                <div v-if="option.Id > 0">
                </div>
              </td>
              <td v-if="timeSelectableOptions.length > 0">
                <span v-if="option.TimeSelectable === 2 && option.SelectedTime !== -1">{{
                option.SelectedTime | minutesToTime
              }}</span>
                <span v-else>-</span>
              </td>
              <td class="has-text-right">
                <span v-if="!reservationHasPackageApplied || !option.InVoucher">{{
                option.PricePerItem
                  | toCurrency('nl-NL', reservation.CurrencyIso)
              }}</span>
              </td>
              <td class="has-text-right">
                <span v-if="!reservationHasPackageApplied || !option.InVoucher">{{ option.TaxPercentage }}%</span>
              </td>
              <td class="has-text-right">
                <span v-if="!reservationHasPackageApplied || !option.InVoucher">{{
                option.PriceTotal | toCurrency('nl-NL', reservation.CurrencyIso)
              }}</span>
              </td>
              <td
                width="75"
                class="is-hidden-print"
              >
                <div
                  v-if="!isLocked && option.RequiredItem !== 2"
                  class="field is-grouped has-text-right"
                >
                  <p class="control">
                    <a
                      @click="setEditOption(option, index)"
                      class="is-small"
                    >
                      <span class="icon">
                        <font-awesome-icon :icon="['fas', 'edit']" />
                      </span>
                    </a>
                  </p>
                  <p
                    class="control"
                    v-if="option.RequiredItem !== 2"
                  >
                    <a
                      @click="setDeleteOption(option, index)"
                      class="is-small"
                    >
                      <span class="icon has-text-danger">
                        <font-awesome-icon :icon="['fas', 'trash-alt']" />
                      </span>
                    </a>
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot v-if="cIndex === categories.length - 1">
            <tr>
              <td :colspan="timeSelectableOptions.length > 0 ? 5 : 4">
                {{
                $t('Components.Reservation.Options.Table_Subtotal')
              }}
              </td>
              <td class="has-text-right">
                <span>{{
                total | toCurrency('nl-NL', reservation.CurrencyIso)
              }}</span>
              </td>
              <td class="is-hidden-print">&nbsp;</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { EventBus } from '@/eventBus/event-bus'
import reservationProvider from '@/providers/reservation'

import ModalAddOption from '@/components/Reservation/ModalAddOption'
import ModalEditOption from '@/components/Reservation/ModalEditOption'
import ModalDeleteOption from '@/components/Reservation/ModalDeleteOption'
import DrawerOptionDetail from '@/components/Reservation/DrawerOptionDetail'
import Message from '../UI/Message.vue'

export default {
  components: {
    ModalAddOption,
    ModalEditOption,
    ModalDeleteOption,
    DrawerOptionDetail,
    Message,
  },

  props: {
    isEmployee: {
      default: false,
      type: Boolean,
    },

    isLocked: {
      default: true,
      type: Boolean,
    },
  },

  data() {
    return {
      drawerTitle: '',
      originalReservation: null,
      selectedOption: null,
      showAddOption: false,
      showEditOption: false,
      showEditTotalSeats: false,
      showDeleteOption: false,
      showOptionSummary: false,
      showSideDrawer: false,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),

    categories() {
      let categories = []

      if (this.reservation && this.reservation.Options.length > 0) {
        for (let i = 0; i < this.reservation.Options.length; i++) {
          let option = this.reservation.Options[i]

          if (categories.findIndex((c) => c === option.CategoryId) === -1) {
            categories.push(option.CategoryId)
          }
        }
      }

      return categories.sort()
    },

    timeSelectableOptions() {
      let result = []

      this.reservation.Options.map(option => {
        option.TimeSelectable === 2 ? result.push(option) : null
      })

      return result
    },

    reservationHasPackageApplied() {
      let result = false

      if (this.reservation && this.reservation.Voucher) {
        result = this.reservation.Voucher.Type === 'Package'
      }

      return result
    },

    total() {
      let total = 0.0

      for (let i = 0; i <= this.reservation.Options.length; i++) {
        let option = this.reservation.Options[i]
        if (option) {
          if (!this.reservationHasPackageApplied || !option.InVoucher)
            total = total + option.PriceTotal
        }
      }

      return total
    },
  },

  created() {
    this.originalReservation = JSON.parse(JSON.stringify(this.reservation))
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    cancelEditOption() {
      this.editSpace = null
    },

    delay(callback, ms) {
      let self = this
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        callback.apply(self)
        clearTimeout(self.timer)
      }, ms || 0)
    },

    saveReservation() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        reservationProvider.methods
          .saveReservation(self.reservation, false)
          .then((response) => {
            if (response.status === 200) {
              // Update reservation store
              self.setReservation(response.data)

              EventBus.$emit('showToast', {
                type: 'info',
                message: this.$t("General.Message_ReservationSaved"),
              })

              // Update originalReservation data
              self.originalReservation = JSON.parse(
                JSON.stringify(response.data)
              )
            }
          })
          .catch(() => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
            self.isSavingSuccess = true
          })
      }
    },

    setShowEditTotalSeats() {
      this.showEditTotalSeats = true
    },

    setShowAddOption() {
      this.showAddOption = true
    },

    setEditOption(option, index) {
      this.selectedOption = option
      this.selectedOption.Index = index
      this.showEditOption = true
    },

    setDeleteOption(option, index) {
      this.selectedOption = option
      this.showDeleteOption = true
    },

    hideModal() {
      this.showAddOption = false
      this.showEditOption = false
      this.showDeleteOption = false
      this.showEditTotalSeats = false

      this.selectedOption = null
    },

    setShowOptionSummary(optionId) {
      this.itemId = optionId
      this.drawerTitle = 'Option detail'
      this.showSideDrawer = true
      this.showOptionSummary = true
    },

    hideDrawer() {
      this.itemId = 0
      this.drawerTitle = ''
      this.showSideDrawer = false
      this.showOptionSummary = false
    },
  },
}
</script>
