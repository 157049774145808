<template>
  <div class="tags" v-if="tags && tags.length > 0">
    <span v-for="(tag, index) in tags" :key="index" class="tag is-link"
      >{{ tag }}
      <a
        v-if="showDelete"
        @click="removeTag(tag, index)"
        class="delete is-small"
      ></a>
    </span>
  <span class="tag is-link" v-if="remainingTags > 0">
    + {{remainingTags}} tags
  </span>
</div> 
</template>

<script>
export default {
  props: {
    showDelete: {
      default: false,
      type: Boolean
    },
    remainingTags: {
      default: 0,
      type: Number
    },

    tags: {
      default: function() {
        return []
      },
      type: Array
    }
  },

  data() {
    return {
    }
  },

  methods: {
    removeTag(tag) {
      this.$emit('tagDeleted', tag)
    }
  }
}
</script>
