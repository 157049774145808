<template>
  <ui-component-modal
    :modalTitle="
      $t('Components.Reservation.ModalDeleteExternalLink.Main_Title')
    "
    confirmButtonCssClass="is-danger"
    :buttonLabelConfirm="$t('Form.Control.Delete')"
    :isSaving="isDeleting"
    :savingSuccessMessage="
      $t('Components.Reservation.ModalDeleteExternalLink.Message_SavingSuccess')
    "
    :isSavingError="isDeletingError"
    :savingErrorMessage="
      $t('Components.Reservation.ModalDeleteExternalLink.Message_SavingError')
    "
    :disableSaveButton="!isDeleteConfirmed || isDeleting"
    :hideFooterButtons="isDeletingError"
    :onClickCancel="onClickCancel"
    :onClickSave="saveDeleteExternalLink"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div
        class="has-margin-bottom is-size-5 has-text-centered"
        v-html="
          $t('Components.Reservation.ModalDeleteExternalLink.Text_Delete', {
            link: externalLink.Link,
          })
        "
      ></div>
      <div class="has-text-centered">
        <BaseCheckbox
          v-model="isDeleteConfirmed"
          class="has-text-left"
          :label="
            $t('Components.Reservation.ModalDeleteExternalLink.Text_YesSure')
          "
        />
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventBus/event-bus'
import reservationProvider from '@/providers/reservation'

const BaseCheckbox = () => import('@/components/UI/Form/BaseCheckbox')

export default {
  components: {
    BaseCheckbox,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },

    externalLink: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isDeleteConfirmed: false,
      isDeleting: false,
      isDeletingError: false,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),
  },

  created() {
    if (this.externalLink) {
      this.mExternalLink = JSON.parse(JSON.stringify(this.externalLink))
    }
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    saveDeleteExternalLink() {
      let self = this

      if (!self.isDeleting) {
        self.isDeleting = true

        reservationProvider.methods
          .deleteExternalLink(self.reservation.Id, self.mExternalLink.Id)
          .then((response) => {
            if (response.status === 204) {
              self.isDeletingSuccess = true

              this.$emit('externalLinkDeleted', self.mExternalLink)

              EventBus.$emit('showToast', {
                type: 'info',
                message: 'External link deleted!',
              })

              self.onClickCancel()
            }
          })
          .catch((error) => {
            self.isDeletingError = true
          })
          .finally(() => {
            self.isDeleting = false
          })
      }
    },
  },
}
</script>
