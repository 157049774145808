<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <ui-loader
      key="loading"
      v-if="isLoading"
    />
    <div v-else-if="!isLoading && invoiceNotFound">
      <Message class="is-info">
        {{$t('Components.Invoices.InvoiceDetail.Message_InvoiceNotFound')}}
      </Message>
    </div>
    <div v-else-if="!isLoading && invoicePending">
      <Message class="is-info">
        {{$t('Components.Invoices.InvoiceDetail.Message_InvoicePending')}}
      </Message>
    </div>
    <div
      v-else-if="invoice.StatusId === 1"
      :key="'invoice_' + invoiceUpdateCounter"
    >
      <div
        v-if="showInvoiceTitle"
        v-text="invoiceTitle"
        class="title is-3"
      ></div>
      <div
        v-if="invoice.StatusId > -1"
        class="actionBar has-background-white-ter has-padding has-margin-bottom has-text-right"
      >
        <LinkDownload
          type="invoice"
          :id="invoice.Id"
          class=" has-margin-left"
        />

        <LinkPrint
          type="invoice"
          :id="invoice.Id"
          class=" has-margin-left"
        />
        <!-- <div class="level is-mobile">
          <div class="level-left"></div>
          <div class="level-right">
            <a
              @click="downloadInvoice"
              class="has-icon"
            >
              <span class="icon is-small">
                <font-awesome-icon :icon="['fas', 'file-download']" />
              </span>
              <span>{{
              $t('Components.Invoices.InvoiceDetail.Icon_Download')
            }}</span>
            </a>
          </div>
        </div> -->
      </div>

      <section class="section">
        <div class="columns">
          <div class="column">
            <table class="table is-fullwidth is-narrow">
              <thead>
                <tr>
                  <th>
                    {{
                    $t(
                      'Components.Invoices.InvoiceDetail.TableHeader_InvoiceTo'
                    )
                  }}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="invoice.CompanyName.length > 0">
                  <td v-text="$t('Components.Invoices.InvoiceDetail.Table_Company')"></td>
                  <td>
                    {{ invoice.CompanyName }}
                  </td>
                </tr>
                <tr v-if="invoice.SendToName.length > 0">
                  <td v-text="$t('Components.Invoices.InvoiceDetail.Table_Attn')"></td>
                  <td>
                    {{ invoice.SendToName }}
                  </td>
                </tr>
                <tr v-if="invoice.EmailTo !== ''">
                  <td v-text="
                    $t('Components.Invoices.InvoiceDetail.Table_InvoiceEmail')
                  "></td>
                  <td>
                    {{ invoice.EmailTo }}
                  </td>
                </tr>
                <tr v-if="invoice.PoNumber !== ''">
                  <td v-text="
                    $t('Components.Invoices.InvoiceDetail.Table_PONumber')
                  "></td>
                  <td>
                    {{ invoice.PoNumber }}
                  </td>
                </tr>
                <tr v-if="invoice.VatNumber !== ''">
                  <td v-text="
                    $t('Components.Invoices.InvoiceDetail.Table_VATNumber')
                  "></td>
                  <td>
                    {{ invoice.VatNumber }}
                  </td>
                </tr>
                <tr v-if="invoice.Address !== ''">
                  <td v-text="$t('Components.Invoices.InvoiceDetail.Table_Address')"></td>
                  <td v-html="invoiceAddress"></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="column">
            <table class="table is-fullwidth is-narrow">
              <thead>
                <tr>
                  <th>
                    {{
                    $t('Components.Invoices.InvoiceDetail.TableHeader_Details')
                  }}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    width="200"
                    v-text="
                    $t(
                      'Components.Invoices.InvoiceDetail.TableHeader_InvoiceNr'
                    )
                  "
                  ></td>
                  <td
                    v-text="invoiceCode"
                    :class="{ 'has-text-danger': invoice.StatusId === -1 }"
                  ></td>
                </tr>
                <tr>
                  <td width="100">
                    {{
                    $t(
                      'Components.Invoices.InvoiceDetail.TableHeader_Reference'
                    )
                  }}
                  </td>
                  <td>{{ invoice.Reference }}</td>
                </tr>
                <tr>
                  <td width="100">Debtor</td>
                  <td>{{ invoice.DebtorId }}</td>
                </tr>
                <tr>
                  <td width="100">
                    {{
                    $t(
                      'Components.Invoices.InvoiceDetail.TableHeader_IssueDate'
                    )
                  }}
                  </td>
                  <td>
                    <span v-if="extractYear(invoice.ActivationDate) !== 1900">{{
                    invoice.ActivationDate
                      | parseIsoDateStringToDate
                      | dateFormat($i18n.locale, 'longDateFormat')
                  }}</span>
                    <span v-if="extractYear(invoice.ActivationDate) === 1900">{{
                    new Date() | dateFormat($i18n.locale, 'longDateFormat')
                  }}</span>
                  </td>
                </tr>
                <tr>
                  <td width="100">
                    {{
                    $t('Components.Invoices.InvoiceDetail.TableHeader_DueDate')
                  }}
                  </td>
                  <td>
                    {{
                    invoice.ExpirationDate
                      | parseIsoDateStringToDate
                      | dateFormat($i18n.locale, 'longDateFormat')
                  }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>

      <div
        v-for="reservationId in invoice.ReservationIds"
        :key="reservationId"
      >
        <section
          v-if="getPackageRows(reservationId).length > 0"
          class="section is-small"
        >
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th>
                  {{ $t('Components.Invoices.InvoiceDetail.TableHeader_Qty') }}
                </th>
                <th>
                  {{
                  $t(
                    'Components.Invoices.InvoiceDetail.TableHeader_Description'
                  )
                }}
                </th>
                <th
                  width="150"
                  class="has-text-right"
                >
                  {{ $t('Components.Invoices.InvoiceDetail.TableHeader_Price') }}
                </th>
                <th
                  width="100"
                  class="has-text-right"
                ></th>
                <th
                  width="100"
                  class="has-text-right"
                >
                  {{ $t('Components.Invoices.InvoiceDetail.TableHeader_Total') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="pck in getPackageRows(reservationId)"
                :key="pck.Id"
              >
                <td width="100">
                  <span>{{ pck.Amount }}x</span>
                </td>
                <td>{{ pck.Text }}</td>
                <td
                  width="150"
                  class="has-text-right"
                >
                  <span v-if="pck.ShowPrice">{{
                  pck.PricePerItem | toCurrency('nl-NL', 'EUR')
                }}</span>
                </td>
                <td
                  width="100"
                  class="has-text-right"
                >&nbsp;</td>
                <td
                  class="has-text-right"
                  width="100"
                >
                  <span v-if="pck.ShowPrice">{{
                  pck.TotalExclTax | toCurrency('nl-NL', 'EUR')
                }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <section
          v-if="getVoucherRows(reservationId).length > 0"
          class="section is-small"
        >
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th>
                  {{ $t('Components.Invoices.InvoiceDetail.TableHeader_Qty') }}
                </th>
                <th>
                  {{
                  $t(
                    'Components.Invoices.InvoiceDetail.TableHeader_Description'
                  )
                }}
                </th>
                <th
                  width="150"
                  class="has-text-right"
                >
                  {{ $t('Components.Invoices.InvoiceDetail.TableHeader_Price') }}
                </th>
                <th
                  width="100"
                  class="has-text-right"
                ></th>
                <th
                  width="100"
                  class="has-text-right"
                >
                  {{ $t('Components.Invoices.InvoiceDetail.TableHeader_Total') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="voucher in getVoucherRows(reservationId)"
                :key="voucher.Id"
              >
                <td width="100">
                  <span>{{ voucher.Amount }}x</span>
                </td>
                <td>{{ voucher.Text }}</td>
                <td
                  width="150"
                  class="has-text-right"
                >&nbsp;</td>
                <td
                  width="100"
                  class="has-text-right"
                >&nbsp;</td>
                <td
                  class="has-text-right"
                  width="100"
                >&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </section>

        <section class="section is-small">
          <p
            v-if="invoice.IsCollective"
            class="subtitle"
          >#{{ reservationId }}</p>
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th>
                  {{ $t('Components.Invoices.InvoiceDetail.TableHeader_Qty') }}
                </th>
                <th>
                  {{
                  $t(
                    'Components.Invoices.InvoiceDetail.TableHeader_Description'
                  )
                }}
                </th>
                <th
                  width="150"
                  class="has-text-right"
                >
                  {{ $t('Components.Invoices.InvoiceDetail.TableHeader_Price') }}
                </th>
                <th
                  width="100"
                  class="has-text-right"
                >
                  {{ $t('Components.Invoices.InvoiceDetail.TableHeader_Tax') }}
                </th>
                <th
                  width="100"
                  class="has-text-right"
                >
                  {{ $t('Components.Invoices.InvoiceDetail.TableHeader_Total') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="space in getSpaceRows(reservationId)"
                :key="space.Id"
              >
                <td width="100">
                  <span v-if="!space.IsTextRow">{{ space.Amount }}x</span>
                </td>
                <td>{{ space.Text }}</td>
                <td
                  width="150"
                  class="has-text-right"
                >
                  <span v-if="!space.IsTextRow && space.ShowPrice">{{
                  space.PricePerItem | toCurrency('nl-NL', 'EUR')
                }}</span>
                </td>
                <td
                  width="100"
                  class="has-text-right"
                >
                  <span v-if="!space.IsTextRow && space.ShowPrice">{{ space.TaxPercentage }}%</span>
                </td>
                <td
                  class="has-text-right"
                  width="100"
                >
                  <span v-if="!space.IsTextRow && space.ShowPrice">{{
                  space.TotalExclTax | toCurrency('nl-NL', 'EUR')
                }}</span>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="4">
                  {{
                  $t('Components.Invoices.InvoiceDetail.TableHeader_Subtotal')
                }}
                </td>
                <td class="has-text-right">
                  {{
                  getSpaceSubTotal(reservationId) | toCurrency('nl-NL', 'EUR')
                }}
                </td>
              </tr>
            </tfoot>
          </table>
        </section>

        <section class="section is-small">
          <div style="align:right">
            <table
              class="table is-fullwidth"
              v-if="getOptionRows(reservationId).length > 0"
            >
              <thead v-if="!invoice.IsCollective">
                <tr>
                  <th>
                    {{ $t('Components.Invoices.InvoiceDetail.TableHeader_Qty') }}
                  </th>
                  <th>
                    {{
                    $t(
                      'Components.Invoices.InvoiceDetail.TableHeader_Description'
                    )
                  }}
                  </th>
                  <th
                    width="150"
                    class="has-text-right"
                  >
                    {{
                    $t('Components.Invoices.InvoiceDetail.TableHeader_Price')
                  }}
                  </th>
                  <th
                    width="100"
                    class="has-text-right"
                  >
                    {{ $t('Components.Invoices.InvoiceDetail.TableHeader_Tax') }}
                  </th>
                  <th
                    class="has-text-right"
                    width="100"
                  >
                    {{
                    $t('Components.Invoices.InvoiceDetail.TableHeader_Total')
                  }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="option in getOptionRows(reservationId)"
                  :key="option.Id"
                >
                  <td width="100">{{ option.Amount }}x</td>
                  <td>{{ option.Text }}</td>
                  <td
                    width="150"
                    class="has-text-right"
                  >
                    <span v-if="!option.IsTextRow && option.ShowPrice">{{
                    option.PricePerItem | toCurrency('nl-NL', 'EUR')
                  }}</span>
                  </td>
                  <td
                    width="100"
                    class="has-text-right"
                  >
                    <span v-if="!option.IsTextRow && option.ShowPrice">{{ option.TaxPercentage }}%</span>
                  </td>
                  <td
                    class="has-text-right"
                    width="100"
                  >
                    <span v-if="!option.IsTextRow && option.ShowPrice">{{
                    option.TotalExclTax | toCurrency('nl-NL', 'EUR')
                  }}</span>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="4">
                    {{
                    $t('Components.Invoices.InvoiceDetail.TableHeader_Subtotal')
                  }}
                  </td>
                  <td class="has-text-right">
                    {{
                    getOptionSubTotal(reservationId)
                      | toCurrency('nl-NL', 'EUR')
                  }}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </section>
      </div>

      <section class="is-clearfix section is-small">
        <div class="columns">
          <div class="column"></div>
          <div class="column">
            <table class="table is-fullwidth is-narrow">
              <tbody>
                <tr>
                  <td>
                    {{
                    $t(
                      'Components.Invoices.InvoiceDetail.TableHeader_TotalExclTax'
                    )
                  }}
                  </td>
                  <td
                    class="has-text-right"
                    width="100"
                  >
                    {{ invoice.TotalExclTax | toCurrency }}
                  </td>
                </tr>
                <tr
                  v-for="tax in taxRows"
                  :key="tax.Id"
                >
                  <td>
                    {{
                    $t('Components.Invoices.InvoiceDetail.TableHeader_TotalTax')
                  }}
                    {{ tax.TaxPercentage }}%
                  </td>
                  <td
                    class="has-text-right"
                    width="100"
                  >
                    {{ tax.TotalExclTax | toCurrency('nl-NL', 'EUR') }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr class="has-text-weight-bold">
                  <td>
                    {{
                    $t(
                      'Components.Invoices.InvoiceDetail.TableHeader_TotalInclTax'
                    )
                  }}
                  </td>
                  <td
                    class="has-text-right"
                    width="100"
                  >
                    {{ invoice.TotalInclTax | toCurrency }}
                  </td>
                </tr>
                <tr
                  v-if="invoice.TotalPaid > 0"
                  class="is-italic"
                >
                  <td>
                    {{
                    $t(
                      'Components.Invoices.InvoiceDetail.TableHeader_TotalPaid'
                    )
                  }}
                  </td>
                  <td class="has-text-right">
                    {{ invoice.TotalPaid | toCurrency }}
                  </td>
                </tr>

                <tr
                  v-if="invoice.AmountOpen > 0"
                  class="has-text-weight-bold"
                >
                  <td>
                    {{
                    $t(
                      'Components.Invoices.InvoiceDetail.TableHeader_AmountOpen'
                    )
                  }}
                  </td>
                  <td
                    class="has-text-right"
                    width="100"
                  >
                    {{ invoice.AmountOpen | toCurrency }}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </section>

      <section class="section is-size-7">
        <ui-section-header :showEdit="false">
        </ui-section-header>

        <div
          v-if="invoice.Footer.length > 0"
          v-html="invoice.Footer"
          class="actionBar has-background-white-ter has-padding has-margin-bottom"
        ></div>
      </section>
    </div>
  </transition>
</template>

<script>
import invoiceProvider from '../../providers/invoice'
import Message from '../UI/Message.vue'
import LinkPrint from '../UI/LinkPrint'
import LinkDownload from '../UI/LinkDownload'

export default {
  components: {
    Message,
    LinkPrint,
    LinkDownload
  },

  props: {
    invoiceId: {
      default: 0,
      type: Number,
    },

    showInvoiceTitle: {
      type: Boolean,
      default: true,
    },

    invoiceStatusChangeCounter: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      invoiceUpdateCounter: 0,
      invoice: null,
      isInitiated: false,
      isLoading: false
    }
  },

  computed: {
    invoiceNotFound() {
      return !this.invoice || this.invoice.StatusId < 0 || this.invoice.StatusId > 1
    },
    invoicePending() {
      return this.invoice && this.invoice.StatusId === 0
    },

    invoiceTitle() {
      let temp = ''
      if (this.invoice) {
        temp =
          this.invoice.StatusId !== 1
            ? this.$t(
              `Components.Invoices.InvoiceDetail.StatusLabels.id_${this.invoice.StatusId}`
            ) + ' '
            : ''

        if (
          (this.invoice.StatusId === 0 || this.invoice.StatusId === 1) &&
          this.invoice.IsDeposit
        ) {
          temp += temp
            ? this.$t(
              `Components.Invoices.InvoiceDetail.Label_InvoiceTypeDeposit`
            ).toLowerCase()
            : this.$t(`Components.Invoices.InvoiceDetail.Label_InvoiceTypeDeposit`)
        } else {
          temp += temp
            ? ` ${this.$t(
              `Components.Invoices.InvoiceDetail.Label_Invoice`
            ).toLowerCase()}`
            : this.$t(`Components.Invoices.InvoiceDetail.Label_Invoice`)
        }

        if (this.invoice.StatusId > 0) {
          temp += ': ' + this.invoice.Code
        }
      }
      return temp
    },


    invoiceCode() {
      let output = this.$t(
        'Components.Invoices.InvoiceDetail.TableHeader_Concept'
      )

      if (this.invoice.StatusId !== 0) {
        output = this.invoice.Code
      } else if (this.invoice.StatusId === -1) {
        output = this.$t('Components.Invoices.InvoiceDetail.Label_Rejected')
      }

      return output
    },

    invoiceAddress() {
      let output = this.invoice.Address
      if (output && (this.invoice.PostalCode || this.invoice.PostalCode)) {
        output += '<br />'
      }
      output += this.invoice.PostalCode
      output += (this.invoice.PostalCode ? ' ' : '') + this.invoice.City

      return output
    },

    taxRows() {
      let self = this
      let rows = []

      if (
        self.invoice &&
        self.invoice.InvoiceLines &&
        self.invoice.InvoiceLines.length > 0
      ) {
        rows = self.invoice.InvoiceLines.filter((l) => l.TextGroupId === 4)
      }

      return rows
    },
  },

  watch: {
    invoiceStatusChangeCounter: {
      handler: function(val) {
        if (val > 0) {
          this.getInvoiceData()
        }
      }
    },
  },

  created() {
    if (!this.invoiceId) {
      this.isLoading = false
      return
    }

    this.getInvoiceData()
  },

  beforeDestroy() {
    this.invoice = null
  },

  methods: {
    decodeString(txt) {
      return this.$stringHelper.decodeHtml(txt)
    },

    getInvoiceData() {
      let self = this

      self.isLoading = true
      invoiceProvider.methods
        .getInvoiceById(this.invoiceId)
        .then((response) => {
          if (response.status === 200) {
            this.invoice = response.data
            this.isInitiated = true
          }
        })
        .finally(() => {
          self.isLoading = false
        })
    },

    getPackageRows(reservationId) {
      let self = this
      let rows = []

      if (
        self.invoice &&
        self.invoice.InvoiceLines &&
        self.invoice.InvoiceLines.length > 0
      ) {
        rows = self.invoice.InvoiceLines.filter(
          (l) => l.ReservationId === reservationId && l.TextGroupId === 7
        )
      }

      return rows
    },

    getVoucherRows(reservationId) {
      let self = this
      let rows = []

      if (
        self.invoice &&
        self.invoice.InvoiceLines &&
        self.invoice.InvoiceLines.length > 0
      ) {
        rows = self.invoice.InvoiceLines.filter(
          (l) => l.ReservationId === reservationId && l.TextGroupId === 10
        )
      }

      return rows
    },

    getSpaceRows(reservationId) {
      let self = this
      let rows = []

      if (
        self.invoice &&
        self.invoice.InvoiceLines &&
        self.invoice.InvoiceLines.length > 0
      ) {
        rows = self.invoice.InvoiceLines.filter(
          (l) => l.ReservationId === reservationId && l.TextGroupId === 1
        )
      }

      return rows
    },

    getSpaceSubTotal(reservationId) {
      let total = 0
      let spaceRows = this.getSpaceRows(reservationId)

      for (let i = 0; i < spaceRows.length; i++) {
        total = total + spaceRows[i].TotalExclTax
      }

      return total
    },

    /* 
    Get all the option invoice lines from a reservation
    */
    getOptionRows(reservationId) {
      let self = this
      let rows = []

      if (
        self.invoice &&
        self.invoice.InvoiceLines &&
        self.invoice.InvoiceLines.length > 0
      ) {
        rows = self.invoice.InvoiceLines.filter(
          (l) => l.ReservationId === reservationId && l.TextGroupId === 2
        )
      }

      return rows
    },

    /* 
    Calculate on per reservation base the total of the selected options
    */
    getOptionSubTotal(reservationId) {
      let total = 0
      let optionRows = this.getOptionRows(reservationId)

      for (let i = 0; i < optionRows.length; i++) {
        total = total + optionRows[i].TotalExclTax
      }

      return total
    },

    extractYear(dateString) {
      let year = 0
      let date = this.returnDateObject(dateString)
      if (date) {
        year = date.getFullYear()
      }

      return year
    },

    returnDateObject(dateString) {
      return this.$options.filters.parseIsoDateStringToDate(dateString)
    },
  },
}
</script>
