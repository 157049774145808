import axios from "axios";
import store from "../store/store";

export default {
  methods: {
    async getReservationMailLog(reservationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/mail/reservation/${reservationId}/log`,
        {
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      );
      return response;
    },
  }
}