<template>
  <ui-component-modal
    :modalTitle="$t('Components.Reservation.ModalEditInvoiceAddress.Header_ModalHeader')"
    :disableSaveButton="false"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveReservation"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <BaseForm ref="baseForm">
        <table
          v-if="mReservation"
          class="table is-borderless is-fullwidth"
        >
          <tbody>
            <tr>
              <td>
                {{ $t('Components.Reservation.ModalEditInvoiceAddress.Label_SendTo') }}
                <span class="has-text-danger">*</span>
              </td>
              <td>
                <RequiredField
                  name="SendTo"
                  v-model="mReservation.InvoiceAddress.SendTo"
                  :required="true"
                />
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('Components.Reservation.ModalEditInvoiceAddress.Label_Email') }}
                <span class="has-text-danger">*</span>
              </td>
              <td>
                <RequiredField
                  name="Email"
                  v-model="mReservation.InvoiceAddress.Email"
                  :required="true"
                />
              </td>
            </tr>
            <tr>
              <td v-text="$t('Components.Reservation.ModalEditInvoiceAddress.Label_PoNumber')">
              </td>
              <td>
                <BaseBulmaField
                  name="PoNumber"
                  v-model="mReservation.InvoiceAddress.PoNumber"
                  fieldType="text"
                />
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('Components.Reservation.ModalEditInvoiceAddress.Label_Address') }}
                <span
                  v-if="invoiceAddressRequired"
                  class="has-text-danger"
                >*</span>
              </td>
              <td>
                <BaseBulmaField
                  name="Address"
                  v-model="mReservation.InvoiceAddress.Address"
                  :required="invoiceAddressRequired"
                  fieldType="text"
                />
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('Components.Reservation.ModalEditInvoiceAddress.Label_PostalCode') }}
                <span
                  v-if="invoiceAddressRequired"
                  class="has-text-danger"
                >*</span>
              </td>
              <td>
                <BaseBulmaField
                  name="Postalcode"
                  v-model="mReservation.InvoiceAddress.Postalcode"
                  :required="invoiceAddressRequired"
                  fieldType="text"
                />
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('Components.Reservation.ModalEditInvoiceAddress.Label_City') }}
                <span
                  v-if="invoiceAddressRequired"
                  class="has-text-danger"
                >*</span>
              </td>
              <td>
                <BaseBulmaField
                  name="City"
                  v-model="mReservation.InvoiceAddress.City"
                  :required="invoiceAddressRequired"
                  fieldType="text"
                />
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('Components.Reservation.ModalEditInvoiceAddress.Label_Country') }}
                <span
                  v-if="invoiceAddressRequired"
                  class="has-text-danger"
                >*</span>
              </td>
              <td>
                <BaseBulmaField
                  name="Country"
                  v-model="mReservation.InvoiceAddress.Country"
                  :required="invoiceAddressRequired"
                  fieldType="text"
                />
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('Components.Reservation.ModalEditInvoiceAddress.Label_Phone') }}
                <span
                  v-if="phoneRequired"
                  class="has-text-danger"
                >*</span>
              </td>
              <td>
                <BaseBulmaField
                  name="PhoneNumber"
                  :validateOnInput="true"
                  v-model="mReservation.InvoiceAddress.PhoneNumber"
                  :required="phoneRequired"
                  fieldType="text"
                  :validationRules="phoneValidationRules"
                  :errorMessages="phoneValidationErrorMessages"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </BaseForm>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { EventBus } from '@/eventBus/event-bus'
import reservationProvider from '../../providers/reservation'
import { required, minLength, email } from 'vuelidate/lib/validators'
import BaseForm from '@/components/UI/Form/BaseForm'

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  components: {
    BaseForm
  },

  data() {
    return {
      changeAllInSet: false,
      mReservation: null,
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      formFields: [],
      phoneValidationErrorMessages: {
        required: this.$t('Form.InputErrors.Required'),
        minLength: this.$t('Form.InputErrors.minLength', { minLength: 8 }),
      }
    }
  },

  /**
   * Form validation rules
   */
  // validations() {
  //   let output = {
  //     SendTo: {
  //       required,
  //       minLength: minLength(2),
  //     },
  //     Email: {
  //       required,
  //       email
  //     },
  //     Address: {
  //       required,
  //     },
  //     Postalcode: {
  //       required
  //     },
  //     City: {
  //       required
  //     },
  //     Country: {
  //       required
  //     },
  //     PhoneNumber: {
  //       required,
  //       minLength: minLength(9)
  //     }
  //   }

  //   return {
  //     mReservation: {
  //       InvoiceAddress: output,
  //     }
  //   }
  // },

  computed: {
    ...mapState('reservationStore', ['reservation']),

    invoiceAddressRequired() {
      return this.mReservation.MeetingtypeId !== 2
    },
    phoneRequired() {
      return this.mReservation.MeetingtypeId !== 2 || (this.mReservation.MeetingtypeId === 2 && this.mReservation.TotalExclTax > 0)
    },

    phoneValidationRules() {
      let rules = {}

      // if (this.phoneRequired) {
      //   rules['required'] = required()
      // }
      if (this.mReservation.InvoiceAddress.PhoneNumber.trim() !== '') {
        rules['minLength'] = minLength(9)
      }
      return rules
    },

    // sendToErrors() {
    //   const errors = []
    //   if (!this.$v.mReservation.InvoiceAddress.SendTo.$dirty) return errors
    //   !this.$v.mReservation.InvoiceAddress.SendTo.required &&
    //     errors.push(this.$t('Form.InputErrors.Required'))
    //   !this.$v.mReservation.InvoiceAddress.SendTo.minLength &&
    //     errors.push(this.$t('Form.InputErrors.minLength', { minLength: 2 }))
    //   return errors
    // },

    // emailErrors() {
    //   const errors = []
    //   if (!this.$v.mReservation.InvoiceAddress.Email.$dirty) return errors
    //   if (!this.$v.mReservation.InvoiceAddress.Email.required) {
    //     errors.push(this.$t('Form.InputErrors.Required'))
    //   } else if (!this.$v.mReservation.InvoiceAddress.Email.email) {
    //     errors.push(this.$t('Form.InputErrors.InvalidEmail'))
    //   }
    //   return errors
    // },

    // addressErrors() {
    //   const errors = []
    //   if (this.mReservation.MeetingtypeId === 1) {
    //     if (!this.$v.mReservation.InvoiceAddress.Address.$dirty) return errors
    //     !this.$v.mReservation.InvoiceAddress.Address.required &&
    //       errors.push(this.$t('Form.InputErrors.Required'))
    //   }
    //   return errors
    // },

    // postalCodeErrors() {
    //   const errors = []
    //   if (this.mReservation.MeetingtypeId === 1) {
    //     if (!this.$v.mReservation.InvoiceAddress.Address.$dirty) return errors
    //     !this.$v.mReservation.InvoiceAddress.Address.required &&
    //       errors.push(this.$t('Form.InputErrors.Required'))
    //   }
    //   return errors
    // },

    // cityErrors() {
    //   const errors = []
    //   if (this.mReservation.MeetingtypeId === 1) {
    //     if (!this.$v.mReservation.InvoiceAddress.City.$dirty) return errors
    //     !this.$v.mReservation.InvoiceAddress.City.required &&
    //       errors.push(this.$t('Form.InputErrors.Required'))
    //   }
    //   return errors
    // },

    // countryErrors() {
    //   const errors = []
    //   if (this.mReservation.MeetingtypeId === 1) {
    //     if (!this.$v.mReservation.InvoiceAddress.Country.$dirty) return errors
    //     !this.$v.mReservation.InvoiceAddress.Country.required &&
    //       errors.push(this.$t('Form.InputErrors.Required'))
    //   }
    //   return errors
    // },

    // phoneErrors() {
    //   const errors = []
    //   if (this.mReservation.MeetingtypeId === 1) {
    //     if (!this.$v.mReservation.InvoiceAddress.PhoneNumber.$dirty) return errors
    //     if (!this.$v.mReservation.InvoiceAddress.PhoneNumber.required) {
    //       errors.push(this.$t('Form.InputErrors.Required'))
    //     }
    //     if (!this.$v.mReservation.InvoiceAddress.PhoneNumber.minLength) {
    //       errors.push(this.$t('Form.InputErrors.minLength', { minLength: 9 }))
    //     }
    //   }
    //   return errors
    // }
  },

  created() {
    this.mReservation = JSON.parse(JSON.stringify(this.reservation))
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    async saveReservation() {
      let self = this

      // Stop processing. A save call is already being executed.
      if (this.isSaving) { return }
      this.isSaving = true

      // Check form data
      await this.$refs.baseForm.runValidation({ silent: true })

      // Stop processing when form data in invalid
      if (!this.$refs.baseForm.isValid()) {
        this.isSaving = false
        return
      }


      // Do saving then close modal
      reservationProvider.methods
        .saveReservationInvoiceAddress(
          self.mReservation.Id,
          self.mReservation.InvoiceAddress,
          false
        )
        .then((response) => {
          if (response.status === 200) {
            // Update reservation store
            self.setReservation(response.data)

            // Send message to display toast on screen
            EventBus.$emit('showToast', {
              type: 'info',
              message: this.$t('General.Message_ReservationSaved'),
            })

            // Close modal
            this.onClickCancel()
          }
        })
        .catch((error) => {
          self.isSavingError = true
        })
        .finally(() => {
          self.isSaving = false
        })
    },
  },
}
</script>
