<template>
  <div>
    <ModalAddExternalLink
      v-if="showAddModal"
      :showModal="showAddModal"
      :onClickCancel="hideModal"
    />

    <ModalDeleteExternalLink
      v-if="showDeleteModal"
      :showModal="showDeleteModal"
      :onClickCancel="hideModal"
      :externalLink="selectedExternalLink"
      @externalLinkDeleted="externalLinkDeleted"
    />

    <ModalEditExternalLink
      v-if="showEditModal"
      :showModal="showEditModal"
      :onClickCancel="hideModal"
      :externalLink="selectedExternalLink"
      @externalLinkEdited="externalLinkEdited"
    />

    <ui-section-header
      :showAdd="!isLocked"
      :addFunction="setShowAddModal"
    >
      <template v-slot:title>{{ $t('Components.Reservation.ReservationExternalLinks.Main_Title') }}</template>
    </ui-section-header>

    <table class="table is-fullwidth is-striped is-hoverable">
      <thead>
        <tr>
          <th v-text="$t('Components.Reservation.ReservationExternalLinks.TableHeader_Type')"></th>
          <th v-text="$t('Components.Reservation.ReservationExternalLinks.TableHeader_Link')"></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(link, index) in reservation.ExternalLinks"
          :key="index"
        >
          <td>{{ link.LinkType }}</td>
          <td>{{ link.Link }}</td>
          <td width="80">
            <div
              v-if="!isLocked"
              class="field is-grouped has-text-right"
            >
              <p class="control">
                <a
                  @click="setShowEditModal(link)"
                  class="is-small"
                >
                  <span class="icon">
                    <font-awesome-icon :icon="['fas', 'edit']" />
                  </span>
                </a>
              </p>
              <p class="control">
                <a
                  @click="setShowDeleteModal(link)"
                  class="is-small"
                >
                  <span class="icon has-text-danger">
                    <font-awesome-icon :icon="['fas', 'trash-alt']" />
                  </span>
                </a>
              </p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations, mapGetters } from 'vuex'

import ModalEditExternalLink from '@/components/Reservation/ModalEditExternalLink'
import ModalDeleteExternalLink from '@/components/Reservation/ModalDeleteExternalLink'
import ModalAddExternalLink from '@/components/Reservation/ModalAddExternalLink'

export default {
  components: {
    ModalAddExternalLink,
    ModalDeleteExternalLink,
    ModalEditExternalLink,
  },

  props: {
    linktType: {
      type: Number,
      default: -1,
    },
  },

  data() {
    return {
      selectedExternalLink: null,
      showAddModal: false,
      showDeleteModal: false,
      showEditModal: false,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),
    ...mapGetters('reservationStore', ['isLocked']),
  },

  created() { },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    externalLinkDeleted(externalLink) {
      let linkIndex = this.reservation.ExternalLinks.findIndex(
        (l) => l.Id === externalLink.Id
      )
      if (linkIndex > -1) {
        let updatedReservation = JSON.parse(JSON.stringify(this.reservation))
        updatedReservation.ExternalLinks.splice(linkIndex, 1)
        this.setReservation(updatedReservation)
      }
    },

    externalLinkEdited(externalLink) {
      // Find the link from the link array in reservations
      // and update the link in the array
      let index = this.reservation.ExternalLinks.findIndex(l => l.Id === externalLink.Id)
      if (index > -1) {
        Vue.set(this.reservation.ExternalLinks, index, externalLink)
        this.setReservation(this.reservation)
      }
    },

    setShowAddModal() {
      this.showAddModal = true
    },

    setShowDeleteModal(externalLink) {
      this.showDeleteModal = true
      this.selectedExternalLink = externalLink
    },

    setShowEditModal(externalLink) {
      this.showEditModal = true
      this.selectedExternalLink = externalLink
    },

    hideModal() {
      this.showAddModal = false
      this.showDeleteModal = false
      this.showEditModal = false
      this.selectedExternalLink = null
    },
  },
}
</script>

<style></style>
